import React from "react";
import config from "../../configData";

const baseDir = config().baseDir;

const ImagesDisplay = (props) => {
  return (
    <div className="ML-imageBox">
      {/* image: {`${baseDir}/Image/${props.obj.data.image}`} */}
      <img alt="" src={`${baseDir}/Image/${props.obj.data.image}`} />
    </div>
  );
};

export default ImagesDisplay;
