import React from "react";
import { fetchImage } from "../../Components/nonUIFuncs";
import { Image } from "semantic-ui-react";

const DictionaryContentRight = ({ tabData, dims, display = false }) => {
  if (!display) {
    return <></>;
  }
  return (
    <div className="dictionary-tab-content-right">
      {tabData.App_Art.length > 0 ? (
        <div className="dictionary-tab-image">
          <Image src={fetchImage(tabData.App_Art)} fluid rounded bordered />
        </div>
      ) : null}
      {tabData?.App_Audio?.length > 0 ? (
        <div className="dictionary-tab-play-audio">
          <div className="dictionary-tab-play-inner">
            <div className="dictionary-tab-play-triangle" />
          </div>
        </div>
      ) : null}
      {/* Check to see if there IS a video, if so, display it else return null */}
      <div className=".dictionary-tab-content-video-container ">
        {/* <VideoDisplay
          width={dims.width || 420}
          youtubeID="TISp0swKhkk"
          vimeoID="131837232"
          url="https://www.youtube.com/embed/v6IhNUpSeAM"
          sourceType={"youtusdsdbe"} // Will play one of the videos above must have sourceType === 'url' || 'youtube' || 'vimeo'
        /> */}
      </div>
    </div>
  );
};

export default DictionaryContentRight;
