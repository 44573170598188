/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import "semantic-ui-css/semantic.min.css";
import "./index.scss";
import "../index.scss";
import "../../../App.scss";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Icon, Button, Radio, Modal } from "semantic-ui-react";
import {setQuizData, fetchImage, fetchAudio, randomNumber} from '../../nonUIFuncs';
import { getPagedQuizData, selectFirestore } from "../../../Store/firestore";
import { useDispatch, useSelector } from "react-redux";
import {Loader, Dimmer} from "semantic-ui-react";

let chosenDatum = null;
const StarDisplay = (props) => {
  let GSWidth = document.getElementsByClassName("stars-gray");
  let goldStars = {width: 0};
  if (GSWidth.length > 0) {
    GSWidth = GSWidth[0].clientWidth;
    goldStars.width = (props.allStars / 5) * GSWidth;
  }
  return (
      <div className="stars-clip-container">
        <div className="stars-clip">
          <div className="stars-gray" />
          <div className="stars-gold" style={goldStars}/>
        </div>
      </div>
  );
};

const ProgressBar = (props) => {
  if (props.correctFill / props.maxFill >= 1) {
    props.fullBarFunc();
  }
let fillingBar = {
  width: 0,
};
  let emptyBarWidth = document.getElementsByClassName('progress-bar-empty');
  if (emptyBarWidth.length > 0) {
    emptyBarWidth = emptyBarWidth[0].clientWidth;
    fillingBar.width = (props.correctFill / props.maxFill) * emptyBarWidth;
  }
  return (
    <div className="progress-bar-container">
      <div className="progress-bar-empty">
        <div style={fillingBar} className="progress-bar-filling">
          <div className="percentage">{Math.floor((props.correctFill / props.maxFill) * 100)}%</div>
        </div>
      </div>
    </div>
  );
};

const Answer = (props) => {
  let bgColor = {backgroundColor: "burlywood"};
  if (props.answer === props.data.English) bgColor.backgroundColor = 'lightblue';
  let markWrong = props.wrongAttempts.find(datum => datum === props.data.English);
  if (markWrong) bgColor.backgroundColor = 'red';
  return (
    <div style={bgColor} className="quiz-answer-container">
      <Radio
        label={props.data.Language}
        name="answerGroup"
        value={props.data.English}
        checked={props.answer === props.data.English}
        onChange={() =>
          props.setAnswer(
            props.answer === props.data.English ? null : props.data.English
          )
        }
      />
      {props.data.App_Art !== undefined ? (
        <img
          src={fetchImage(props.data.App_Art)}
          className="quiz-answer-image"
        />
      ) : null}
      {props.data.Audio_Array.length > 0 ? (
        <div
          onClick={() =>
            fetchAudio(
              props.data.Audio_Array[
                randomNumber(props.data.Audio_Array.length)
              ]
            )
          }
          className="quiz-answer-audio"
        >
          <Icon name="play" fitted />
        </div>
      ) : null}
    </div>
  );
}

const Question = (props) => {
  return (
    <div className="quiz-quiz-container">
      <h2>{props.correctAnswer.English}</h2>
      <div className="quiz-question-answers-container">
        {props.roundData.map((datum) => {
          return (
            <Answer
              key={datum.English}
              wrongAttempts={props.wrongAttempts}
              data={datum}
              setAnswer={props.setAnswer}
              answer={props.answer}
            />
          );
        })}
      </div>
      <ProgressBar
        correctFill={props.correctFill}
        maxFill={5}
        fullBarFunc={props.fullBarFunc}
      />
    </div>
  );
};

const Quiz = (props) => {
  const [answer, setAnswer] = useState(null);
  const [correctAnswer, setCorrectAnswer] = useState(null);
  const [roundData, setRoundData] = useState([]);
  const [wrongAttempts, setWrongAttemps] = useState([]);
  const [currentStar, setCurrentStar] = useState(1);
  const [allStars, setAllStars] = useState(0);
  const [correctCount, setCorrectCount] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [fullQuizData, setFullQuizData] = useState([]);
  const [insertChance, setInsertChance] = useState(.2);
  const [searchedPiece, setSearchedPiece] = useState(null);
  const [alreadyPlaced, setAlreadyPlaced] = useState(false);
  const dispatch = useDispatch();
  const fireStoreData = useSelector(selectFirestore);

  useEffect(() => {
    // TODO: not sure we want localStorage for this actually and it looks like there might be an issue with retriving bad data sometimes? (not verified)
    let storedData = JSON.parse(localStorage.QuizData);
    setSearchedPiece(storedData);
    chosenDatum = setQuizData(storedData);
    dispatch(getPagedQuizData(chosenDatum));
  }, []);

  const shuffle = (array) => {
    let fixedArray = [...array];
    let j, x, i;
    for (i = fixedArray.length - 1; i > 0; i--) {
      j = Math.floor(Math.random() * (i + 1));
      x = fixedArray[i];
      fixedArray[i] = fixedArray[j];
      fixedArray[j] = x;
    }
    return fixedArray;
  };

  const findNewData = (data) => {
    let findQuizData = data ? data : fullQuizData;
    findQuizData = findQuizData.filter((d) => d.English.length > 1 && d.Language.length > 1);
    if (data) {
      setFullQuizData(findQuizData);
    }
    setAnswer(null);
    setWrongAttemps([]);
    let quizData = shuffle(findQuizData);
    let findRoundData = quizData.slice(0, 4);
    findRoundData = findRoundData.map((datum) => {
      let normalizedData = setQuizData(datum);
      return normalizedData;
    });

    setRoundData(shuffle(findRoundData));
    // TODO: make sure this random number is 0 - X, not 1 - X
    setCorrectAnswer(findRoundData[randomNumber(findRoundData.length)]);
    if (correctAnswer?.English !== searchedPiece.English) {
      if (Math.random() < insertChance && alreadyPlaced !== true) {
        setAlreadyPlaced(true);
        let searchedDatum = setQuizData(searchedPiece);
        let newData = [
          ...findRoundData.filter(
            (datum) => datum.English !== searchedDatum.English
          ),
          searchedDatum,
        ];
        if (newData.length > 4) {
          newData.shift();
        }
        setRoundData(shuffle(newData));
        setCorrectAnswer(searchedDatum);
      }
    }
  };

  if (fireStoreData.quizData && correctAnswer === null && searchedPiece != null) {
    let filteredData = [];
    let filteredDataStrings = [];
    fireStoreData.quizData.forEach((datum) => {
      if (!filteredDataStrings.includes(datum.English)) {
        filteredData.push(datum);
        filteredDataStrings.push(datum.English);
      }
    })
    findNewData(filteredData);
  }

  const checkCorrectness = () => {
    if (answer === correctAnswer.English) {
      findNewData();
      setAllStars(allStars + currentStar)
      setCurrentStar(1);
      setCorrectCount(correctCount + 1);
      setInsertChance(insertChance + 0.2);
      if (answer === searchedPiece.English) {
        setAlreadyPlaced(true);
      }
    } else {
      setWrongAttemps([...wrongAttempts, answer]);
      setAnswer(null);
      setCurrentStar(currentStar > 0 ? currentStar - 0.25 : 0);
    }
  };

  const endGame = () => {
    setShowModal(true);
    setCurrentStar(1);
    setTimeout(() => setCorrectCount(0), 1000)
  }

  const reset = () => {
    setInsertChance(0.2);
    setAlreadyPlaced(false);
    setCorrectAnswer(null);
    setShowModal(false);
    setAllStars(0);
  }
  return (
    <div className="current-screen-style">
      <div className="quiz-header-container">
        <Link
          to={{ pathname: "/Dictionary" }}
          className="dictionary-quiz-button noselect"
        >
          <Icon name="chevron left" />
          Sináákia’tsis
        </Link>
        <div className="quiz-header">Quiz</div>
        {/* Hidden div to balance screen position */}
        <div className="dictionary-quiz-button noselect" style={{ opacity: 0 }}>
          <Icon name="chevron left" />
          Sináákia’tsis
        </div>
      </div>
      <div className="quiz-maincontent">
        <StarDisplay allStars={allStars} />
        {fullQuizData?.length > 0 ? (
          <Question
            fullBarFunc={endGame}
            correctFill={correctCount}
            correctAnswer={correctAnswer}
            roundData={roundData}
            answer={answer}
            setAnswer={setAnswer}
            wrongAttempts={wrongAttempts}
          />
        ) : (
          <div className="dictionary-quiz-default">
            <Dimmer active>
              <Loader active>Fetching Data...</Loader>
            </Dimmer>
          </div>
        )}
      </div>
      <Button
        disabled={answer === null}
        content="Check"
        onClick={checkCorrectness}
      />
      <Modal open={showModal} className="modalKat">
        <Modal.Header>
          <div className="englishTxtHeader">You Win!</div>
        </Modal.Header>
        <Modal.Actions>
          <Link to={{ pathname: "/Dictionary" }}>
            <Button
              icon="chevron left"
              content="Back to Sináákia’tsis"
              onClick={() => {
                setShowModal(false);
              }}
            />
          </Link>
          <Button content="Play Again" onClick={reset} />
        </Modal.Actions>
      </Modal>
    </div>
  );
};

export default Quiz;

Quiz.propTypes = {};
