import "./index.scss";
import "../../App.scss";
import React, { useState, useRef, useEffect } from "react";
// import { Link } from "react-router-dom";
import { selectIsLoggedIn } from "../../Store/loggedIn";
import {
  Button,
  Transition,
  Icon,
  Dropdown,
  Dimmer,
  Loader,
  Segment,
  Form,
} from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import {
  selectFirestore,
  getUserPrefs,
  getProductList,
  getCollectionNames,
  // updateDocument,
  // updateUserStyles,
  // updateUserColumnPrefs,
  getGameData,
  // createDocument,
  // deleteDocument,
  // updateUserColors,
} from "../../Store/firestore";
import { useSelector, useDispatch } from "react-redux";
import { specialCharsA } from "../SpecialCharsEntry";
// import { fetchImage } from "../nonUIFuncs";
import {
  fontSizeOptions,
  productStatusList,
  fixColor,
  gameDataList,
} from "./dataGridConfig";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import AddNewGameRow from "./Components/addNewGame";
import "@inovua/reactdatagrid-community/index.css";
import { SketchPicker } from "react-color";
import {
  BoldIcon,
  CellColorIcon,
  ItalicIcon,
  StrikeThroughIcon,
  TextColorIcon,
  UnderlineIcon,
  FilterIcon,
} from "./SVGIcons";
import {
  AddColumnModal,
  OptionsModal,
  MediaUploadModal,
  ConfirmAddRowModal,
  ConfirmDeleteRowModal,
} from "./Components/addColumnModal";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";
// import Dropzone from "../Dropzone";
import { NumberFilter } from "@inovua/reactdatagrid-community/NumberFilter";
// import { stringify } from "@firebase/util";
// import AdminNav from "../Navigation-admin";

const rowHeight = 27;
let fontStyleProps = { style: { padding: 2 }, active: false };
const ComponentAccordion = (props) => {
  useEffect(() => {
    if (props.componentName === "Link Collection") {
      props.updateOpenArray([props.componentName], ["Product List & Status"]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const updateOpenArray = (action) => {
    if (action === "close") {
      props.updateOpenArray([], [props.componentName]);
    } else {
      props.updateOpenArray([props.componentName], []);
    }
  };
  if (props.openArray.includes(props.componentName)) {
    return (
      <div className="gameData-outeraccordion-outercontainer">
        <div
          className="gameData-outeraccordion-innercontainer"
          onClick={() => {
            updateOpenArray("close");
          }}
        >
          <h2 style={{ margin: 0, padding: 0 }}>{props.componentName}</h2>
          <Icon size="large" name="chevron down" />
        </div>
        <div style={{ padding: 2 }}>{props.children}</div>
      </div>
    );
  } else {
    return (
      <div className="gameData-outeraccordion-outercontainer">
        <div
          className="gameData-outeraccordion-innercontainer"
          onClick={() => {
            updateOpenArray("open");
          }}
        >
          <h2 style={{ margin: 0, padding: 0 }}>{props.componentName}</h2>
          <Icon size="large" name="chevron right" />
        </div>
      </div>
    );
  }
};
const InnerComponentAccordion = (props) => {
  if (props.expanded) {
    return (
      <div className="gameData-inneraccordion-outercontainer">
        <div
          className="gameData-inneraccordion-innercontainer-expanded"
          onClick={() => props.setExpanded(false)}
        >
          <h4 className="gameData-inneraccordion-headertext">{props.header}</h4>
          <Icon size="large" name="chevron down" />
        </div>
        <div className="gameData-inneraccordion-childrencontainer">
          {props.children}
        </div>
      </div>
    );
  } else {
    return (
      <div className="gameData-inneraccordion-outercontainer">
        <div
          className="gameData-inneraccordion-innercontainer"
          onClick={() => props.setExpanded(true)}
        >
          <h4 className="gameData-inneraccordion-headertext">{props.header}</h4>
          <Icon size="large" name="chevron right" />
        </div>
      </div>
    );
  }
};
const ChooseCollection = (props) => {
  const [visibleOne, setVisibleOne] = useState(true);
  const [visibleTwo, setVisibleTwo] = useState(true);
  const updateOpenArray = (type) => {
    props.setNewExisting(type);
    props.updateOpenArray(
      type === "existing" ? "Product List & Status" : "New Collection",
      ["New Collection", "Product List & Status"]
    );
  };
  return (
    <div
      className={
        props.newExisting === null
          ? "gamedata-choosecollection-container"
          : "gamedata-choosecollection-container-chosen"
      }>
      <div className="gamedata-choosecollection-header">
        Would you like to...
      </div>
      <div className="gamedata-choosecollection-button-container">
        <Button.Group size={props.newExisting === null ? "medium" : "tiny"}>
          <Transition animation="jiggle" duration={500} visible={visibleOne}>
            <Button
              color="blue"
              onClick={() => {
                updateOpenArray("existing");
                setVisibleOne(!visibleOne);
              }}>
              Edit an existing collection
            </Button>
          </Transition>
          <Button.Or />
          <Transition animation="jiggle" duration={500} visible={visibleTwo}>
            <Button
              color="blue"
              onClick={() => {
                updateOpenArray("new");
                setVisibleTwo(!visibleTwo);
              }}>
              Create a new collection
            </Button>
          </Transition>
        </Button.Group>
      </div>
    </div>
  );
};
const IconButton = (props) => {
  return (
    <Button
      color={props.color}
      style={props.style}
      active={props.active}
      compact
      icon
      onClick={props.buttonPress}>
      {props.icon}
    </Button>
  );
}
const ColorPickerContainer = (props) => {
  const recentColorsFirestoreData = props.fireStoreData?.userPrefs?.recentColors;
  useEffect(() => {
    try {
      let keys = Object.keys(props.fireStoreData.userPrefs.recentColors);
      keys.forEach((k) => {
        if (recentColorsFirestoreData[k].length === 0) {
          let colors = recentColorsFirestoreData[k];
          colors.foreach((c) => {
            props.updateRecentUserColors(c, k, false); //BUG: this looks to change props and that's a no-no.
          });
        }
      });
    } catch (e) {
      console.log(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recentColorsFirestoreData]);
  useEffect(() => {
    let keys = Object.keys(props.cellSelection);
    if (keys.length !== 0) {
      _iconButtonPress(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.cellSelection]);

  let recentColors = props.recentUserColors[props.componentID];
  // const handleBlur = (e) => {
  //   console.log(e);
  //   if (!e.currentTarget.contains(e.relatedTarget)) {
  //     console.log('Triggered Inside?');
  //   } else {
  //     console.log('Triggered Outside');
  //   }
  // };
  const _iconButtonPress = (componentName) => {
    let cn = componentName;
    if (cn !== null) {
      cn = props.componentID;
    }
    if (props.activeComponent === props.componentID) {
      if (cn !== null) {
        cn = null;
      }
      if (props.color.rgb !== undefined) {
        props.updateRecentUserColors(
          fixColor(props.color.rgb),
          props.componentID
        );
      }
      props.setActiveComponent(cn);
    } else {
      props.setActiveComponent(cn);
    }
  };
  return (
    <div className="gameData-styleBar-button-container">
      <IconButton
        {...fontStyleProps}
        buttonPress={_iconButtonPress}
        icon={props.icon}
      />
      {props.activeComponent === props.componentID ? (
        <div
          className="gameData-styleBar-sketchPicker-container"
        // onBlur={(e) => handleBlur(e)}
        >
          <SketchPicker
            color={props.color}
            onChangeComplete={(color) => {
              // console.log('CHANGED');
              props.setColor(color);
              props.updateCellStyle({
                [props.componentID]: fixColor(color.rgb),
              });
            }}
          />
          {recentColors.length > 0 ? (
            <div>
              <h3 className="gameData-styleBar-sketchPicker-recentsHeader">
                Recent Colors
              </h3>
              <div className="gameData-styleBar-sketchPicker-recentscontainer">
                {recentColors.map((color) => {
                  return (
                    <div
                      key={color}
                      // TODO: Next Steps we need to make sure to save the data in userPrefs and pull it from userPrefs rather than the dummy data I am using currently.
                      className="gameData-styleBar-sketchPicker-recentscolors"
                      style={{ backgroundColor: color }}
                      onClick={() => {
                        // props.setColor(color);
                        props.updateCellStyle({
                          [props.componentID]: color,
                        });
                        props.setActiveComponent(null);
                      }}
                    />
                  );
                })}
              </div>
            </div>
          ) : null}
          <Button
            className="gameData-styleBar-default-button"
            fluid
            onClick={() => {
              props.componentID === "backgroundColor"
                ? props.updateCellStyle({
                  [props.componentID]: "rgba(0,0,0,0)",
                })
                : props.updateCellStyle({
                  [props.componentID]: "",
                });
              props.setActiveComponent(null);
            }}
          >
            {props.clearButtonTitle}
          </Button>
        </div>
      ) : null}
    </div>
  );
};
const StyleBar = (props) => {
  const textColorRef = useRef();
  const backgroundColorRef = useRef();
  const [activeStyleComponent, setActiveStyleComponent] = useState("");
  const [cellBackground, setCellBackground] = useState("");
  const [textColor, setTextColor] = useState("");
  const [textAlignment, setTextAlignment] = useState("");
  textColorRef.current = textColor;
  backgroundColorRef.current = cellBackground;
  let textAlignArr = ["left", "center", "right", "justify"];
  let fontStyleButtons = [
    { style: { fontWeight: "bold" }, icon: <BoldIcon size={25} /> },
    { style: { fontStyle: "italic" }, icon: <ItalicIcon size={25} /> },
    {
      style: { textDecorationLine: "line-through" },
      icon: <StrikeThroughIcon size={25} />,
    },
    {
      style: { textDecorationLine: "underline" },
      icon: <UnderlineIcon size={25} />,
    },
  ];
  let colorPickerProps = [
    {
      componentID: "backgroundColor",
      clearButtonTitle: "Clear Cell Color",
      icon: <CellColorIcon size={25} />,
      color: backgroundColorRef.current,
      setColor: setCellBackground,
    },
    {
      componentID: "color",
      clearButtonTitle: "Clear Text Color",
      icon: <TextColorIcon size={25} />,
      color: textColorRef.current,
      setColor: setTextColor,
    },
  ];
  let fontStyleProps = { style: { padding: 2 }, active: false };
  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div className="gameData-styleBar-container">
        <div className="gameData-styleBar-button-container">
          <IconButton
            active={false}
            buttonPress={() => {
              setActiveStyleComponent(
                activeStyleComponent === "textAlign" ? null : "textAlign"
              );
            }}
            icon={<Icon name={`align ${textAlignment || "left"}`} />}
          />
          {activeStyleComponent === "textAlign" ? (
            <div className="gameData-styleBar-hidden-buttonGroup-container">
              <Button.Group>
                {textAlignArr.map((string) => (
                  <IconButton
                    key={string}
                    active={textAlignment === string}
                    buttonPress={() => {
                      setTextAlignment(string);
                      props.updateCellStyle({ textAlign: string });
                      setActiveStyleComponent(null);
                    }}
                    icon={<Icon name={`align ${string}`} />}
                  />
                ))}
              </Button.Group>
            </div>
          ) : null}
        </div>
        {colorPickerProps.map((cp) => (
          <ColorPickerContainer
            {...cp}
            fireStoreData={props.fireStoreData}
            key={cp.componentID}
            activeComponent={activeStyleComponent}
            setActiveComponent={setActiveStyleComponent}
            updateCellStyle={props.updateCellStyle}
            recentUserColors={props.recentUserColors}
            updateRecentUserColors={props.updateRecentUserColors}
            cellSelection={props.cellSelection}
          />
        ))}
        <div className="gameData-styleBar-buttonGroup-container">
          <Button.Group>
            {fontStyleButtons.map((b, idx) => {
              return (
                <IconButton
                  {...fontStyleProps}
                  key={idx}
                  buttonPress={() => props.updateCellStyle(b.style)}
                  icon={b.icon}
                />
              );
            })}
          </Button.Group>
        </div>
        <div className="gameData-styleBar-buttonGroup-container">
          <Button size="tiny" style={{ padding: 0 }}>
            <Dropdown
              placeholder="12"
              search
              selection
              options={fontSizeOptions}
              allowAdditions
              onAddItem={(_e, d) => {
                if (isNaN(d.value)) {
                  alert(`${d.value} is not a valid font size!`);
                } else {
                  let exists = d.options.find(
                    (v) => Number(d.value) === v.value
                  );
                  if (exists) {
                    alert(`${d.value} already exists in the list!`);
                  } else {
                    fontSizeOptions.push({
                      key: d.options.length,
                      text: Number(d.value),
                      value: Number(d.value),
                    });
                    fontSizeOptions.sort((a, b) => {
                      let nameA = a.text;
                      let nameB = b.text;
                      if (nameA < nameB) {
                        return -1;
                      }
                      if (nameA > nameB) {
                        return 1;
                      }
                      return 0;
                    });
                  }
                }
              }}
              onChange={(_e, d) => {
                d.options.map((v) => {
                  let func;
                  if (v.text === Number(d.value)) {
                    func = props.updateCellStyle({
                      fontSize: Number(d.value),
                    });
                  } else if (d.value === "default") {
                    func = props.updateCellStyle({
                      fontSize: d.value,
                    });
                  }
                  return func;
                });
              }}
            />
          </Button>
        </div>
        <div className="gameData-styleBar-buttonGroup-container">
          <IconButton
            style={{ padding: 2 }}
            active={props.enableFiltering}
            buttonPress={() => {
              props.setEnableFiltering(!props.enableFiltering);
            }}
            icon={<FilterIcon size={25} />}
          />
        </div>
      </div>
      <div className="gameData-styleBar-buttonGroup-container-right">
        <Button
          onClick={() => {
            props.setShowAddColumn(true);
            props.setEnableFiltering(false);
          }}
          size="tiny"
        >
          Add a column
        </Button>
      </div>
    </div>
  );
};
const ProductStatusDataGrid = (props) => {
  const rowsRef = useRef();
  const colRef = useRef();
  const cellSelectionRef = useRef();
  const checkedColumnsRef = useRef();
  const [productList, setProductList] = useState(false);
  const [columns, setColumns] = useState([]);
  const [cellSelection, setCellSelection] = useState({});
  const [enableFiltering, setEnableFiltering] = useState(false);
  const [checkedColumns, setCheckedColumns] = useState([]);
  const [showAddColumnModal, setShowAddColumnModal] = useState(false);
  const [showNewOptionsModal, setShowNewOptionsModal] = useState(false);
  const [optionsModalProps, setOptionsModalProps] = useState({});
  const [loading, setLoading] = useState(true);
  rowsRef.current = props.rows;
  cellSelectionRef.current = cellSelection;
  checkedColumnsRef.current = checkedColumns;
  colRef.current = columns;
  useEffect(() => {
    let headerBoxes = document.getElementsByClassName(
      "InovuaReactDataGrid__column-header__content"
    );
    let keys = Object.keys(headerBoxes);
    keys.forEach((k) => {
      headerBoxes[k].style.height = "30px";
      headerBoxes[k].style.padding = "0";
    });
  }, [columns]);
  useEffect(() => {
    console.log('Enable Filtering Triggered');
    const setHeaderHeight = () => {
      let alteredColumns = [...columns];
      let height = enableFiltering ? 80 : 30;
      alteredColumns = alteredColumns.map((ac) => {
        let altered = { ...ac };
        try {
          altered.headerProps.style.height = height;
        } catch (e) { }
        return altered;
      });
      console.log('before set state');
      setColumns(alteredColumns);
      console.log('after set state');
    };
    if (columns !== undefined) {
      setHeaderHeight();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enableFiltering]);
  // let userName = props.userData.currentUserData.user;
  let newRows = [];
  const onEditComplete = (e) => {
    let v = e.value;
    if (e.cellProps.type === 'boolean') {
      if (e.data[e.columnId] !== true) {
        v = true;
      } else {
        v = false;
      }
    }
    const updateColumnFilterDataSource = (arr) => {
      let newColumns = [...columns];
      newColumns = newColumns.map((col) => {
        let column = { ...col };
        if (column.id === e.columnId) {
          column.filterEditorProps.dataSource = arr;
        }
        return column;
      });
      setColumns(newColumns);
    };
    const setCellValue = (newVal, rowId, colId) => {
      let allRows = [...props.rows];
      allRows = allRows.map((r) => {
        let rw = { ...r };
        if (rw.id === rowId) {
          rw[colId] = newVal;
        }
        return rw;
      });
      props.setRows(allRows);
    };
    let editedData = props.rows.map((d) => {
      let row = { ...d };
      if (e.rowId === row.id) {
        if (e.cellProps.filterEditor === SelectFilter) {
          let keys = e.cellProps.filterEditorProps.dataSource.reduce(
            (arr, obj) => {
              arr.push(obj.id);
              return arr;
            },
            []
          );
          if (!keys.includes(v)) {
            setOptionsModalProps({
              setRow: setCellValue,
              setOptionsArray: updateColumnFilterDataSource,
              optionsArray: e.cellProps.filterEditorProps.dataSource,
              row: e.data,
              column: e.columnId,
              currentValue: e.data[e.columnId],
              newValue: e.value,
            });
            setShowNewOptionsModal(true);
          }
        }
        row = {
          ...row,
          [e.columnId]: v,
        };
      }
      return row;
    });
    // TODO: NIEA
    // updateDocument(
    //   "Product List & Status",
    //   editedData.find((d) => d.id === e.rowId),
    //   e.columnId
    // );
    props.setRows(editedData);
  };
  const insertSpecialChar = (char) => {
    let newRows = [...props.rows];
    let split = Object.keys(cellSelection)[0].split(",");
    let rowID = Number(split[0]);
    let column = split[1];
    let newRow = newRows.find((r) => r.id === rowID);
    newRow = {
      ...newRow,
      [column]: newRow[column] + char,
    };
    newRows = newRows.map((r) => {
      let row = { ...r };
      if (row.id === rowID) {
        row = newRow;
      }
      return row;
    });
    props.setRows(newRows);
  };
  const editCollection = (data, _action) => {
    props.updateOpenArray("Edit Collection", "Product List & Status");
    // props.setLinkExistingCollection(action === 'edit' ? false : true);
    props.setCollectionToEdit(data);
  };
  Object.filter = (obj, predicate) => {
    let newRes = {};
    try {
      Object.keys(obj)
        .filter((key) => predicate(key))
        .reduce((res, key) => {
          res[key] = obj[key];
          return res;
        }, newRes);
    } catch (e) {
      console.log(e);
    }
    return newRes;
  };
  const selectCell = (type, id, rowAction) => {
    let newCells = {};
    if (type === "row") {
      let columnKeys = Object.keys(props.rows[0]);
      if (rowAction === "add") {
        columnKeys.forEach((c) => {
          newCells = { ...newCells, [`${id},${c}`]: true };
        });
        newCells = { ...cellSelectionRef.current, ...newCells };
      } else {
        newCells = Object.filter(
          cellSelectionRef.current,
          (key) => Number(key.split(",")[0]) !== Number(id)
        );
      }
    } else {
      let remove = true;
      let cellsToCheck = [];
      rowsRef.current.forEach((r) => {
        cellsToCheck.push(`${r.id},${id}`);
      });
      cellsToCheck.forEach((c) => {
        if (cellSelectionRef.current[c] !== true) {
          remove = false;
        }
      });
      let header = colRef.current.find((c) => c.id === id);
      header.headerProps.selectColumn = !remove;
      if (remove) {
        newCells = Object.filter(cellSelectionRef.current, (t) => {
          let split = t.split(",");
          return split[1] !== id;
        });
        setCheckedColumns({ ...checkedColumnsRef.current, [id]: false });
      } else {
        setCheckedColumns({ ...checkedColumnsRef.current, [id]: true });
        rowsRef.current.forEach((r) => {
          newCells = { ...newCells, [`${r.id},${id}`]: true };
        });
        newCells = { ...cellSelectionRef.current, ...newCells };
      }
      fixCheckedHeaders(newCells);
    }
    setCellSelection(newCells);
  };
  const fixCheckedHeaders = (selected) => {
    let arr = Object.keys(selected);
    let cols = colRef.current.reduce((array, col) => {
      array.push(col.id);
      return array;
    }, []);
    cols.forEach((col) => {
      let rowCount = arr.filter(
        (string) => string.split(",")[1] === col
      ).length;
      try {
        let header = colRef.current.find((c) => c.id === col);
        if (rowCount < rowsRef.current.length) {
          header.headerProps.selectColumn = false;
        } else if (rowCount === rowsRef.current.length) {
          header.headerProps.selectColumn = true;
        }
        let newColumns = colRef.current.map((h) => {
          if (h.id === header.id) {
            return header;
          }
          return h;
        });
        setColumns(newColumns);
      } catch (e) {
        // console.log('Failed to assign selectColumn property to column:', col);
      }
    });
  };
  if (!productList) {
    props.dispatch(getProductList());
    setProductList(true);
  }
  if (columns.length === 0 && props.fireStoreData.productList !== undefined) {
    productStatusList.setInitialData(
      props.fireStoreData,
      newRows,
      editCollection,
      setColumns,
      props.setRows,
      selectCell,
      checkedColumns,
      props.updateCollections,
      setLoading
    );
  }
  if (props.newExisting === "existing") {
    // const updateVersionNumber = () => {
    //   const findRandomRow = () => {
    //     return Math.floor(Math.random() * props.rows.length);
    //   };
    //   let newRow = props.rows[findRandomRow()];
    //   newRow.Version = Date.now();
    //   // let newRows = props.rows.filter((row) => row.firebaseID !== newRow.fireStoreData);
    //   // setRows([...newRows]);
    // };
    const updateCellStyle = (style) => {
      let styleKey = Object.keys(style)[0];
      let removeStyle = true;
      let keys = Object.keys(cellSelection);
      let keysColumn = [];
      let keysRow = [];
      keys = keys.map((k) => {
        let arr = k.split(",");
        let row = Number(arr[0]);
        keysColumn.push(arr[1]);
        keysRow.push(row);
        return { column: arr[1], row: row };
      });
      const reducer = (filtered, option) => {
        if (option.row === option.rowID) {
          filtered.push(option.column);
        }
        return filtered;
      };
      let newRows = [...props.rows];
      let i = 0;
      let j = 0;
      const findRemoveStyle = () => {
        for (i; i < newRows.length; i++) {
          let row = { ...newRows[i] };
          if (keysRow.includes(row.id)) {
            keys = keys.map((k) => {
              return { ...k, rowID: row.id };
            });
          }
          let filteredKeys = keys.reduce(reducer, []);
          for (j; j < filteredKeys.length; j++) {
            if (
              row.style[filteredKeys[j]] === undefined ||
              row.style[filteredKeys[j]][styleKey] !== style[styleKey]
            ) {
              removeStyle = false;
              return;
            }
          }
        }
      };
      findRemoveStyle();
      newRows = newRows.map((r) => {
        let row = { ...r };
        if (keysRow.includes(row.id)) {
          keys = keys.map((k) => {
            return { ...k, rowID: row.id };
          });
          let filteredKeys = keys.reduce(reducer, []);
          let cellStyles = row.style;
          filteredKeys.map((f) => {
            if (!removeStyle) {
              cellStyles = {
                ...cellStyles,
                [f]: {
                  ...cellStyles[f],
                  ...style,
                },
              };
            } else {
              cellStyles = {
                ...cellStyles,
                // [f]: Object.filter(
                //   cellStyles[f],
                //   (sty) => sty !== styleKey
                // ),
                [f]: {
                  ...cellStyles[f],
                  [styleKey]: "",
                },
              };
            }
            return cellStyles;
          });
          row = {
            ...row,
            style: cellStyles,
          };
          // TODO: NIEA
          // props.dispatch(
          //   updateUserStyles("Product List & Status", row, userName)
          // );
        }
        return row;
      });
      props.setRows(newRows);
    };
    const renderRowContextMenu = (menuProps, { rowProps, cellProps }) => {
      menuProps.autoDismiss = true;
      menuProps.items = [
        {
          label: `Select Row ${rowProps.data.id}`,
          onClick: () => selectCell("row", rowProps.data.id, "add"),
        },
        {
          label: `Deselect Row ${rowProps.data.id}`,
          onClick: () => selectCell("row", rowProps.data.id, "remove"),
        },
      ];
    };
    const updateUserColumnProps = (changeType, newColumnProps) => {
      let updateColumn = { ...newColumnProps.column };
      if (changeType === "orderChange") {
        updateColumn = newColumnProps;
      } else {
        if (changeType === "resize") {
          updateColumn = {
            ...updateColumn,
            defaultWidth: newColumnProps.width,
          };
        } else if (changeType === "visible") {
          updateColumn = {
            ...updateColumn,
            visible: newColumnProps.visible,
          };
        }
      }
      // TODO: NIEA
      // updateUserColumnPrefs("Product List & Status", updateColumn, userName);
    };
    return (
      <ComponentAccordion
        openArray={props.openArray}
        updateOpenArray={props.updateOpenArray}
        componentName="Product List & Status">
        <div className="gameData-productStatus-container">
          <div className="gameData-productStatus-styleBar-container">
            <StyleBar
              fireStoreData={props.fireStoreData}
              cellSelection={cellSelectionRef.current}
              setShowAddColumn={setShowAddColumnModal}
              updateCellStyle={updateCellStyle}
              enableFiltering={enableFiltering}
              setEnableFiltering={setEnableFiltering}
              userPrefs={props.userData}
              recentUserColors={props.recentUserColors}
              updateRecentUserColors={props.updateRecentUserColors}
            />
            <div className="keyboard-container" style={{ overflow: "hidden" }}>
              <div className="keyboard-inner-container">
                {specialCharsA.map((char) => {
                  return (
                    <Button
                      disabled={Object.keys(cellSelection).length !== 1}
                      color="blue"
                      compact
                      icon
                      key={char}
                      className="keyboard-key"
                      onClick={() => insertSpecialChar(char)}>
                      {char}
                    </Button>
                  );
                })}
              </div>
            </div>
          </div>
          {loading ? (
            <Segment
              style={{
                width: "100%",
                height: 500,
                marginTop: 10,
                backgroundColor: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Dimmer active>
                <Loader size="huge">Loading Data</Loader>
              </Dimmer>
            </Segment>
          ) : (
            <ReactDataGrid
              onColumnResize={(columnProps) =>
                updateUserColumnProps("resize", columnProps)
              }
              onColumnOrderChange={(columnProps) =>
                updateUserColumnProps("orderChange", columnProps)
              }
              onColumnVisibleChange={(columnProps) =>
                updateUserColumnProps("visible", columnProps)
              }
              style={{ width: "100%", height: 500, marginTop: 10 }}
              idProperty="id"
              cellSelection={cellSelectionRef.current}
              onCellSelectionChange={(selected) => {
                setCellSelection(selected);
                fixCheckedHeaders(selected);
              }}
              renderRowContextMenu={renderRowContextMenu}
              columns={columns}
              dataSource={props.rows}
              rowHeight={rowHeight}
              onEditComplete={(e) => onEditComplete(e)} // Check if column has a generated list like "Status" or "Product  Type"
              defaultSortInfo={[]}
              defaultFilterValue={productStatusList.filterValue}
              className="gameData-productStatus-dataGrid-container"
              onEnableFilteringChange={() => {
                setEnableFiltering();
              }}
              onLoadingChange={setLoading}
              // enableFiltering={'gter'}
              enableFiltering={enableFiltering}
              filterDelay={false}
            />
          )}
          {showAddColumnModal ? (
            <AddColumnModal
              rows={props.rows}
              setRows={props.setRows}
              selectColumn={checkedColumns}
              selectCell={selectCell}
              columnsArr={columns}
              setColumnsArr={setColumns}
              setShowModal={setShowAddColumnModal}
              userData={props.userData}
            />
          ) : null}

          {showNewOptionsModal ? (
            <OptionsModal
              {...optionsModalProps}
              showModal={showNewOptionsModal}
              setShowModal={setShowNewOptionsModal}
            />
          ) : null}
        </div>
      </ComponentAccordion>
    );
  } else {
    return (
      <ComponentAccordion
        updateOpenArray={props.updateOpenArray}
        openArray={props.openArray}
        componentName="New Collection"
      >
        <div style={{ alignSelf: "flex-start", marginBottom: 10 }}>
          {rowsRef.current.length > 0 ? (
            <AddNewGameRow
              collections={props.collections}
              updateCollections={props.updateCollections}
              setCollectionToEdit={props.setCollectionToEdit}
              fireStoreData={props.fireStoreData}
              rows={rowsRef.current}
              setRows={props.setRows}
              setNewExisting={props.setNewExisting}
              columns={colRef.current}
              editCollection={editCollection}
            />
          ) : null}
        </div>
      </ComponentAccordion>
    );
  }
};
const CollectionHeaderDisplay = (props) => {
  const [rowData, setRowData] = useState([]);
  useEffect(() => {
    setRowData(props.dataSet.headerData);
  }, [props.dataSet]);
  const setNewValue = (obj, data) => {
    let headerData = [...rowData];
    let headerRow = headerData.find((r) => r.Key === data.Key);
    headerRow[data.column] = obj.value;
    headerData = headerData.filter((r) => r.Key !== data.Key);
    setRowData([...headerData, headerRow]);
  };
  const saveData = (data) => {
    props.setDataSet(props.collectionName, { rows: rowData });
    // let headerRow = rowData.find((r) => r.Key === data.Key);
    // TODO: NIEA
    // updateDocument(props.collectionName, headerRow, data.column);
  };
  const headerDataArr = [
    {
      displayColumn: "left",
      label: "Product Type",
      placeholder: "Product Type",
      Key: 400,
      column: "Game",
    },
    {
      displayColumn: "left",
      label: "QR App Name",
      placeholder: "Blackfeet Langauge QR Reader",
      Key: 402,
      column: "English",
    },
    {
      displayColumn: "left",
      label: "Website QRID",
      placeholder: "QRID",
      Key: 402,
      column: "QRID",
    },
    {
      displayColumn: "left",
      label: "Vocab QRID",
      placeholder: "QRID",
      Key: 412,
      column: "QRID",
    },
    {
      displayColumn: "left",
      label: "Table Talk QRID",
      placeholder: "QRID",
      Key: 413,
      column: "QRID",
    },
    {
      displayColumn: "center-left",
      label: "Title",
      placeholder: "Title",
      Key: 400,
      column: "English",
    },
    {
      displayColumn: "center-right",
      label: "Subtitle",
      placeholder: "Subtitle",
      Key: 418,
      column: "English",
    },
    {
      displayColumn: "center-left",
      label: "Language",
      placeholder: "Blackfeet",
      Key: 401,
      column: "English",
    },
    {
      displayColumn: "center-right",
      label: "Age Range",
      placeholder: "Age Range",
      Key: 403,
      column: "English",
    },
    {
      displayColumn: "center-left",
      label: "Number of Players",
      placeholder: "Number of Players",
      Key: 404,
      column: "English",
    },
    {
      displayColumn: "center-right",
      label: "Game Time",
      placeholder: "Game Time",
      Key: 405,
      column: "English",
    },
    {
      displayColumn: "center-left",
      label: "Game Description",
      placeholder: "Game Description...",
      Key: 407,
      column: "English",
    },
    {
      displayColumn: "center-right",
      label: "Copyright",
      placeholder: "Copyright",
      Key: 417,
      column: "English",
    },
    {
      displayColumn: "center-left",
      label: "Artist",
      placeholder: "Artist",
      Key: 410,
      column: "English",
    },
    {
      displayColumn: "center-right",
      label: "Collaborators",
      placeholder: "Collaborators",
      Key: 411,
      column: "English",
    },
    {
      displayColumn: "center-left",
      label: "EDU Box Barcode",
      placeholder: "EDU Box Barcode",
      Key: 414,
      column: "English",
    },
    {
      displayColumn: "center-right",
      label: "Travel Box Barcode",
      placeholder: "Travel Box Barcode",
      Key: 415,
      column: "English",
    },
    {
      displayColumn: "right",
      label: "Card Back",
      placeholder: "Card Back",
      Key: 406,
      column: "@image",
    },
    {
      displayColumn: "right",
      label: "Box Art/Background Image",
      placeholder: "Box Art/Background Image",
      Key: 408,
      column: "@image",
    },
    {
      displayColumn: "right",
      label: "Community Insignia",
      placeholder: "Community Insignia",
      Key: 409,
      column: "@image",
    },
    {
      displayColumn: "right",
      label: "NTA Logo",
      placeholder: "NTA Logo",
      Key: 419,
      column: "@image",
    },
    {
      displayColumn: "right",
      label: "3D Boxshot",
      placeholder: "3D Boxshot",
      Key: 416,
      column: "App_Art",
    },
  ];
  const columnDisplayArr = ["left", "center-left", "center-right", "right"];
  return (
    <Form style={{ margin: 10 }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          flexWrap: "wrap",
          marginBottom: 10,
        }}
      >
        {columnDisplayArr.map((str) => {
          return (
            <div style={{ margin: "0px 5px" }} key={str}>
              {headerDataArr.map((datum) => {
                if (datum.displayColumn === str) {
                  try {
                    let cellValue = rowData.find(
                      (d) => Number(d.Key) === datum.Key
                    )[datum.column];
                    return (
                      <Form.Input
                        onBlur={() => saveData(datum)}
                        key={datum.label}
                        label={datum.label}
                        value={cellValue}
                        onChange={(_e, obj) => setNewValue(obj, datum)}
                      />
                    );
                  } catch (e) {
                    return (
                      <Form.Input
                        onBlur={() => saveData(datum)}
                        key={datum.label}
                        label={datum.label}
                        placeholder={datum.placeholder}
                        value=""
                        onChange={(_e, obj) => setNewValue(obj, datum)}
                      />
                    );
                  }
                }
                return null;
              })}
            </div>
          );
        })}
      </div>
    </Form>
  );
};
const EditCollection = (props) => {
  const gameDataRef = useRef();
  const gameCollectionDataRef = useRef();
  const [showAddColumnModal, setShowAddColumnModal] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [showMediaUploadModal, setShowMediaUploadModal] = useState(false);
  const [showConfirmRowAdd, setShowConfirmRowAdd] = useState(false);
  const [showConfirmRowDelete, setShowConfirmRowDelete] = useState(false);
  gameCollectionDataRef.current = props.gameCollectionData;
  const [enableFiltering, setEnableFiltering] = useState(false);
  let currentCollectionName = props.collection["Tab Name"];
  const [gameData, setGameData] = useState(
    gameCollectionDataRef.current[props.collection["Tab Name"]]
  );
  const [mediaUploadInfo, setMediaUploadInfo] = useState({});
  const [deleteRowId, setDeleteRowId] = useState(null);
  gameDataRef.current = gameData;
  let userName = props.userData.currentUserData.user;
  useEffect(() => {
    const setHeaderHeight = () => {
      let alteredColumns = [...gameData.columns];
      let height = enableFiltering ? 80 : 30;
      alteredColumns = alteredColumns.map((ac) => {
        let altered = { ...ac };
        try {
          // I believe as long as this works once the row will adjust to fit the largest specified height.
          altered.headerProps.style.height = height;
        } catch (e) {
          // console.log('CANNOT ALTER', altered);
        }
        return altered;
      });
      setGameDataState(currentCollectionName, { columns: alteredColumns });
    };
    if (gameData !== undefined) {
      setHeaderHeight();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enableFiltering]);
  const deleteRow = () => {
    console.log(deleteRowId);
    let fixedColletionName = deleteRowId.collectionName;
    if (!fixedColletionName.includes("_test")) {
      fixedColletionName = fixedColletionName + "_test";
    }
    // TODO: NIEA
    // deleteDocument(fixedColletionName, deleteRowId.firebaseID);
    let allRows = [...gameDataRef.current.rows];
    allRows = allRows.filter((r) => r.Key !== deleteRowId.Key);
    setGameDataState(currentCollectionName, { rows: allRows });
  };
  const renderRowContextMenu = (menuProps, { rowProps, cellProps }) => {
    menuProps.autoDismiss = true;
    menuProps.items = [
      {
        label: `Select Row ${rowProps.data.Key}`,
        onClick: () => selectCell("row", rowProps.data.id, "add"),
      },
      {
        label: `Deselect Row ${rowProps.data.Key}`,
        onClick: () => selectCell("row", rowProps.data.id, "remove"),
      },
      {
        label: `Delete Row`,
        // onClick: () => deleteRow(rowProps.data.id),
        onClick: () => {
          console.log(rowProps);
          setDeleteRowId(rowProps.data);
          setShowConfirmRowDelete(true);
        },
      },
    ];
  };
  const setGameDataState = (collectionName, obj) => {
    let newData = { ...gameCollectionDataRef.current };
    let initialDataObj = {
      selectedCells: {},
      columns: [],
      rows: [],
      loading: true,
      checkedColumns: {},
    };
    if (obj !== undefined) {
      if (newData[collectionName] === undefined) {
        newData[collectionName] = {
          // selectedCells: {},
          columns: [],
          rows: [],
          checkedColumns: {},
        };
      }
      newData[collectionName] = {
        ...newData[collectionName],
        ...obj,
        loading: false,
      };
      if (obj.selectedCells !== undefined) {
        newData[collectionName].selectedCells = obj.selectedCells;
        fixCheckedHeaders(newData[collectionName].selectedCells);
      }
    } else {
      newData[collectionName] = initialDataObj;
    }
    setGameData(newData[collectionName]);
    props.setGameCollectionData(newData);
  };
  const onEditComplete = (e) => {
    let v = e.value;
    if (e.cellProps.type === "boolean") {
      if (e.data[e.columnId] !== true) {
        v = true;
      } else {
        v = false;
      }
    }
    // const updateColumnFilterDataSource = (arr) => {
    //   let newColumns = [...gameData.columns];
    //   newColumns = newColumns.map((col) => {
    //     let column = { ...col };
    //     if (column.id === e.columnId) {
    //       column.filterEditorProps.dataSource = arr;
    //     }
    //     return column;
    //   });
    //   setGameDataState(currentCollectionName, {coluns: newColumns});
    // };
    // const setCellValue = (newVal, rowId, colId) => {
    //   let allRows = [...props.rows];
    //   allRows = allRows.map((r) => {
    //     let rw = { ...r };
    //     if (rw.id === rowId) {
    //       rw[colId] = newVal;
    //     }
    //     return rw;
    //   });
    //   props.setRows(allRows);
    // };
    let editedData = gameData.rows.map((d) => {
      let row = { ...d };
      if (e.rowId === row.id) {
        // if (e.cellProps.filterEditor === SelectFilter) {
        //   let keys = e.cellProps.filterEditorProps.dataSource.reduce(
        //     (arr, obj) => {
        //       arr.push(obj.id);
        //       return arr;
        //     },
        //     []
        //   );
        //   if (!keys.includes(v)) {
        //     setOptionsModalProps({
        //       setRow: setCellValue,
        //       setOptionsArray: updateColumnFilterDataSource,
        //       optionsArray: e.cellProps.filterEditorProps.dataSource,
        //       row: e.data,
        //       column: e.columnId,
        //       currentValue: e.data[e.columnId],
        //       newValue: e.value,
        //     });
        //     setShowNewOptionsModal(true);
        //   }
        // }
        row = {
          ...row,
          [e.columnId]: v,
        };
      }
      return row;
    });
    // TODO: NIEA
    // updateDocument(
    //   currentCollectionName,
    //   editedData.find((d) => d.id === e.rowId),
    //   e.columnId
    // );
    // props.setRows(editedData);
    setGameDataState(currentCollectionName, { rows: editedData });
  };
  const fixCheckedHeaders = (selected) => {
    let arr = Object.keys(selected);
    let cols = gameData.columns.reduce((array, col) => {
      array.push(col.id);
      return array;
    }, []);
    cols.forEach((col) => {
      let rowCount = arr.filter(
        (string) => string.split(",")[1] === col
      ).length;
      try {
        let header = gameData.columns.find((c) => c.id === col);
        if (rowCount < gameData.rows.length) {
          header.headerProps.selectColumn = false;
        } else if (rowCount === gameData.rows.length) {
          header.headerProps.selectColumn = true;
        }
        let newColumns = gameData.columns.map((h) => {
          if (h.id === header.id) {
            return header;
          }
          return h;
        });
        setGameDataState(currentCollectionName, { columns: newColumns });
      } catch (e) {
        // console.log("Failed to assign selectColumn property to column:", col);
      }
    });
  };
  const selectCell = (type, id, rowAction) => {
    let newCells = {};
    let checkedColumns = { ...gameDataRef.current.checkedColumns };
    if (type === "row") {
      let columnKeys = Object.keys(gameDataRef.current.rows[0]);
      if (rowAction === "add") {
        columnKeys.forEach((c) => {
          newCells = { ...newCells, [`${id},${c}`]: true };
        });
        newCells = { ...gameDataRef.current.selectedCells, ...newCells };
      } else {
        newCells = Object.filter(
          gameDataRef.current.selectedCells,
          (key) => Number(key.split(",")[0]) !== Number(id)
        );
      }
    } else {
      let remove = true;
      let cellsToCheck = [];
      gameDataRef.current.rows.forEach((r) => {
        cellsToCheck.push(`${r.id},${id}`);
      });
      cellsToCheck.forEach((c) => {
        if (gameDataRef.current.selectedCells[c] !== true) {
          remove = false;
        }
      });
      let header = gameDataRef.current.columns.find((c) => c.id === id);
      header.headerProps.selectColumn = !remove;
      if (remove) {
        newCells = Object.filter(gameDataRef.current.selectedCells, (t) => {
          let split = t.split(",");
          return split[1] !== id;
        });
        checkedColumns = { ...checkedColumns, [id]: false };
      } else {
        checkedColumns = { ...checkedColumns, [id]: true };
        gameDataRef.current.rows.forEach((r) => {
          newCells = { ...newCells, [`${r.id},${id}`]: true };
        });
        newCells = { ...gameDataRef.current.selectedCells, ...newCells };
      }
    }
    setGameDataState(currentCollectionName, {
      checkedColumns: checkedColumns,
      selectedCells: newCells,
    });
  };
  useEffect(() => {
    setGameData(gameCollectionDataRef.current[props.collectionName]);
  }, [props.collectionName]);
  useEffect(() => {
    // console.log('3rd useEffect');
    props.collections.forEach((collection) => {
      if (gameCollectionDataRef.current[collection["Tab Name"]] === undefined) {
        setGameDataState(collection["Tab Name"]);
      }
      if (props.fireStoreData.editableGameData === undefined) {
        props.dispatch(getGameData(props.collection["Tab Name"]));
      } else if (
        props.fireStoreData.editableGameData[collection["Tab Name"]] ===
        undefined
      ) {
        props.dispatch(getGameData(collection["Tab Name"]));
      }
      if (collection.Name.length === 0) {
        // let updatedCollection = { ...collection, Name: collection["Tab Name"] };
        // TODO: NIEA
        // updateDocument(
        //   updatedCollection.collectionName,
        //   updatedCollection,
        //   "Name"
        // );
      }
      if (
        props.fireStoreData.editableGameData &&
        gameCollectionDataRef.current[collection["Tab Name"]] !== undefined &&
        gameCollectionDataRef.current[collection["Tab Name"]].columns.length ===
        0 &&
        props.fireStoreData.editableGameData[collection["Tab Name"]] !==
        undefined
      ) {
        gameDataList.setInitialData(
          props.fireStoreData,
          collection["Tab Name"],
          props.fireStoreData.editableGameData[collection["Tab Name"]],
          {
            selectedCells: {},
            columns: [],
            rows: [],
            loading: true,
            checkedColumns: {},
          },
          setGameDataState,
          selectCell,
          setShowMediaUploadModal,
          setMediaUploadInfo
        );
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.collections,
    props.collection,
    props.fireStoreData.editableGameData,
  ]);
  const updateUserColumnProps = (changeType, newColumnProps) => {
    let updateColumn = { ...newColumnProps.column };
    if (changeType === "orderChange") {
      updateColumn = newColumnProps;
    } else {
      if (changeType === "resize") {
        updateColumn = {
          ...updateColumn,
          defaultWidth: newColumnProps.width,
        };
      } else if (changeType === "visible") {
        updateColumn = { ...updateColumn, visible: newColumnProps.visible };
      }
    }
    // TODO: NIEA
    // updateUserColumnPrefs(currentCollectionName, updateColumn, userName);
  };
  const insertSpecialChar = (char) => {
    let newRows = [...gameData.rows];
    let split = Object.keys(gameData.selectedCells)[0].split(",");
    let rowID = Number(split[0]);
    let column = split[1];
    let newRow = newRows.find((r) => r.id === rowID);
    newRow = {
      ...newRow,
      [column]: newRow[column] + char,
    };
    newRows = newRows.map((r) => {
      let row = { ...r };
      if (row.id === rowID) {
        row = newRow;
      }
      return row;
    });
    // props.setRows(newRows);
    setGameDataState(currentCollectionName, { rows: newRows });
  };
  const updateCellStyle = (style) => {
    let styleKey = Object.keys(style)[0];
    let removeStyle = true;
    let keys = Object.keys(gameData.selectedCells);
    let keysColumn = [];
    let keysRow = [];
    keys = keys.map((k) => {
      let arr = k.split(",");
      let row = Number(arr[0]);
      keysColumn.push(arr[1]);
      keysRow.push(row);
      return { column: arr[1], row: row };
    });
    const reducer = (filtered, option) => {
      if (option.row === option.rowID) {
        filtered.push(option.column);
      }
      return filtered;
    };
    let newRows = [...gameData.rows];
    let i = 0;
    let j = 0;
    const findRemoveStyle = () => {
      for (i; i < newRows.length; i++) {
        let row = { ...newRows[i] };
        if (keysRow.includes(row.id)) {
          keys = keys.map((k) => {
            return { ...k, rowID: row.id };
          });
        }
        let filteredKeys = keys.reduce(reducer, []);
        for (j; j < filteredKeys.length; j++) {
          if (
            row.style[filteredKeys[j]] === undefined ||
            row.style[filteredKeys[j]][styleKey] !== style[styleKey]
          ) {
            removeStyle = false;
            return;
          }
        }
      }
    };
    findRemoveStyle();
    newRows = newRows.map((r) => {
      let row = { ...r };
      if (keysRow.includes(row.id)) {
        keys = keys.map((k) => {
          return { ...k, rowID: row.id };
        });
        let filteredKeys = keys.reduce(reducer, []);
        let cellStyles = row.style;
        filteredKeys.map((f) => {
          if (!removeStyle) {
            cellStyles = {
              ...cellStyles,
              [f]: {
                ...cellStyles[f],
                ...style,
              },
            };
          } else {
            cellStyles = {
              ...cellStyles,
              [f]: {
                ...cellStyles[f],
                [styleKey]: "",
              },
            };
          }
          return cellStyles;
        });
        row = {
          ...row,
          style: cellStyles,
        };
        // TODO: NIEA
        // updateUserStyles(currentCollectionName, row, userName);
      }
      return row;
    });
    // props.setRows(newRows);
    setGameDataState(currentCollectionName, { rows: newRows });
  };
  const findNextKey = () => {
    let keys = gameDataRef.current.rows.reduce((arr, obj) => {
      arr.push(Number(obj.Key));
      return arr;
    }, []);
    let filteredKeys = keys.filter((k) => k < 300 || k >= 700);
    let highestKey = 0;
    filteredKeys.forEach((k) => {
      if (k > highestKey) {
        highestKey = k;
      }
    });
    highestKey = highestKey + 1;
    if (highestKey >= 300 && highestKey < 700) {
      highestKey = 700;
    }
    return highestKey;
  };
  const addRow = () => {
    let newKey = findNextKey();
    let allRows = [...gameDataRef.current.rows];
    let newRow = {};
    gameDataRef.current.columns.forEach((obj) => {
      let initialValue = "";
      if (obj.filterEditor === NumberFilter) initialValue = 0;
      else if (obj.filterEditor === SelectFilter) {
        if (typeof obj.filterEditorProps.dataSource[0].id === "boolean") {
          initialValue = false;
        }
      }
      if (obj.id === "Key") initialValue = newKey;
      newRow[obj.id] = initialValue;
      // newRow.firebaseID = props.fireStoreData.newFirebaseID;
      newRow = {
        ...newRow,
        ...props.fireStoreData.newFirebaseID,
      };
    });
    setGameDataState(currentCollectionName, { rows: [...allRows, newRow] });
  };
  if (gameDataRef.current !== undefined && gameDataRef.current.columns !== undefined) {
    console.log(gameDataRef.current.columns);
  }
  return (
    <ComponentAccordion
      updateOpenArray={props.updateOpenArray}
      openArray={props.openArray}
      componentName="Edit Collection"
    >
      <div>
        {props.collections.length > 0 ? (
          <div className="gameData-collectionButtonArray-container">
            {props.collections.map((collection) => {
              const title =
                collection.Name.charAt(0).toUpperCase() +
                collection.Name.slice(1);
              return (
                <Button
                  key={collection["Tab Name"]}
                  compact
                  color="blue"
                  size="tiny"
                  onClick={() => props.setCollectionToEdit(collection)}
                  className="gameData-collectionButtonArray-outerButton"
                >
                  <div className="gameData-collectionButton-contents">
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div>{title}</div>
                      <div className="gameData-collectionButtonArray-tabName">
                        {collection["Tab Name"]}
                      </div>
                    </div>
                    <div
                      className="gameData-collectionButtonArray-innerButton"
                      onClick={(e) => {
                        e.stopPropagation();
                        props.removeCollection(collection["Tab Name"]);
                      }}
                    >
                      <Icon fitted name="close" />
                    </div>
                  </div>
                </Button>
              );
            })}
          </div>
        ) : null}
        {gameDataRef.current !== undefined &&
          gameDataRef.current.rows.length > 0 ? (
          <InnerComponentAccordion
            expanded={expanded}
            setExpanded={setExpanded}
            header="Header Data (400s)"
          >
            <CollectionHeaderDisplay
              collectionName={currentCollectionName}
              dataSet={gameDataRef.current}
              setDataSet={setGameDataState}
            />
          </InnerComponentAccordion>
        ) : null}

        {gameData === undefined || gameData.loading ? (
          <Segment
            style={{
              width: "100%",
              height: 500,
              marginTop: 10,
              backgroundColor: "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Dimmer active>
              <Loader size="huge">Loading Data</Loader>
            </Dimmer>
          </Segment>
        ) : (
          <div>
            <div className="gameData-productStatus-styleBar-container">
              <StyleBar
                fireStoreData={props.fireStoreData}
                cellSelection={gameDataRef.current.selectedCells}
                setShowAddColumn={setShowAddColumnModal}
                updateCellStyle={updateCellStyle}
                enableFiltering={enableFiltering}
                setEnableFiltering={setEnableFiltering}
                userPrefs={props.userData}
                recentUserColors={props.recentUserColors}
                updateRecentUserColors={props.updateRecentUserColors}
              />
              <div
                className="keyboard-container"
                style={{ overflow: "hidden" }}
              >
                <div className="keyboard-inner-container">
                  {specialCharsA.map((char) => {
                    return (
                      <Button
                        disabled={
                          Object.keys(gameData.selectedCells).length !== 1
                        }
                        color="blue"
                        compact
                        icon
                        key={char}
                        className="keyboard-key"
                        onClick={() => insertSpecialChar(char)}
                      >
                        {char}
                      </Button>
                    );
                  })}
                </div>
              </div>
            </div>











            <ReactDataGrid
              key={currentCollectionName}
              onColumnResize={(columnProps) =>
                updateUserColumnProps("resize", columnProps)
              }
              onColumnOrderChange={(columnProps) =>
                updateUserColumnProps("orderChange", columnProps)
              }
              onColumnVisibleChange={(columnProps) =>
                updateUserColumnProps("visible", columnProps)
              }
              style={{ width: "100%", height: 500, marginTop: 10 }}
              idProperty="id"
              cellSelection={gameData.selectedCells}
              // TODO: fix onCellSelectionChange or determine if it is needed.
              onCellSelectionChange={(selected) => {
                setGameDataState(currentCollectionName, {
                  selectedCells: selected,
                });
              }}
              renderRowContextMenu={renderRowContextMenu}
              columns={gameDataRef.current.columns}
              dataSource={gameDataRef.current.rows}
              rowHeight={rowHeight + 20}
              onEditComplete={(e) => onEditComplete(e)} // Check if column has a generated list like "Status" or "Product Type"
              defaultSortInfo={[]}
              defaultFilterValue={gameDataList.filterValue}
              className="gameData-productStatus-dataGrid-container"
              onEnableFilteringChange={() => {
                setEnableFiltering();
              }}
              enableFiltering={enableFiltering}
              filterDelay={false}
            />










            <Button
              color="blue"
              fluid
              onClick={() => {
                // TODO: NIEA
                // props.dispatch(
                //   createDocument(currentCollectionName + "_test", {
                //     Key: findNextKey(),
                //     id: Date.now(),
                //   })
                // );
                setShowConfirmRowAdd(true);
              }}
            >
              Add Row (next key: {findNextKey()})
            </Button>
          </div>
        )}
        {showMediaUploadModal ? (
          <MediaUploadModal
            gameData={gameDataRef.current}
            setGameData={setGameDataState}
            info={mediaUploadInfo}
            setInfo={setMediaUploadInfo}
            showModal={showMediaUploadModal}
            setShowModal={setShowMediaUploadModal}
          />
        ) : null}
        {showConfirmRowAdd ? (
          <ConfirmAddRowModal
            confirmAddRow={addRow}
            showModal={showConfirmRowAdd}
            setShowModal={setShowConfirmRowAdd}
          />
        ) : null}
        {showConfirmRowDelete ? (
          <ConfirmDeleteRowModal
            rowId={deleteRowId.Key}
            confirmDeleteRow={deleteRow}
            showModal={showConfirmRowDelete}
            setShowModal={setShowConfirmRowDelete}
          />
        ) : null}
        {showAddColumnModal ? (
          <AddColumnModal
            rows={gameDataRef.current.rows}
            selectColumn={gameDataRef.current.checkedColumns}
            selectCell={selectCell}
            columnsArr={gameDataRef.current.columns}
            setGameData={(obj) => setGameDataState(currentCollectionName, obj)}
            setShowModal={setShowAddColumnModal}
            userData={props.userData}
          />
        ) : null}
      </div>
    </ComponentAccordion>
  );
};

const GameData = () => {
  const collectionsRef = useRef([]);
  const gameCollectionDataRef = useRef([]);
  const recentColorsRef = useRef([]);
  const [newExisting, setNewExisting] = useState(null);
  // const [headerData, setHeaderData] = useState({});
  const [collectionToEdit, setCollectionToEdit] = useState(null);
  const dispatch = useDispatch();
  const fireStoreData = useSelector(selectFirestore);
  const userData = useSelector(selectIsLoggedIn);
  const [collections, setCollections] = useState([]);
  // const [linkExistingCollection, setLinkExistingCollection] = useState(false);
  const [openArray, setOpenArray] = useState([]);
  const [productStatusRows, setProductStatusRows] = useState([]);
  const [gameCollectionData, setGameCollectionData] = useState({});
  const [recentUserColors, setRecentUserColors] = useState({ color: [], backgroundColor: [] });
  recentColorsRef.current = recentUserColors;
  useEffect(() => {
    dispatch(getCollectionNames());
    dispatch(getUserPrefs(userData.currentUserData.user));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const updateOpenArray = (addArr = [], removeArr = []) => {
    if (typeof addArr === "string") {
      addArr = [addArr];
    }
    if (typeof removeArr === "string") {
      removeArr = [removeArr];
    }
    let newOpenArray = [...openArray];
    newOpenArray = newOpenArray.reduce((arr, str) => {
      if (!removeArr.includes(str)) {
        arr.push(str);
      }
      return arr;
    }, []);
    newOpenArray = [...newOpenArray, ...addArr];
    setOpenArray(newOpenArray);
  };
  collectionsRef.current = collections;
  gameCollectionDataRef.current = gameCollectionData;
  const updateCollections = (d) => {
    let openCollections = collectionsRef.current.reduce((arr, obj) => {
      arr.push(obj["Tab Name"]);
      return arr;
    }, []);
    if (openCollections.includes(d["Tab Name"])) {
      return;
    }
    setCollections([...collectionsRef.current, d]);
  };
  const removeCollection = (tabName) => {
    let filtered = collectionsRef.current.filter(
      (c) => c["Tab Name"] !== tabName
    );
    if (collectionToEdit["Tab Name"] === tabName) {
      if (filtered.length === 0) {
        setCollectionToEdit(null);
      } else {
        let newOpen = filtered[0];
        collectionsRef.current.forEach((collection, idx) => {
          if (collection["Tab Name"] === tabName) {
            if (idx === 0) {
              newOpen = filtered[0];
            } else {
              newOpen = collectionsRef.current[idx - 1];
            }
          }
        });
        setCollectionToEdit(newOpen);
      }
    }
    setCollections(filtered);
  };
  const updateRecentUserColors = (newColor, id, updateStore) => {
    let updateRecentColors = { ...recentColorsRef.current };
    if (updateRecentColors[id].includes(newColor)) {
      // console.log('Already Exists');
      let arr = updateRecentColors[id];
      arr = arr.filter((c) => c !== newColor);
      arr.push(newColor);
      updateRecentColors = {
        ...updateRecentColors,
        [id]: arr,
      };
    } else {
      // console.log('Does Not Exist');
      updateRecentColors[id].push(newColor);
    }
    if (updateRecentColors[id].length > 20) {
      updateRecentColors[id].shift();
    }
    setRecentUserColors(updateRecentColors);
    // TODO: NIEA
    // if (updateStore !== false) {
    //   updateUserColors(updateRecentColors, userData.currentUserData.user);
    // }
  };
  // const handleLogOutButton = (e, d) => {
  //   // dispatch(logOut());
  // };
  return (
    <div className="current-screen-style">
      {/* <div className="admin-menu-container">
        <AdminNav>
          <Button onClick={handleLogOutButton}>Log Out</Button>
        </AdminNav>
      </div> */}
      <ChooseCollection
        newExisting={newExisting}
        setNewExisting={setNewExisting}
        updateOpenArray={updateOpenArray}
        openArray={openArray}
      />
      {newExisting !== null ? (
        <ProductStatusDataGrid
          // collections={collections}
          recentUserColors={recentUserColors}
          setRecentUserColors={setRecentUserColors}
          updateRecentUserColors={updateRecentUserColors}
          userData={userData}
          rows={productStatusRows}
          setRows={setProductStatusRows}
          openArray={openArray}
          updateOpenArray={updateOpenArray}
          updateCollections={updateCollections}
          collectionToEdit={collectionToEdit}
          setCollectionToEdit={setCollectionToEdit}
          dispatch={dispatch}
          fireStoreData={fireStoreData}
          newExisting={newExisting}
          setNewExisting={setNewExisting}
        // setLinkExistingCollection={setLinkExistingCollection}
        />
      ) : null}
      {collectionToEdit !== null ? (
        // linkExistingCollection ? (
        //   <LinkCollection
        //     rows={productStatusRows}
        //     setRows={setProductStatusRows}
        //     openArray={openArray}
        //     updateOpenArray={updateOpenArray}
        //     collectionToEdit={collectionToEdit}
        //     setCollectionToEdit={setCollectionToEdit}
        //     setLinkExistingCollection={setLinkExistingCollection}
        //     fireStoreData={fireStoreData}
        //     setCollections={setCollections}
        //     collections={collections}
        //     updateCollections={updateCollections}
        //   />
        // ) : (
        <EditCollection
          openArray={openArray}
          updateOpenArray={updateOpenArray}
          removeCollection={removeCollection}
          collection={collectionToEdit}
          collectionName={collectionToEdit["Tab Name"]}
          setCollectionToEdit={setCollectionToEdit}
          collections={collections}
          updateCollections={updateCollections}
          fireStoreData={fireStoreData}
          dispatch={dispatch}
          gameCollectionData={gameCollectionDataRef.current}
          setGameCollectionData={setGameCollectionData}
          userData={userData}
          recentUserColors={recentUserColors}
          updateRecentUserColors={updateRecentUserColors}
        />
      ) : // )
        null}
      <div style={{ height: 65 }} />
    </div>
  );
};

export default GameData;
