/* eslint-disable jsx-a11y/accessible-emoji */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { flip, selectTest } from "../../Store/test";
import {
  selectFirestore,
  getFullDataFirebaseCollection,
  changeUserData,
} from "../../Store/firestore";
import { Button } from "semantic-ui-react";
import { motion, AnimatePresence } from "framer-motion";
import DropdownControlled from "../DropdownControlled";
import InputControlled from "../InputControlled";
import TextEditor from "../TextEditor";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./index.scss";

export default function Test() {
  // const [] = useState();
  // const [editorState, seteditorState] = useState(EditorState.createEmpty());
  const test = useSelector(selectTest);
  const data = useSelector(selectFirestore);
  const dispatch = useDispatch();

  // console.warn("type of data: ", typeof data);

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      variants={{
        hidden: {
          scale: 1,
          opacity: 0,
          x: 500,
        },
        visible: {
          scale: 1,
          opacity: 1,
          x: 0,
          transition: {
            delay: 0.4,
            duration: 0.5,
            type: "spring",
          },
        },
      }}
    >
      <button onClick={() => dispatch(flip())}>flip</button>
      {/* <button onClick={() => dispatch(getFullDataAsync())}>get data</button> */}
      <button
        onClick={() => dispatch(getFullDataFirebaseCollection("Dictionary"))}
      >
        get a collection using firebase library
      </button>
      <div>test result: {String(test)}</div>
      {/* <SignIn /> */}
    <Button onClick={() => {
      console.log("I was clicked");
      dispatch(changeUserData("123"));
    }}> TEST </Button>
      <div className="results">
        <ul>
          <AnimatePresence>
            {data.firebaseCollectionData &&
              data.firebaseCollectionData.Dictionary &&
              data.firebaseCollectionData.Dictionary.map((d) => {
                return (
                  <motion.li
                    key={d.id}
                    initial={{ y: 900, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ duration: 0.6 }}
                  >
                    {d.id} -- {d.data.English} -- {d.data.Language}
                  </motion.li>
                );
              })}
          </AnimatePresence>
        </ul>
      </div>
    </motion.div>
  );
}
