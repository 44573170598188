import './addNewGame.scss'
import React, { useState, useRef, useEffect } from "react";
import {productStatusList} from "../dataGridConfig";
import {Form, Modal, Button} from "semantic-ui-react";
import { FormCheckbox, FormInput } from "./formComponents";
import { selectFirestore, updateDocument, createDocument } from "../../../Store/firestore";
import { useSelector, useDispatch } from "react-redux";

const AddNewGameRow = (props) => {
  const dispatch = useDispatch();
  const fireStoreData = useSelector(selectFirestore);
  const newRowRef = useRef({});
  const tnExistsRef = useRef(false);
  const nExistsRef = useRef(false);
  const [newRow, setNewRow] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [uniqueTabName, setUniqueTabName] = useState(null);
  const [nExists, setNExists] = useState(false);
  const [tnExists, setTNExists] = useState(false);
  let collectionNames = props.fireStoreData.collectionNames;
  newRowRef.current = newRow;
  nExistsRef.current = nExists;
  tnExistsRef.current = tnExists;
  let productStatusTabs = props.rows.reduce((arr, obj) => {
    arr.push(obj["Tab Name"]);
    return arr;
  }, []);
  useEffect(() => { // useEffect to only be called onLoad
    // TODO: testing only?
    dispatch(createDocument('Product List & Status'));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    let initialRow = {};
    props.columns.forEach((col) => {
      if (col.id === "Edit") {
        initialRow[col.id] = productStatusList.addEditButton(
          props.editCollection,
          props.updateCollections
        );
      } else if (col.id === "Version") {
        initialRow.Version = Date.now();
      } else if (col.id === "id") {
        initialRow.id = props.rows.length + 1;
      } else if (col.id === "collectionName") {
        initialRow.collectionName = props.rows[0].collectionName;
      } else {
        initialRow[col.id] = col.type === "boolean" ? false : "";
      }
    });
    setNewRow(initialRow);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.columns]);
  const updateRow = (objKey, value) => {
    let newRowUp = { ...newRowRef.current };
    if (objKey === "Name") {
      let str = value.replace(/ /g, "_");
      newRowUp["Tab Name"] = str;
      newRowUp[objKey] = value;
      newRowUp.Edit = productStatusList.addEditButton(
        props.editCollection,
        props.updateCollections,
        newRowUp
      );
    } else {
      newRowUp[objKey] = value;
    }
    setNewRow(newRowUp);
  };
  const updateDisplay = (row) => {
    let finalTouchRow = row || newRowRef.current;
    finalTouchRow.firebaseID = fireStoreData.newFirebaseID.firebaseID;
    console.log(newRowRef.current);
    setShowModal(false);
    updateDocument(newRowRef.current.collectionName, finalTouchRow);
    props.setCollectionToEdit(finalTouchRow);
    props.updateCollections(finalTouchRow);
    props.setNewExisting("existing");
  };
  const updateExistingGame = () => {
    let newRows = [...props.rows];
    // newRows = newRows.map((row) => {
    //   let datum = {...row};
    //   if (datum['Tab Name'] === newRowRef.current['Tab Name']) {
    //     datum['Tab Name'] = uniqueTabName;
    //   }
    //   return datum;
    // });
    // let halt = newRows.find((row) => row['Tab Name'] === newRowRef.current['Tab Name']);
    // if (halt) {
    //   newRows = newRows.map((row) => {
    //     let datum = {...row};
    //     if (datum['Tab Name'] === newRowRef.current['Tab Name']) {
    //       datum = newRowRef.current;
    //     }
    //   })
    // } else {
      newRows.push(newRowRef.current);
    // }
    props.setRows(newRows);
    updateDisplay();
  };
  const createNewWithUniqueTabName = () => {
    let fixedNewRow = {...newRowRef.current, 'Tab Name': uniqueTabName};
    setNewRow(fixedNewRow);
    props.setRows([...props.rows, fixedNewRow]);
    updateDisplay(fixedNewRow);
  };
  const linkToExistingCollection = () => {
    if (!productStatusTabs.includes(newRowRef.current['Tab Name'])) {
      props.setRows([...props.rows, newRowRef.current]);
    }
    updateDisplay();
  };

  const submitGame = () => {
    if (
      !collectionNames.includes(newRowRef.current["Tab Name"]) &&
      !productStatusTabs.includes(newRowRef.current["Tab Name"])
    ) {
      props.setRows([...props.rows, newRowRef.current]);
      updateDisplay();
    } else {
      props.rows.forEach((row) => {
        if (row["Tab Name"] === newRowRef.current["Tab Name"]) {
          setTNExists(true);
        }
        if (row.Name === newRowRef.current.Name) {
          setNExists(true);
        }
      });
      findUniqueTabName();
      setShowModal(true);
    }
  };
  const findUniqueTabName = () => {
    let tabNameArr = newRowRef.current['Tab Name'].split('_');
    let currentNumber = Number(tabNameArr[tabNameArr.length - 1]);
    if (isNaN(currentNumber)) {
      currentNumber = 1;
    }

    let joined = tabNameArr.join('_');
    if (!isNaN(tabNameArr[tabNameArr.length - 1])) {
      joined = tabNameArr.slice(0, -1);
      joined = joined.join('_');
    }

    let i;
    for (i = currentNumber; uniqueTabName === null; i++) {
      let newName = `${joined}_${i}`;
      if (!collectionNames.includes(newName) && !productStatusTabs.includes(newName)) {
        setUniqueTabName(newName);
        break;
      }
    }
  }
  return (
    <div className="new-game-form-container">
      <Form
        onSubmit={() => {
          submitGame();
        }}
        className="new-game-form"
      >
        <div className="new-game-row-1-container">
          <div className="new-game-row-group-first">
            <FormInput
              required
              updateRow={updateRow}
              label="Name"
              placeholder="Name"
            />
            <FormInput
              updateRow={updateRow}
              label="Product Type"
              placeholder="Product Type"
            />
            <FormInput updateRow={updateRow} label="SKU" placeholder="SKU" />
          </div>
          <div className="new-game-row-group">
            <div className="new-game-row-1-lower">
              Tab Name: {newRowRef.current["Tab Name"]}
            </div>
          </div>
        </div>
        <div className="new-game-row-group">
          <div className="new-game-row-2-group">
            <FormCheckbox updateRow={updateRow} label="EDU" />
            <FormInput
              disabled={!newRowRef.current.EDU}
              updateRow={updateRow}
              placeholder="EDU SKU"
            />
          </div>
          <div className="new-game-row-2-group">
            <FormCheckbox updateRow={updateRow} label="Travel" />
            <FormInput
              inline
              disabled={!newRowRef.current.Travel}
              updateRow={updateRow}
              placeholder="Travel SKU"
            />
          </div>
        </div>
        <div className="new-game-row-group">
          <FormInput
            updateRow={updateRow}
            label="Status"
            placeholder="Status"
          />
          <div className="new-game-row-2-group">
            <FormCheckbox
              updateRow={updateRow}
              label="Date Last Version Approved?"
            />
            <FormInput
              disabled={!newRowRef.current["Date Last Version Approved?"]}
              updateRow={updateRow}
              placeholder="Date Last Version Approved"
            />
          </div>
        </div>
        <div className="new-game-row-group">
          <FormInput
            updateRow={updateRow}
            label="QR-Key Start"
            placeholder="QR-Key Start"
          />
          <FormInput
            updateRow={updateRow}
            label="QR-Key End"
            placeholder="QR-Key End"
          />
        </div>
        <Form.Group>
          <FormCheckbox updateRow={updateRow} label="Home School Kit" />
          <FormCheckbox updateRow={updateRow} label="Head Start Kit" />
        </Form.Group>
        <Form.TextArea
          id="Notes"
          onChange={(_e, data) => updateRow(data.id, data.value)}
          label="Notes"
          placeholder="Notes..."
        />
        <Form.Group>
          <FormCheckbox updateRow={updateRow} label="isQRApp" />
          <FormCheckbox updateRow={updateRow} label="isDictionary" />
          <FormCheckbox updateRow={updateRow} label="isKidsZone" />
        </Form.Group>
        <Form.Button color="blue">Submit</Form.Button>
      </Form>

      <Modal
        open={showModal}
        onOpen={() => setShowModal(true)}
        onClose={() => setShowModal(false)}
      >
        <Modal.Header>
          The collection "{newRowRef.current["Tab Name"]}" already exists!
        </Modal.Header>
        <Modal.Content>
          {nExistsRef.current ? (
            <Modal.Description>
              The Name "{newRowRef.current.Name}" already exists in the Product
              Status List.
            </Modal.Description>
          ) : null}
          {tnExistsRef.current ? (
            <Modal.Description>
              The Tab Name "{newRowRef.current["Tab Name"]}" already exists in
              the Product Status List.
            </Modal.Description>
          ) : null}
          <Modal.Description>What would you like to do?</Modal.Description>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {tnExistsRef.current ? null : (
              // If this collection exists but does not show up in Product Status List
              <Button
                className="new-game-modal-button"
                onClick={updateExistingGame}
                content="Update Product List & Status collection and link to existing collection."
              />
            )}
            <Button
              className="new-game-modal-button"
              onClick={createNewWithUniqueTabName}
              content={`Create a new collection using "${uniqueTabName}".`}
            />
            <Button
              className="new-game-modal-button"
              onClick={linkToExistingCollection}
              content={`Open the collection "${newRowRef.current["Tab Name"]}".`}
            />
            <Button
              className="new-game-modal-button"
              onClick={() => setShowModal(false)}
              content="Keep Editing."
            />
          </div>
        </Modal.Content>
      </Modal>
    </div>
  );
};

export default AddNewGameRow;
