import "./index.scss";
// import "../../App.scss";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Modal } from "semantic-ui-react";
// import "semantic-ui-css/semantic.min.css";
import QrReader from 'react-qr-scanner';

const QRScanner = (props) => {
  const [foundData, setFoundData] = useState(null);
  const [showScanner, setShowScanner] = useState(true);

  const onScanSuccess = (data) => {
    if (data !== null) {
      // Use data.text to query the database.
      // Is there anyway to know what game/poster/etc. this comes from before rooting around in firebase?
      console.log(data);
      setShowScanner(false);
      setFoundData(data);
    }
  };

  const onScanError = (e) => {
    console.log(e);
  };
  const resetScanner = () => {
    setFoundData(null);
    setShowScanner(true);
  }
  let previewStyle = {
    height: 240,
    width: 320,
  };

  return (
    <div className="current-screen-style" style={{ backgroundColor: "beige" }}>
      {showScanner ? (
        <QrReader
          style={previewStyle}
          delay={50000}
          onError={onScanError}
          onScan={onScanSuccess}
        />
      ) : (
        <Modal open={true} className="modalKat">
          <Modal.Header>
            <div>Is this your card?</div>
          </Modal.Header>
          <Modal.Content style={{ display: "flex", justifyContent: "center" }}>
            {foundData !== null ? (
              <div>{foundData.text}</div>
            ) : (
              <div>Searching...</div>
            )}
          </Modal.Content>
          <Modal.Actions>
            <Link to={{ pathname: "/welcome" }}> {/* This should redirect based on the code scanned, ought to go to a game page that features the scanned code */}
              <Button>Redirect</Button>
            </Link>
            <Button onClick={resetScanner}>Scan Again</Button>
          </Modal.Actions>
        </Modal>
      )}
    </div>
  );
};

export default QRScanner;

QRScanner.propTypes = {};
