
// ==> client config <== //

// const langDir = "BF";
const langDir = "Salish";
const langName = "Salish";
// ===================== //

const baseConfigDir = "./Config/";

export const fullConfigDir = baseConfigDir + langDir;

//=====> Salish Keyboard <====//
const lookupTable = {
  a: ["a"],
  c: ["c", "c̓", "č", "č̓"],
  e: ["e"],
  h: ["h"],
  i: ["i"],
  k: ["k", "kʷ", "k̓ʷ"],
  l: ["l", "l̓", "ł", "ƛ̓"],
  m: ["m", "m̓"],
  n: ["n", "n̓"],
  o: ["o", "ó", "óó"],
  p: ["p", "p̓"],
  q: ["q", "q̓", "qʷ", "q̓ʷ"],
  r: ["r"],
  s: ["s", "ss"],
  t: ["t", "t̓"],
  u: ["u"],
  v: ["v"],
  w: ["w", "w̓"],
  x: ["x", "xʷ", "x̣ʷ"],
  y: ["y", "y̓"],
  z: ["z", "ʔ"],
};

const searchTypesArr = [
  { key: "Noun", text: "Nouns", value: "Noun" },
  { key: "Verb", text: "Verbs", value: "Verb" },
  { key: "Affixes", text: "Affixes", value: "Endings" },
  { key: "Prefixes", text: "Prefixes", value: "Beginnings" },
  { key: "Phrases", text: "Phrases", value: "MultiwordPhrases" },
];

const langArr = [
  { key: "Language", text: langName, value: "Language" },
  { key: "English", text: "English", value: "English" },
];

const dataTypeToCompontent = {
  Words: "Words",
};

export const collectionsToDisplay = (collectionName) => {
  const collectionO = {
    I_Nouns: () => "Noun",
    Words: () => "Words",
    I_Verbs: () => "Verb",
  };

  if (typeof collectionO[collectionName] !== "function") {
    return collectionName; // display actual name if not in list.
  }
  return collectionO[collectionName]();
};

export const Show_Quiz = true;

function mediaDirSwitch(lang) {
  const langServerName = {
    "BF": "blackfootmedia",
    "Salish": "salishmedia",
  }
  const staticgoogleapis = "https://storage.googleapis.com/";
  return staticgoogleapis + langServerName[lang];
}

// const IMAGE_PRE = "https://storage.googleapis.com/blackfootmedia/Image/";
const IMAGE_PRE = mediaDirSwitch(langDir) + "/Image/";
const AUDIO_PRE = mediaDirSwitch(langDir) + "/Audio/";
// const AUDIO_PRE = "https://storage.googleapis.com/blackfootmedia/Audio/";

const config = () => {
  const clientConfigs = {
    Salish: {
      baseDir: "https://storage.googleapis.com/salishmedia",
      NativeLanguage: "Salish",
      lookupTable: lookupTable,
      searchTypesArr,
      langArr,
      dataTypeToCompontent,
      IMAGE_PRE,
      AUDIO_PRE,
    },
    BF: {
      baseDir: "https://storage.googleapis.com/blackfootmedia",
      NativeLanguage: "Blackfoot",
      lookupTable: lookupTable,
      searchTypesArr,
      langArr,
      dataTypeToCompontent,
      IMAGE_PRE,
      AUDIO_PRE,
    }
  };
  return clientConfigs[langName];
};

export default config;
