import config from "../../configData";
import { DateTime } from "luxon";

const CONFIG = config();

const IMAGE_PRE = CONFIG.IMAGE_PRE;
const AUDIO_PRE = CONFIG.AUDIO_PRE;

const fetchImage = (fileName) => {
  return `${IMAGE_PRE}${fileName}`;
};
const fetchAudio = (fileName) => {
  let audioObj = new Audio(`${AUDIO_PRE}${fileName}`);
  audioObj.play();
  // return `${AUDIO_PRE}${fileName}`;
};

function formatOptionsForDropdown(list) {
  if (typeof list === "object" && Array.isArray(list)) {
    let optionsDD = list.map((d) => {
      return { key: d, value: d, text: d };
    });
    //check that this is string: add blank as option
    optionsDD.push({ key: "blank", value: "", text: "<blank>" });
    return optionsDD;
  }
}

function debounce(func, wait, immediate) {
  var timeout;
  return function() {
    var context = this,
      args = arguments;
    var later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

function getLanguageArray(input) {
  return CONFIG.lookupTable;
}

function playAudio(file) {
  console.log(file);
  let audio = new Audio(`/audio/${file}`);
  audio.play();
}

const setQuizData = (data) => {
  let audioArray = [
    data["FL1 Speaker Audio_File"] !== undefined &&
      data["FL1 Speaker Audio_File"].length > 0
      ? data["FL1 Speaker Audio_File"]
      : undefined,
    data["FL2 Speaker Audio_File"] !== undefined &&
      data["FL2 Speaker Audio_File"].length > 0
      ? data["FL2 Speaker Audio_File"]
      : undefined,
    data["ML1 Speaker Audio_File"] !== undefined &&
      data["ML1 Speaker Audio_File"].length > 0
      ? data["ML1 Speaker Audio_File"]
      : undefined,
    data["ML2 Speaker Audio_File"] !== undefined &&
      data["ML2 Speaker Audio_File"].length > 0
      ? data["ML2 Speaker Audio_File"]
      : undefined,
  ];
  audioArray = audioArray.filter((string) => string !== undefined);
  let finalData = {
    Key:
      data.Key !== undefined && !isNaN(Number(data.Key))
        ? Number(data.Key)
        : undefined,
    App_Art:
      data.App_Art !== undefined && data.App_Art.length > 0
        ? data.App_Art
        : undefined,
    Audio_Array: audioArray,
    Category:
      data.Category !== undefined && data.Category.length > 0
        ? data.Category
        : undefined,
    Subcategory:
      data.Subcategory !== undefined && data.Subcategory.length > 0
        ? data.Subcategory
        : undefined,
    Definition:
      data.Definition !== undefined && data.Definition.length > 0
        ? data.Definition
        : undefined,
    English: data.English,
    Language: data.Language,
    collectionName: data.collectionName,
    Grammatical_Category:
      data.Grammatical_Category !== undefined &&
        data.Grammatical_Category.length > 0
        ? data.Grammatical_Category
        : undefined,
  };
  return finalData;
};

const randomNumber = (highNum) => {
  let number = Math.floor(Math.random() * highNum);
  return number;
};

const dateToTimeElapsed = (date) => {
  let mydate = DateTime.fromHTTP(date);
  //TODO: detect invalid DateTime
  if (mydate.invalid) {
    return "";
  }
  return mydate.toLocaleString(DateTime.DATETIME_FULL);
};

const dateToFriendly = (date) => {
  let dateObj = DateTime.fromHTTP(date);
  let now = DateTime.now();
  let diff = now.diff(dateObj);

  if (dateObj.invalid) {
    return "";
  }
  let formatedTimeDiff = "";
  if (diff.as("days") < 20) {
    formatedTimeDiff = `${Math.trunc(diff.as("days"))} days ago`;
  } else {
    formatedTimeDiff = `${Math.trunc(diff.as("months"))} months ago`;
  }
  // console.log("formated diff display: ", formatedTimeDiff);
  return formatedTimeDiff;
};

const simplifyString = (string) => {
  return string.replace(/[^a-zA-Z0-9]/g, "_");
}

const compare = (a, b) => {
  let engA = a.toString().toUpperCase();
  let engB = b.toString().toUpperCase();
  let comparison = 0;
  if (!isNaN(Number(engA)) && !isNaN(Number(engB))) {
    if (Number(engA) > Number(engB)) {
      comparison = 1;
    } else {
      comparison = -1;
    }
  } else {
    if (engA > engB) {
      comparison = 1;
    } else {
      comparison = -1;
    }
  }
  return comparison;
};

export {
  simplifyString,
  debounce,
  playAudio,
  fetchImage,
  fetchAudio,
  getLanguageArray,
  setQuizData,
  randomNumber,
  formatOptionsForDropdown,
  dateToFriendly,
  dateToTimeElapsed,
  compare,
};
