import { useRef, useLayoutEffect } from "react";

const isBrowser = typeof window !== "undefined";

function getScrollPosition({ element, offset }) {
  if (!isBrowser) return { x: 0, y: 0 };

  const target = element ? element.current : document.body;
  if (!target) {
    return false;
  }
  const position = target.getBoundingClientRect();
  let scrollMaxHeight =
    document.getElementsByClassName("main-content")[0].clientHeight + offset;
  let getMoreData = scrollMaxHeight >= position.bottom;
  return {
    bottom: position.bottom,
    maxHeight: scrollMaxHeight,
    getMoreData: getMoreData,
  };
}

function useLoadMoreData({ effect, deps, element, offset, wait }) {
  const position = useRef(getScrollPosition({ element }));
  let throttleTimeout = null;

  const callBack = () => {
    const currPos = getScrollPosition({ element, offset });
    effect({ prevPos: position.current, currPos });
    position.current = currPos;
    throttleTimeout = null;
  };
  useLayoutEffect(() => {
    const handleScroll = () => {
      if (wait) {
        if (throttleTimeout == null) {
          // eslint-disable-next-line
          throttleTimeout = setTimeout(callBack, wait);
        }
      } else {
        callBack();
      }
    };
    let domElement = document.getElementsByClassName("main-content")[0];
    domElement.addEventListener("scroll", handleScroll);
    return () => domElement.removeEventListener("scroll", handleScroll);
  }, deps);
}

export default useLoadMoreData;
