import "./addColumn.scss";
// import "../../../App.scss";
import React, { useState, useRef, useEffect } from "react";
// import { Link } from "react-router-dom";
import {
  Button,
  Icon,
  Checkbox,
  Form,
} from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";
import NumberFilter from "@inovua/reactdatagrid-community/NumberFilter";
import StringFilter from "@inovua/reactdatagrid-community/StringFilter";
import {
  getHeader,
  findCellStyle,
  productStatusList,
  boolSelectValues,
  gameDataList,
} from "../dataGridConfig";
import {
  selectFirestore,
  updateDocument,
  updateUserColumnPrefs,
} from "../../../Store/firestore";
import { useSelector } from "react-redux";
import Dropzone from '../../Dropzone/index';
import { fetchImage } from "../../nonUIFuncs";

let filterArr = [
  {
    key: 'string',
    text: 'String Filter',
    value: StringFilter,
  },
  {
    key: 'number',
    text: 'Number Filter',
    value: NumberFilter,
  },
  {
    key: 'select',
    text: 'Select Filter',
    value: SelectFilter,
  },
];
let typeArr = [
  {
    key: 'text',
    text: 'Text',
    value: 'text',
  },
  {
    key: 'boolean',
    text: 'Checkbox',
    value: 'boolean',
  },
  {
    key: 'number',
    text: 'Number',
    value: 'number',
  },
];
const CustomModal = (props) => {
  return (
    <div
      className="addColumnModal-fullScreen"
      onClick={(e) => {
        props.setShowModal(false);
        e.stopPropagation();
      }}
    >
      <Form
        className="addColumnModal-container"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="addColumnModal-header">
          <div className="addColumnModal-header-text">{props.modalHeader}</div>
        </div>
        {props.modalContent ? (
          <div className="addColumnModal-content">{props.modalContent}</div>
        ) : null}
        <div className="addColumnModal-actions noselect">
          {props.modalActions.left}
          {props.modalActions.right}
        </div>
      </Form>
    </div>
  );
};

const AddColumnModal = (props) => {
  const fireStoreData = useSelector(selectFirestore);
  const arrRef = useRef([]);
  const newColumnRef = useRef({});
  const filterArrRef = useRef([]);
  const [arr, setArr] = useState([]);
  const [newColumn, setNewColumn] = useState({});
  // const [newColumnProps, setNewColumnProps] = useState({});
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [selectFilterArray, setSelectFilterArray] = useState([
    { key: 1, label: 'Filter Name', value: null },
    { key: 2, label: 'Filter Name', value: null },
    { key: 3, label: 'Filter Name', value: null },
  ]);
  const [disableFinish, setDisableFinish] = useState(true);
  const columnNames = props.columnsArr.reduce((array, obj) => {
    array.push(obj.id);
    return array;
  }, []);
  let startObj = {
    resizable: { type: "boolean", value: true },
    sortable: { type: "boolean", value: true },
    defaultWidth: { type: "number", value: 140 },
    editable: { type: "boolean", value: true },
    id: { type: "string", value: "" },
    name: { type: "string", value: "" },
    filterEditor: { type: "select", value: StringFilter },
    filterEditorProps: {
      type: "object",
      value: { placeholder: "All", stringvalue: "StringFilter" },
    },
    type: { type: "select", value: "string" },
    rendersInlineEditor: { type: "boolean", value: false },
  };
  useEffect(() => {
    let colKeys = props.columnsArr.reduce((arr, obj) => {
      let keys = Object.keys(obj);
      let uniqueKeys = keys.filter((s) => !arr.includes(s));
      arr = [...arr, ...uniqueKeys];
      return arr;
    }, []);
    let keys = Object.keys(startObj);
    let col = keys.reduce((object, str) => {
      object[str] = startObj[str].value;
      return object;
    }, {});
    setNewColumn(col);
    setArr(colKeys);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  arrRef.current = arr;
  newColumnRef.current = newColumn;
  filterArrRef.current = selectFilterArray;
  // We are going to asume any column the users want to create and not have created for them are going to be pretty simple, no buttons/images/html in any way
  // also assuming that it needs to be editable, sortable, resizable etc. full permissions

  const updateSelectArray = (key, value) => {
    let filterProps = [...filterArrRef.current];
    filterProps = filterProps.map((d) => {
      let datum = { ...d };
      if (d.key === key) {
        if (value.length === 0) {
          datum.value = null;
        } else {
          if (value.toUpperCase() === 'TRUE') {
            datum.value = true;
          } else if (value.toUpperCase() === 'FALSE') {
            datum.value = false;
          } else {
            datum.value = value;
          }
        }
      }
      return datum;
    });
    let values = filterProps.reduce((arr, obj) => {
      arr.push(obj.value);
      return arr;
    }, []);
    if (!values.includes(null)) {
      filterProps.push({ key: filterProps.length + 1, label: 'Filter Name', value: null });
    }
    setSelectFilterArray(filterProps);
  };
  let filterObj = {
    name: newColumnRef.current.name, // TODO: Might Yell
    operator: "startsWith",
    type: "string",
    value: "",
  };
  const updateFilterValues = () => {
    if (newColumnRef.current.type === 'number') {
      filterObj = {
        name: newColumnRef.current.name,
        operator: "gte",
        type: "number",
      };
    } else if (newColumnRef.current.type === "boolean") {
      filterObj = {
        name: newColumnRef.current.name,
        operator: "eq",
        type: "boolean",
      };
    }
    if (props.setGameData !== undefined) {
      gameDataList.updateFilterValue(filterObj);
    } else {
      productStatusList.updateFilterValue(filterObj);
    }
  };

  const updateColumn = (key, value) => {
    let editColumn = { ...newColumnRef.current };
    editColumn[key] = value;
    if (key === 'filterEditor') {
      let stringvalue = "StringFilter";
      if (value === SelectFilter) {
        stringvalue = 'SelectFilter';
      } else if (value === NumberFilter) {
        stringvalue = 'NumberFilter';
      }
      editColumn.filterEditorProps = { ...editColumn.filterEditorProps, stringvalue: stringvalue };
    }
    if (key === 'filterable') {
      if (value === false) {
        editColumn['filterEditor'] = null;
      } else {
        delete editColumn[key];
        editColumn["filterEditor"] = StringFilter;
        editColumn.filterEditorProps = {
          ...editColumn.filterEditorProps,
          stringvalue: "StringFilter",
        };
      }
    } else if (key === 'name') {
      if (columnNames.includes(value)) {
        setDisableFinish(true);
      } else {
        setDisableFinish(false);
      }
      editColumn.id = value;
    } else if (key === 'defaultWidth') {
      editColumn[key] = Number(value);
    } else if (key === 'type' && value === 'boolean') {
      editColumn["filterEditor"] = SelectFilter;
      editColumn.filterEditorProps = {
        ...editColumn.filterEditorProps,
        stringvalue: "SelectFilter",
      };
      let filterProps = boolSelectValues.reduce((arr, obj) => {
        arr.push({ key: obj.label, label: obj.label, value: obj.id });
        return arr;
      }, []);
      setSelectFilterArray(filterProps);
    }
    setNewColumn(editColumn);
  };

  const _finishNewColumn = () => {
    let editColumn = { ...newColumnRef.current };
    // TODO: this is where we should check to see if the column name/id is unique.
    updateFilterValues();
    editColumn.filterEditorProps = { ...editColumn.filterEditorProps, filterValueObj: filterObj };
    if (newColumnRef.current.filterEditor === SelectFilter) {
      let correctedArr = filterArrRef.current.filter(
        (f) => f.value !== null && f.value.length !== 0
      );
      let dataSource = correctedArr.map((filter) => {
        return { id: filter.value, label: filter.value.toString() };
      });
      if (newColumnRef.current.type === 'boolean') {
        dataSource = boolSelectValues;
      }
      editColumn.filterEditorProps = {
        ...editColumn.filterEditorProps,
        dataSource: dataSource,
      };
    }
    if (newColumnRef.current.type === "boolean") {
      editColumn.defaultWidth =
        Number(editColumn.defaultWidth) !== 140 ? editColumn.defaultWidth : 100;
      editColumn.rendersInlineEditor = true;
      editColumn.render = ({ value }, { cellProps }) => {
        let v = cellProps.editProps.inEdit ? cellProps.editProps.value : value;
        return (
          <div
            key={`${cellProps.id}${cellProps.data.id}`}
            className="dataGridConfig-booleanCheckbox-container"
          >
            <Checkbox
              onClick={() => cellProps.editProps.onComplete()}
              autoFocus={cellProps.inEdit}
              checked={v}
            />
          </div>
        );
      };
    }
    editColumn.onRender = (cellProps, { data }) => {
      cellProps.style = findCellStyle(cellProps, data, fireStoreData.userPrefs);
    };
    editColumn.header = getHeader(newColumnRef.current.name, props.selectCell);
    editColumn.headerProps = {
      selectColumn: props.selectColumn[newColumnRef.current.name],
      style: { position: "relative" },
    };
    updateUserColumnPrefs(
      props.rows[0].collectionName,
      editColumn,
      props.userData.currentUserData.user
    );
    let newColumnOrder = [...props.columnsArr, editColumn];
    newColumnOrder = newColumnOrder.reduce((arr, obj) => {
      arr.push(obj.id);
      return arr;
    }, [])
    updateUserColumnPrefs(
      props.rows[0].collectionName,
      newColumnOrder,
      props.userData.currentUserData.user
    );
    setNewColumn(editColumn);
    let alteredRows = [...props.rows];
    alteredRows = alteredRows.reduce((arr, obj) => {
      let newColumnObj = {
        [editColumn.id]: editColumn.type === "boolean" ? false : "",
      };
      let newObj = { ...obj, ...newColumnObj };
      updateDocument(obj.collectionName, newObj, editColumn.id);
      arr.push(newObj);
      return arr;
    }, []);
    if (props.setGameData !== undefined) {
      props.setGameData({
        rows: alteredRows,
        columns: [...props.columnsArr, editColumn],
      });
    } else {
      props.setRows(alteredRows);
      props.setColumnsArr([...props.columnsArr, editColumn]);
    }
    props.setShowModal(false);
  };

  let ModalContent = (
    <div>
      <Form.Input
        inline
        label="Name"
        placeholder=""
        onChange={(e, d) => updateColumn("name", d.value)}
      />
      <Form.Dropdown
        inline
        label="Input Type"
        placeholder="Text"
        onChange={(e, d) => updateColumn("type", d.value)}
        options={typeArr}
      />
      {/* {arrRef.current.map((a) => {
          if (!hiddenArr.includes(a)) {
            return <div style={{ height: 20 }}>{a}</div>;
          }
        })} */}
      <div
        onClick={() => setShowAdvanced(!showAdvanced)}
        className="addColumnModal-content-advanced-bar noselect"
      >
        <hr />
        <div style={{ lineHeight: "1em", fontSize: 15 }}>Advanced</div>
        <div className="addColumnModal-content-advanced-bar-arrow">
          <Icon
            name={showAdvanced ? "chevron down" : "chevron right"}
            style={{
              position: "relative",
              top: -2,
              width: 10,
              height: 15,
              fontSize: 15,
              padding: 0,
              margin: 0,
            }}
          />
        </div>
        <hr />
      </div>
      {showAdvanced ? (
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: 10,
            }}
          >
            <Form.Input
              label="Default Width"
              placeholder="140"
              type="number"
              onChange={(e, d) => updateColumn("defaultWidth", d.value)}
            />
            <div style={{ margin: "0 30px" }}>
              <Form.Checkbox
                label="Filterable"
                onChange={(e, d) => updateColumn("filterable", d.checked)}
                checked={newColumnRef.current.filterable}
              />
              <Form.Dropdown
                options={filterArr}
                placeholder="String Filter"
                disabled={
                  !newColumnRef.current.filterable ||
                  newColumnRef.current.type === "boolean"
                }
                inline
                label="Filter Type"
                onChange={(e, d) => updateColumn("filterEditor", d.value)}
              />
            </div>
          </div>
          {newColumnRef.current.filterEditor === SelectFilter ? (
            <div style={{ marginBottom: 30 }}>
              {selectFilterArray.map((filter) => {
                return (
                  <Form.Input
                    disabled={newColumnRef.current.type === "boolean"}
                    value={filter.value}
                    inline
                    size="tiny"
                    label={filter.label}
                    key={filter.key}
                    onChange={(e, d) => updateSelectArray(filter.key, d.value)}
                  >
                    <input style={{ margin: 0, padding: 5 }} />
                  </Form.Input>
                );
              })}
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
  let ModalHeader = 'Add a Column';
  let ModalActions = {
    left: (
      <div className="addColumnModal-actions-left">
        <Form.Button color="black" onClick={() => props.setShowModal(false)}>
          Cancel
        </Form.Button>
      </div>
    ),
    right: (
      <div className="addColumnModal-actions-right">
        {/* <Form.Button
          as={Button}
          color="black"
          onClick={() => props.setShowModal(false)}
        >
          Save
        </Form.Button> */}
        <div className="addColumnModal-actions-finish-button-container">
          <div
            className={
              columnNames.includes(newColumn.name)
                ? "addColumnModal-actions-warning-text-visible"
                : "addColumnModal-actions-warning-text-hidden"
            }
          >
            <div className="addColumnModal-actions-warning-text">
              This column name already exists!
            </div>
          </div>
          <Form.Button
            disabled={disableFinish}
            color="black"
            onClick={_finishNewColumn}
          >
            Finish
          </Form.Button>
        </div>
      </div>
    ),
  };

  return (
    <CustomModal
      showModal={props.showModal}
      setShowModal={props.setShowModal}
      modalHeader={ModalHeader}
      modalContent={ModalContent}
      modalActions={ModalActions}
    />
  );
};
const ConfirmAddRowModal = (props) => {
  let ModalHeader = 'Are you sure you want to add a row?';
  let ModalActions = {
    left: (
      <div className="addColumnModal-actions-left">
        {/* <Form.Button color="black" onClick={() => props.setShowModal(false)}>
          Cancel
        </Form.Button> */}
      </div>
    ),
    right: (
      <div className="addColumnModal-actions-right">
        {/* <div className="addColumnModal-actions-finish-button-container"> */}
        <Form.Button color="black" onClick={() => props.setShowModal(false)}>
          Cancel
        </Form.Button>
        <Form.Button
          color="black"
          onClick={() => {
            props.setShowModal(false);
            props.confirmAddRow();
          }}
        >
          Continue
        </Form.Button>
        {/* </div> */}
      </div>
    ),
  };

  return (
    <CustomModal
      showModal={props.showModal}
      setShowModal={props.setShowModal}
      modalHeader={ModalHeader}
      // modalContent={ModalContent}
      modalActions={ModalActions}
    />
  );
};
const ConfirmDeleteRowModal = (props) => {
  let ModalHeader = `Delete row ${props.rowId}?`;
  let ModalActions = {
    left: (
      <div className="addColumnModal-actions-left" />
    ),
    right: (
      <div className="addColumnModal-actions-right">
        <Form.Button color="black" onClick={() => props.setShowModal(false)}>
          Cancel
        </Form.Button>
        <Form.Button
          color="black"
          onClick={() => {
            props.setShowModal(false);
            props.confirmDeleteRow();
          }}
        >
          Continue
        </Form.Button>
      </div>
    ),
  };
  return (
    <CustomModal
      showModal={props.showModal}
      setShowModal={props.setShowModal}
      modalHeader={ModalHeader}
      modalActions={ModalActions}
    />
  );
};

const MediaUploadModal = (props) => {
  const uploadedImageRef = useRef();
  const [uploadedImage, setUploadedImage] = useState({ file: '' });
  const rowData = props.info.cellProps.data;
  uploadedImageRef.current = uploadedImage;
  const useNewImage = () => {
    let imageSplit = uploadedImage.split('/');
    let image = imageSplit[imageSplit.length - 1];
    let gameDataRows = [...props.gameData.rows];
    gameDataRows = gameDataRows.map((datum) => {
      let newDatum = { ...datum };
      if (rowData.firebaseID === newDatum.firebaseID) {
        newDatum[props.info.cellProps.id] = image;
      }
      return newDatum;
    });
    updateDocument(
      rowData.collectionName,
      gameDataRows.find((d) => d.firebaseID === rowData.firebaseID),
      props.info.cellProps.id
    );
    props.setGameData(rowData.collectionName, { rows: gameDataRows });
    props.setShowModal(false);
  }
  let ModalContent = (
    <div className="mediauploadmodal-content">
      <Dropzone setUploadedImage={setUploadedImage} />
      <div className="mediauploadmodal-content-rightcontent">
        {/* This is the image that already exists, if length === 0 do not display*/}
        {props.info.value.length > 0 ? (
          <div className="mediauploadmodal-content-rightcontent-imagecontainer">
            <p>Current</p>
            <img
              className="mediauploadmodal-content-rightcontent-image"
              alt=""
              src={fetchImage(props.info.value)}
            />
          </div>
        ) : null}

        {/* This is the image that is getting uploaded, if length === 0 do not display*/}
        {uploadedImageRef.current.file.length > 0 ? (
          <div className="mediauploadmodal-content-rightcontent-imagecontainer">
            <p>New</p>
            <img
              className="mediauploadmodal-content-rightcontent-image"
              alt=""
              src={uploadedImageRef.current.file}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
  let ModalHeader = `${rowData.collectionName} - ${rowData.English}(${rowData.Language}) - ${props.info.cellProps.id}`;
  let ModalActions = {
    left: (
      <div className="addColumnModal-actions-left">{/* Left Buttons */}</div>
    ),
    right: (
      <div className="addColumnModal-actions-right">
        <Button onClick={() => props.setShowModal(false)} color="blue">
          Cancel
        </Button>
        <Button
          onClick={useNewImage}
          color="blue"
          disabled={uploadedImageRef.current.file.length === 0}>
          Use new Image
        </Button>
      </div>
    ),
  };

  return (
    <CustomModal
      showModal={props.showModal}
      setShowModal={props.setShowModal}
      modalHeader={ModalHeader}
      modalContent={ModalContent}
      modalActions={ModalActions}
    />
  );
};
const OptionsModal = (props) => {
  const [existingOption, setExistingOption] = useState(null);
  const _setExisting = () => {
    props.setRow(existingOption.label, props.row.id, props.column);
    props.setShowModal(false);
  }
  const _cancel = () => {
    props.setRow(props.currentValue, props.row.id, props.column);
    props.setShowModal(false);
  }
  const _addNewOption = () => {
    let newDataSource = props.optionsArray;
    newDataSource.push({ id: props.newValue, label: props.newValue });
    props.setOptionsArray(newDataSource);
    props.setRow(props.newValue, props.row.id, props.column);
    props.setShowModal(false);
  }

  let ModalContent = (
    <div style={{ padding: 15 }}>
      <div>Does an existing option work instead?</div>
      <div style={{ margin: '15px 100px' }}>
        {props.optionsArray.map((option) => {
          return (
            <Button
              style={{ margin: 5 }}
              key={option.label}
              onClick={() => setExistingOption(option)}
            >
              {option.label}
            </Button>
          );
        })}
      </div>
    </div>
  );
  let ModalHeader = 'Would you like to add a new Option to this list?';
  let ModalActions = {
    left: (
      <div className="addColumnModal-actions-left">
        <Form.Button
          color="black"
          onClick={_cancel}>
          No
        </Form.Button>
      </div>
    ),
    right: (
      <div className="addColumnModal-actions-right">
        <Form.Button
          color="black"
          disabled={existingOption === null}
          onClick={() => _setExisting(existingOption)}>
          Use Existing Option
        </Form.Button>
        <Form.Button
          color="black"
          onClick={_addNewOption}
        >
          Yes
        </Form.Button>
      </div>
    ),
  };

  return (
    <CustomModal
      showModal={props.showModal}
      setShowModal={props.setShowModal}
      modalHeader={ModalHeader}
      modalContent={ModalContent}
      modalActions={ModalActions}
    />
  );
};

export {
  AddColumnModal,
  OptionsModal,
  MediaUploadModal,
  ConfirmAddRowModal,
  ConfirmDeleteRowModal,
};
