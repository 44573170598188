import React from "react";
/*
COPY THESE INSTRUCTIONS TO ALL NEW LANGUAGE CONFIG FILES (FOR EASY ACCESS)

Three files need their import statements altered for a Language Switch
1. App.scss - handles all styling changes
2. App.js - Enabling contents to change throughout the app to fit the Language
3. Store/firestore.js - Enables data from correct firestore database
*/

document.title = "Seliš u Ql̓ispé Nuwewlštn";

// const tabNameWelcome = "Welcome (salish)";
const tabNameWelcome = "Snlciʔtn";
// const tabNameDictionary = "Dictionary (salish)";
const tabNameDictionary = "Nuwewlštn Nyoʔnuntn";
const loginLangTxt = "Nʔułxʷ"
const firebaseConfig = {
  apiKey: "AIzaSyDcG0JRsEx6Ecq34NivPj7NihMotNYi8U0",
  authDomain: "salish-7c4e1.firebaseapp.com",
  projectId: "salish-7c4e1",
  storageBucket: "salish-7c4e1.appspot.com",
  messagingSenderId: "384220497463",
  appId: "1:384220497463:web:40c34d91fb0ee89cc461b2",
  measurementId: "G-9HB6C8GQQS",
};

const linkArray = [
  // { // Example Data
  //   link: false,
  //   text: "Indian Education for All", // Text for tab (displayed)
  //   activeName: "bcc", // Name to show active tab styling (not displayed)
  //   navText: "http://blackfootkeystone.com/IEFA/", // Web address or link pageName
  //   navType: "external", // Is this a page inside this project of an external source?
  // },
];

const welcomeEngArray = [
  'Qe lemt łu kʷ cxʷuy! iheʔ x̣ʷl̓ qe Seliš u Ql̓ispé nuwewlštn. Yetłxʷasq̓t qe y̓amncut u qe es wičstm łu nxʷlxʷiltn ta es hoy qe cxʷic̓łlt łu qeqł nxʷlxʷiltn iqs šiʔmnwexʷ l es yaʔ łu l es xʷlxʷilt put u yetłxʷasq̓t łu qe nk̓ʷłaxmintn nk̓ʷuʔ wilš łu ne qe es lmntmnwexʷ u qe es cuti lemlmtš č̓ es yaʔ sqlqelixʷ x̣ʷl̓ qe sqelixʷ. ',
  'Welcome to the Salish – Qlipse (Pend d’Oreille) Online Dictionary. We hope this site helps you with learning and understand our language. We hope to include the language dialects of the Bitterroot Salish, Upper Kalispel, Lower Kalispel and Spokane tribes. We want this to be an inclusive language tool for all to use and share.',
  'Lemlmtš Pesyaʔ – Thanks Everyone',
  'First we give thanks to our Creator and to our ancestors for all that they have done and given to us.',
  'We give thanks to our Elders that have worked tirelessly to share their knowledge with us so that our language can live on:',
  <div><ul>
    <li>Felicite McDonald</li>
    <li>Eneas Vanderburg</li>
    <li>Mary “Dolly” Linsebigler</li>
    <li>Francis Stanger</li>
    <li>Pat Pierre</li>
    <li>Sophie Haines</li>
    <li>Noel Pichette</li>
    <li>Louie Adams</li>
    <li>Eva Boyd</li>
    <li>Frances Vanderburg</li>
    <li>Harriet McDougal</li>
    <li>Annie Vanderburg</li>
    <li>Stephen Smallsalmon</li>
    <li>Johnny Arlee</li>
    <li>Shirley Trahan</li>
    <li>Lucy Vanderburg</li>
    <li>Tony Incashola</li>
    <li>Andrew Woodcock</li>
    <li>Chauncey Beaverhead</li>
    <li>Stan Bluff</li>
    <li>Francis Cullooyah</li>
  </ul></div>,
  'Lemlmtš Pesyaʔ – We would also like to give thanks to all that have helped make this project happen!',
  'For information about online Salish Dictionary please email chaney_bell@hotmail.com or thesalishinstitute@gmail.com',
  <h2>Bibliography</h2>,
  <div>
    <ul>
      <li>Salish-Pend d’Oreille Culture Committee. Language Vocabulary: Word-Guide Book 1. SPCC updated edition 2001</li>
      <li>Salish-Pend d’Oreille Culture Committee. Common Names of the Flathead Language. SPCC 3rd edition 1996</li>
      <li>Salish-Pend d’Oreille People. A Beginning Course in Salish & Pend d’Oreille Dialect: Salish Language Curriculum. 3 levels 2011-2016</li>
      <li>Pete, Tachini. Seliš nyoʔnuntn: Medicine for the Salish Language. English to Salish Translation Dictionary. University of Nebraska Press, 2010</li>
    </ul>
  </div>,
  <div><h3>
    All Content and Language © Salish and Qlispe People
  </h3></div>,
  <div><h3>
    Developed by Native Teaching Aids.
  </h3></div>,
];

const welcomeLangArray = [
];

const welcomeLogoArray = ["logo-nta", "logo-salish-logo"];
const welcomeMessageArray = [""];

const config = {
  linkArray: linkArray,
  engArray: welcomeEngArray,
  langArray: welcomeLangArray,
  logoArray: welcomeLogoArray,
  messageArray: welcomeMessageArray,
  tabNameWelcome,
  tabNameDictionary,
  loginLangTxt,
  firebaseConfig,
};

export default config;
export { linkArray, welcomeEngArray, welcomeLangArray, firebaseConfig };
