import "./index.scss";
import React from "react";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";
import NumberFilter from "@inovua/reactdatagrid-community/NumberFilter";
import StringFilter from "@inovua/reactdatagrid-community/StringFilter";
import { fetchImage } from "../../Components/nonUIFuncs";
import {
  Button,
  Checkbox,
  // Input,
} from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";

const boolSelectValues = [
  { id: true, label: "True" },
  { id: false, label: "False" },
];

const createOptionsArrayFromStrings = (arr) => {
  arr = arr.map((n, idx) => {
    return { key: idx, text: n, value: n };
  });
  return arr;
};

const fixColor = (c) => {
  return `rgba(${c.r},${c.g},${c.b},${c.a})`;
};

let defaultColumnProps = {
  // textEllipsis: false, // TODO: Text wrapping
  resizable: true,
  sortable: true,
  defaultWidth: 140,
  editable: true,
};

let fontSizeOptions = ["default", 6, 7, 8, 9, 10, 11, 12, 14, 18, 24, 36];
let borderWidthOptions = ["default", 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
fontSizeOptions = createOptionsArrayFromStrings(fontSizeOptions);
borderWidthOptions = createOptionsArrayFromStrings(borderWidthOptions);
let filterValue = [
  { name: "id", operator: "gte", type: "number" },
  { name: "Name", operator: "startsWith", type: "string" },
  { name: "Tab Name", operator: "startsWith", type: "string", value: "" },
  { name: "Product Type", operator: "eq", type: "string", value: "" },
  { name: "SKU", operator: "startsWith", type: "string", value: "" },
  { name: "EDU", operator: "eq", type: "boolean" },
  { name: "EDU SKU", operator: "startsWith", type: "string", value: "" },
  { name: "Travel", operator: "eq", type: "boolean" },
  { name: "Travel SKU", operator: "startsWith", type: "string", value: "" },
  { name: "QR-Key Start", operator: "startsWith", type: "string", value: "" },
  { name: "QR-Key End", operator: "startsWith", type: "string", value: "" },
  { name: "Home School Kit", operator: "eq", type: "boolean" },
  { name: "Head Start Kit", operator: "eq", type: "boolean" },
  { name: "Status", operator: "startsWith", type: "string", value: "" },
  {
    name: "Date Last Version Approved",
    operator: "startsWith",
    type: "string",
    value: "",
  },
  { name: "Notes", operator: "startsWith", type: "string", value: "" },
  { name: "isQRApp", operator: "eq", type: "boolean" },
  { name: "isKidsZone", operator: "eq", type: "boolean" },
  { name: "isDictionary", operator: "eq", type: "boolean" },
  { name: "Version", operator: "startsWith", type: "string", value: "" },
];
let gameDataFilterValue = [
  { name: "Key", operator: "gte", type: "number" },
  { name: "Folder", operator: "startsWith", type: "string", value: "" },
  { name: "App", operator: "gte", type: "number" },
  { name: "QRID", operator: "startsWith", type: "string", value: "" },
  { name: "ALTQRID", operator: "startsWith", type: "string", value: "" },
  { name: "Game", operator: "startsWith", type: "string", value: "" },
  { name: "Dictionary", operator: "startsWith", type: "string", value: "" },
  { name: "English", operator: "startsWith", type: "string", value: "" },
  { name: "Language", operator: "startsWith", type: "string", value: "" },
  {
    name: "Pronunciation_Guide",
    operator: "startsWith",
    type: "string",
    value: "",
  },
  {
    name: "Interlinear Gloss",
    operator: "startsWith",
    type: "string",
    value: "",
  },
  { name: "App_Audio", operator: "startsWith", type: "string", value: "" },
  { name: "Speaker", operator: "startsWith", type: "string", value: "" },
  { name: "Speaker_Type", operator: "startsWith", type: "string", value: "" },
  { name: "@image", operator: "startsWith", type: "string", value: "" },
  { name: "App_Art", operator: "startsWith", type: "string", value: "" },
  { name: "isQRApp", operator: "eq", type: "boolean" },
  { name: "isKidsZone", operator: "eq", type: "boolean" },
  { name: "isDictionary", operator: "eq", type: "boolean" },
];
const updateFilterValue = (obj) => {
  let foundValue = filterValue.find((fv) => fv.name === obj.name);
  if (foundValue === undefined) {
    filterValue.push(obj);
  }
};
const updateGameFilterValue = (obj) => {
  let foundValue = gameDataFilterValue.find((fv) => fv.name === obj.name);
  if (foundValue === undefined) {
    gameDataFilterValue.push(obj);
  }
};
let productColumnsTemplate = [
  { id: "Edit", name: "Edit", width: 95, filterable: false, editable: false },
  {
    id: "id",
    name: "id",
    defaultWidth: 100,
    maxWidth: 200,
    editable: false,
    filterEditor: NumberFilter,
    filterEditorProps: { placeholder: "All" },
  },
  {
    id: "Name",
    name: "Name",
    filterEditor: StringFilter,
    filterEditorProps: { placeholder: "All" },
  },
  { id: "Tab Name", name: "Tab Name", editable: false },
  {
    id: "Product Type",
    name: "Product Type",
    filterEditor: SelectFilter,
    filterEditorProps: { placeholder: "All" },
  },
  { id: "SKU", name: "SKU" },
  {
    id: "EDU",
    name: "EDU",
    type: "boolean",
    filterEditor: SelectFilter,
    filterEditorProps: { placeholder: "All", dataSource: boolSelectValues },
  },
  { id: "EDU SKU", name: "EDU SKU", editable: true },
  {
    id: "Travel",
    name: "Travel",
    type: "boolean",
    filterEditor: SelectFilter,
    filterEditorProps: { placeholder: "All", dataSource: boolSelectValues },
  },
  { id: "Travel SKU", name: "Travel SKU" },
  { id: "QR-Key Start", name: "QR-Key Start", defaultWidth: 100 },
  { id: "QR-Key End", name: "QR-Key End", defaultWidth: 100 },
  {
    id: "Home School Kit",
    name: "Home School Kit",
    type: "boolean",
    filterEditor: SelectFilter,
    filterEditorProps: { placeholder: "All", dataSource: boolSelectValues },
  },
  {
    id: "Head Start Kit",
    name: "Head Start Kit",
    type: "boolean",
    filterEditor: SelectFilter,
    filterEditorProps: { placeholder: "All", dataSource: boolSelectValues },
  },
  {
    id: "Status",
    name: "Status",
    filterEditor: SelectFilter,
    filterEditorProps: { placeholder: "All" },
  }, // List Selection
  { id: "Date Last Version Approved", name: "Date Last Version Approved" }, // Date Picker
  { id: "Notes", name: "Notes" },
  {
    id: "isQRApp",
    name: "isQRApp",
    type: "boolean",
    filterEditor: SelectFilter,
    filterEditorProps: { placeholder: "All", dataSource: boolSelectValues },
  },
  {
    id: "isKidsZone",
    name: "isKidsZone",
    type: "boolean",
    filterEditor: SelectFilter,
    filterEditorProps: { placeholder: "All", dataSource: boolSelectValues },
  },
  {
    id: "isDictionary",
    name: "isDictionary",
    type: "boolean",
    filterEditor: SelectFilter,
    filterEditorProps: { placeholder: "All", dataSource: boolSelectValues },
  },
  {
    id: "Version",
    name: "Version",
    editable: false,
    filterable: false,
    visible: false,
  },
  {
    id: "collectionName",
    name: "collectionName",
    editable: false,
    filterable: false,
    visible: false,
  },
  {
    id: "firebaseID",
    name: "firebaseID",
    editable: false,
    filterable: false,
    visible: false,
  },
];
let defaultGameDataProps = {
  resizable: true,
  sortable: true,
  defaultWidth: 140,
  editable: true,
};

let gameDataColumnsTemplate = [
  {
    id: "id",
    name: "id",
    defaultWidth: 100,
    defaultVisible: false,
    defaultLocked: true,
    maxWidth: 200,
    editable: false,
    filterEditor: NumberFilter,
    filterEditorProps: { placeholder: "All" },
    filterable: false,
  },
  {
    id: "Key",
    name: "Key",
    defaultWidth: 100,
    maxWidth: 200,
    editable: false,
    filterEditor: NumberFilter,
    filterEditorProps: { placeholder: "All" },
  },
  {
    id: "Folder",
    name: "Folder",
    defaultWidth: 100,
    editable: false,
    filterEditor: StringFilter,
    filterEditorProps: { placeholder: "All" },
  },
  {
    id: "App",
    name: "App",
    filterEditor: NumberFilter,
    filterEditorProps: { placeholder: "All" },
    defaultWidth: 80,
  },
  {
    id: "QRID",
    name: "QRID",
    defaultWidth: 100,
    filterEditor: StringFilter,
    filterEditorProps: { placeholder: "All" },
  },
  {
    id: "ALTQRID",
    name: "ALTQRID",
    defaultWidth: 100,
    filterEditor: StringFilter,
    filterEditorProps: { placeholder: "All" },
    filterable: false,
  },
  {
    id: "Game",
    name: "Game",
    filterEditor: StringFilter,
    filterEditorProps: { placeholder: "All" },
  },
  {
    id: "Dictionary",
    name: "Dictionary",
    filterEditor: StringFilter,
    filterEditorProps: { placeholder: "All" },
  },
  {
    id: "English",
    name: "English",
    filterEditor: StringFilter,
    filterEditorProps: { placeholder: "All" },
  },
  {
    id: "Language",
    name: "Language",
    filterEditor: StringFilter,
    filterEditorProps: { placeholder: "All" },
  },
  {
    id: "Pronunciation_Guide",
    name: "Pronunciation_Guide",
    filterEditor: StringFilter,
    filterEditorProps: { placeholder: "All" },
  },
  {
    id: "Interlinear Gloss",
    name: "Interlinear Gloss",
    filterEditor: StringFilter,
    filterEditorProps: { placeholder: "All" },
  },
  {
    id: "App_Audio",
    name: "App_Audio",
    filterEditor: StringFilter,
    filterEditorProps: { placeholder: "All" },
  },
  {
    id: "Speaker",
    name: "Speaker",
    filterEditor: StringFilter,
    filterEditorProps: { placeholder: "All" },
  },
  {
    id: "Speaker_Type",
    name: "Speaker_Type",
    filterEditor: StringFilter,
    filterEditorProps: { placeholder: "All" },
  },
  {
    id: "@image",
    name: "@image",
    filterEditor: StringFilter,
    filterEditorProps: { placeholder: "All" },
  },
  {
    id: "App_Art",
    name: "App_Art",
    filterEditor: StringFilter,
    filterEditorProps: { placeholder: "All" },
    defaultWidth: 55,
  },
  {
    id: "isQRApp",
    name: "isQRApp",
    type: "boolean",
    filterEditor: SelectFilter,
    filterEditorProps: { placeholder: "All", dataSource: boolSelectValues },
  },
  {
    id: "isKidsZone",
    name: "isKidsZone",
    type: "boolean",
    filterEditor: SelectFilter,
    filterEditorProps: { placeholder: "All", dataSource: boolSelectValues },
  },
  {
    id: "isDictionary",
    name: "isDictionary",
    type: "boolean",
    filterEditor: SelectFilter,
    filterEditorProps: { placeholder: "All", dataSource: boolSelectValues },
    defaultWidth: 150,
  },
  {
    id: "Notes",
    name: "Notes",
    type: "string",
  },
  {
    id: "collectionName",
    name: "collectionName",
    editable: false,
    filterable: false,
    visible: false,
  },
  {
    id: "firebaseID",
    name: "firebaseID",
    editable: false,
    filterable: false,
    visible: false,
  },
];

let editButtonCellStyle = { margin: 0, padding: "0 2px" };
let productTypeArray = [];
let statusArray = [];
const getHeader = (column, updateCells) => {
  return (cellProps) => {
    const title = column.charAt(0).toUpperCase() + column.slice(1);
    return (
      <div className="dataGridConfig-getHeader-container">
        <Checkbox
          className="dataGridConfig-getHeader-checkbox"
          checked={cellProps.headerProps.selectColumn === true}
          onClick={(e) => {
            e.stopPropagation();
            updateCells("column", column);
          }}
        />
        <div>{title}</div>
      </div>
    );
  };
};
const addEditButton = (edit, update, data) => {
  return (
    <Button
      size="mini"
      compact
      color="blue"
      onClick={() => {
        edit(data, "edit");
        update(data);
      }}
      fluid>
      Edit
    </Button>
  );
};
// const addLinkButton = (edit, data) => {
//   return (
//     <Button
//       style={{paddingLeft: 0, paddingRight: 0}}
//       size="mini"
//       compact
//       color="red"
//       onClick={() => {
//         edit(data, 'link');
//       }}
//       fluid>
//       Link Collection
//     </Button>
//   );
// };

const findCellStyle = (cellProps, userPrefs) => {
  let style = { ...cellProps.style };
  // console.log('style', style);
  // console.log("data", cellProps.data.style[cellProps.id]);
  if (cellProps.data.style && cellProps.data.style[cellProps.id]) {
    style = { ...style, ...cellProps.data.style[cellProps.id] };
  }
  try {
    let userStyle =
      userPrefs.style[cellProps.data.collectionName][cellProps.data.firebaseID][
        cellProps.name
      ];
    style = { ...userStyle, ...style };
    // console.log("SUCCESS");
  } catch (e) {
    // console.log('NO USER STYLE FOUND');
  }
  if (cellProps.id === "Edit") {
    style = { ...style, ...editButtonCellStyle };
  }
  return { style: style };
};

const setInitialProductStatusCells = (
  fireStoreData,
  newRows,
  editCollection,
  setColumns,
  setRows,
  updateSelectedCells,
  selectColumn,
  updateCollections,
  setLoading
  // userPrefs,
) => {
  let newColumns = [];
  let columnKeys = [];
  let keys = Object.keys(fireStoreData.productList[0].data);
  productColumnsTemplate.map((datum) =>
    keys.includes(datum.id) || datum.id === "Edit" || datum.id === "id"
      ? newColumns.push(datum) && columnKeys.push(datum.id)
      : null
  );
  keys.map((key) =>
    !columnKeys.includes(key)
      ? columnKeys.push(key) &&
        newColumns.push({ id: key, name: key, editable: true })
      : null
  );
  try {
    let orderedKeys =
      fireStoreData.userPrefs.columnPrefs["Product List & Status"].columnOrder;
    const findOrderedKeys = (columns) => {
      let columnsToReorder = [...columns];
      columnsToReorder = orderedKeys.reduce((arr, key) => {
        let found = columnsToReorder.find((c) => c.name === key);
        if (found !== undefined) {
          arr.push(found);
        } else {
          arr.push({ id: key, name: key });
        }
        return arr;
      }, []);
      return columnsToReorder;
    };
    newColumns = findOrderedKeys(newColumns);
  } catch (e) {
    // console.log(
    //   `User Preference 'Column Order' Not Found: This likely means this is a new
    //   user or sheet where order has not been established`,
    //   e,
    // );
  }
  newColumns = newColumns.map((datum) => {
    let newDatum = {
      ...defaultColumnProps,
      ...datum,
      header: getHeader(datum.name, updateSelectedCells),
      headerProps: {
        selectColumn: selectColumn[datum.name],
        style: { position: "relative" },
      },
    };
    if (newDatum.type === "boolean") {
      newDatum = {
        ...newDatum,
        defaultWidth: datum.defaultWidth ? newDatum.defaultWidth : 100,
        rendersInlineEditor: true,
        render: ({ value }, { cellProps }) => {
          let v = cellProps.editProps.inEdit
            ? cellProps.editProps.value
            : value;
          return (
            <div
              key={`${cellProps.id}${cellProps.data.id}`}
              className="dataGridConfig-booleanCheckbox-container"
            >
              <Checkbox
                onClick={() => cellProps.editProps.onComplete()}
                autoFocus={cellProps.inEdit}
                checked={v}
              />
            </div>
          );
        },
      };
    }
    newDatum = {
      ...newDatum,
      // The styling need to be thoroughly reworked
      // use cellDOMProps to get the initial style in place.
      // cellDOMProps: {style: {color: 'green'}},
      cellDOMProps: (cellProps) =>
        findCellStyle(cellProps, fireStoreData.userPrefs),
      // use onRender for updating styles, don't focus on the initial render.
    };
    return newDatum;
  });

  let keysAgain = [];
  newColumns = newColumns.reduce((filtered, option) => {
    if (!keysAgain.includes(option.id)) {
      filtered.push(option);
      keysAgain.push(option.id);
    }
    return filtered;
  }, []);

  newRows = fireStoreData.productList.map((datum) => {
    return datum.data;
  });
  newRows = newRows.sort((a, b) => {
    let nameA = a.Name;
    let nameB = b.Name;
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
  let i = 0;
  let productTypes = [];
  let statuses = [];
  newRows = newRows.filter((nr) => nr["Product Type"] !== "Digital");
  newRows = newRows.map((datum) => {
    let newDatum = { ...datum };
    let productType = newDatum["Product Type"];
    let status = newDatum.Status;
    if (newDatum["Product Type"].length === 0) {
      productType = "NO PRODUCT TYPE";
      newDatum["Product Type"] = productType;
      if (!productTypes.includes("NO PRODUCT TYPE")) {
        productTypes.push(productType);
      }
    } else if (!productTypes.includes(newDatum["Product Type"])) {
      productType = newDatum["Product Type"];
      productTypes.push(productType);
      newDatum["Product Type"] = productType;
    }
    if (newDatum.Status.length === 0) {
      status = "NO STATUS";
      newDatum.Status = status;
      if (!statuses.includes("NO STATUS")) {
        statuses.push(status);
      }
    } else if (!statuses.includes(newDatum.Status)) {
      status = newDatum.Status;
      statuses.push(status);
      newDatum.Status = status;
    }
    const rowKeys = Object.keys(newDatum);
    let findBooleans = rowKeys.reduce((newObj, key) => {
      let col = newColumns.find((d) => d.name === key);
      if (col && col.type === "boolean") {
        newObj[key] = newDatum[key] === true ? true : false;
      }
      return newObj;
    }, {});
    i++;
    // let styleObj = {};
    let styleObj = columnKeys.reduce((obj, col) => {
      obj[col] = {
        display: "flex",
        overflow: "visible",
        overflowWrap: "break-word", // TODO: Text wrapping
      };
      return obj;
    }, {});
    let date = new Date(newDatum["Date Last Version Approved"]);
    if (newDatum["Tab Name"].length > 0) {
      newDatum["Edit"] = addEditButton(
        editCollection,
        updateCollections,
        newDatum
      );
    }
    // else {
    //   newDatum["Edit"] = addLinkButton(
    //     editCollection,
    //     newDatum,
    //   );
    // }
    return {
      id: i,
      style: styleObj,
      ...newDatum,
      ...findBooleans,
      "Date Last Version Approved":
        newDatum["Date Last Version Approved"].length > 0
          ? date.toLocaleDateString()
          : "",
    };
  });

  if (productTypeArray.length === 0 && productTypes.length > 0) {
    productTypeArray = productTypes.map((type) => {
      let d = { id: type, label: type };
      if (type.length === 0) {
        d.label = "No Product Type";
      }
      return d;
    });
  }
  if (statusArray.length === 0 && statuses.length > 0) {
    statusArray = statuses.map((type) => {
      let d = { id: type, label: type };
      if (type.length === 0) {
        d.label = "No Status";
      }
      return d;
    });
  }
  newColumns = newColumns.map((col) => {
    let newCol = { ...col };
    if (newCol.id === "Product Type" || newCol.id === "Status") {
      newCol = {
        ...newCol,
        filterEditorProps: {
          ...newCol.filterEditorProps,
          dataSource:
            newCol.id === "Product Type" ? productTypeArray : statusArray,
        },
      };
    }
    return newCol;
  });
  let noName = newRows.filter((datum) => datum.Name.length === 0);
  newRows = newRows.filter((datum) => datum.Name.length > 0);
  newRows = [...newRows, ...noName];
  let rowKeysAgain = [];
  newRows = newRows.reduce((filtered, option) => {
    if (!rowKeysAgain.includes(option.firebaseID)) {
      filtered.push(option);
      rowKeysAgain.push(option.firebaseID);
    }
    return filtered;
  }, []);
  newRows = newRows.map((r) => {
    let row = { ...r };
    let keys = Object.keys(row);
    keys.forEach((k) => {
      let column = newColumns.find((c) => c.id === k);
      if (typeof row[k] === "number" && column.filterEditor !== NumberFilter) {
        row[k] = row[k].toString();
      }
    });
    return row;
  });
  try {
    let productListPrefs =
      fireStoreData.userPrefs.columnPrefs["Product List & Status"];
    newColumns = newColumns.map((col) => {
      let columnWithPrefs = { ...col };
      let userPref = productListPrefs[columnWithPrefs.id];
      if (userPref !== undefined) {
        columnWithPrefs = { ...columnWithPrefs, ...userPref };
      }
      return columnWithPrefs;
    });
  } catch (e) {
    // console.log("User Preference 'Column Prefs' Not Found", e);
  }
  newColumns = newColumns.map((col) => {
    let addColumnFilter = { ...col };
    if (
      col.filterEditor === undefined &&
      col.filterEditorProps?.stringvalue !== undefined
    ) {
      updateFilterValue(col.filterEditorProps.filterValueObj);
      let specifiedEditor = StringFilter;
      if (col.filterEditorProps.stringvalue === "NumberFilter") {
        specifiedEditor = NumberFilter;
      } else if (col.filterEditorProps.stringvalue === "SelectFilter") {
        specifiedEditor = SelectFilter;
      }
      addColumnFilter.filterEditor = specifiedEditor;
    }
    return addColumnFilter;
  });
  setColumns(newColumns);
  setRows(newRows);
  setLoading(false);
};

const setInitialGameDataCells = (
  fireStoreData,
  collectionName,
  collectionData,
  initialState,
  setGameDataState,
  updateSelectedCells,
  showMediaUpload,
  setMediaUploadInfo
) => {
  let newRows = [...initialState.rows];
  let newColumns = [...initialState.columns];
  let selectedCells = { ...initialState.selectedCells };
  let columnKeys = [];
  let keys = Object.keys(collectionData[0]);
  gameDataColumnsTemplate.map((datum) =>
    keys.includes(datum.id) || datum.id === "id"
      ? newColumns.push(datum) && columnKeys.push(datum.id)
      : null
  );
  keys.map((key) =>
    !columnKeys.includes(key)
      ? columnKeys.push(key) &&
        newColumns.push({ id: key, name: key, editable: true })
      : null
  );
  try {
    let orderedKeys =
      fireStoreData.userPrefs.columnPrefs[collectionName].columnOrder;
    const findOrderedKeys = (columns) => {
      let columnsToReorder = [...columns];
      columnsToReorder = orderedKeys.reduce((arr, key) => {
        let found = columnsToReorder.find((c) => c.name === key);
        if (found !== undefined) {
          arr.push(found);
        } else {
          arr.push({ id: key, name: key });
        }
        return arr;
      }, []);
      return columnsToReorder;
    };
    newColumns = findOrderedKeys(newColumns);
  } catch (e) {
    // console.log("User Preference 'Column Order' Not Found", e);
  }

  newColumns = newColumns.map((datum) => {
    let newDatum = {
      ...defaultGameDataProps,
      ...datum,
      header: getHeader(datum.name, updateSelectedCells),
      headerProps: {
        selectColumn: initialState.checkedColumns[datum.name],
        style: { position: "relative" },
      },
    };
    if (newDatum.type === "boolean") {
      newDatum = {
        ...newDatum,
        defaultWidth: datum.defaultWidth ? newDatum.defaultWidth : 100,
        rendersInlineEditor: true,
        render: ({ value }, { cellProps }) => {
          let v = cellProps.editProps.inEdit
            ? cellProps.editProps.value
            : value;
          return (
            <div
              key={`${cellProps.id}${cellProps.data.id}`}
              className="dataGridConfig-booleanCheckbox-container"
            >
              <Checkbox
                onClick={() => cellProps.editProps.onComplete()}
                autoFocus={cellProps.inEdit}
                checked={v}
              />
            </div>
          );
        },
      };
    } else if (newDatum.id === "App_Art" || newDatum.id === "@image") {
      newDatum.render = ({ value }, { cellProps }) => {
        if (value.length === 0) {
          return (
            <div
              onClick={() => {
                setMediaUploadInfo({ cellProps: cellProps, value: value });
                showMediaUpload(true);
              }}
              style={{ width: "100%", height: "100%" }}
            />
          );
        }
        return (
          <img
            onClick={() => {
              setMediaUploadInfo({ cellProps: cellProps, value: value });
              showMediaUpload(true);
            }}
            alt=""
            style={{ width: "100%", height: 45, objectFit: "contain" }}
            src={fetchImage(value)}
          />
        );
      };
    }
    newDatum = {
      ...newDatum,
      // onRender: (cellProps, { data }) => {
      //   cellProps.style = findCellStyle(cellProps, data, fireStoreData.userPrefs);
      // },
      cellDOMProps: (cellProps) =>
        findCellStyle(cellProps, fireStoreData.userPrefs),
    };
    return newDatum;
  });

  let keysAgain = [];
  newColumns = newColumns.reduce((filtered, option) => {
    if (!keysAgain.includes(option.id)) {
      filtered.push(option);
      keysAgain.push(option.id);
    }
    return filtered;
  }, []);

  newRows = [...collectionData];
  newRows = newRows.map((row) => {
    return { ...row, collectionName: collectionName };
  });
  console.log(newRows);
  newRows = newRows.sort((a, b) => {
    let nameA = Number(a.Key);
    let nameB = Number(b.Key);
    // console.log('Sorting: ', nameA, nameB);
    if (isNaN(nameA)) console.log("A Key", a);
    if (isNaN(nameB)) console.log("B Key", b);
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
  let fourhundos;
  const fixSorted = (rows) => {
    let fixedRows = [...rows];
    let threehundos = fixedRows.filter(
      (r) => Number(r.Key) >= 300 && Number(r.Key) < 400
    );
    fourhundos = fixedRows.filter(
      (r) => Number(r.Key) >= 400 && Number(r.Key) < 500
    );
    let fivehundos = fixedRows.filter(
      (r) => Number(r.Key) >= 500 && Number(r.Key) < 600
    );
    let sixhundos = fixedRows.filter(
      (r) => Number(r.Key) >= 600 && Number(r.Key) < 700
    );
    let emptyRows = fixedRows.filter((r) => r.Key.toString().length === 0);
    fixedRows = fixedRows.filter(
      (r) =>
        (Number(r.Key) < 300 || Number(r.Key >= 700)) &&
        r.Key.toString().length > 0
    );
    // return [...fourhundos, ...fixedRows, ...sixhundos, ...fivehundos, ...threehundos]; // Includes 400s
    return [
      ...fixedRows,
      ...sixhundos,
      ...fivehundos,
      ...threehundos,
      ...emptyRows,
    ];
  };
  newRows = fixSorted(newRows);
  let i = 0;
  newRows = newRows.map((collection) => {
    i++;
    return { ...collection, id: i };
  });
  newRows = newRows.map((datum) => {
    let newDatum = { ...datum };
    const rowKeys = Object.keys(newDatum);
    let findBooleans = rowKeys.reduce((newObj, key) => {
      let col = newColumns.find((d) => d.name === key);
      if (col && col.type === "boolean") {
        newObj[key] = newDatum[key] === true ? true : false;
      }
      return newObj;
    }, {});
    let styleObj = columnKeys.reduce((obj, col) => {
      obj[col] = {
        display: "flex",
        overflow: "visible",
        overflowWrap: "break-word",
      };
      return obj;
    }, {});
    return {
      style: styleObj,
      ...newDatum,
      ...findBooleans,
    };
  });
  let rowKeysAgain = [];
  newRows = newRows.reduce((filtered, option) => {
    if (!rowKeysAgain.includes(option.firebaseID)) {
      filtered.push(option);
      rowKeysAgain.push(option.firebaseID);
    }
    return filtered;
  }, []);
  newRows = newRows.map((r) => {
    let row = { ...r };
    let keys = Object.keys(row);
    keys = keys.filter((k) => k !== "style");
    keys.forEach((k) => {
      let column = newColumns.find((c) => c.id === k);
      if (typeof row[k] === "number" && column.filterEditor !== NumberFilter) {
        row[k] = row[k].toString();
      }
    });
    return row;
  });
  try {
    let gameCollectionPrefs =
      fireStoreData.userPrefs.columnPrefs[collectionName];
    newColumns = newColumns.map((col) => {
      let columnWithPrefs = { ...col };
      let userPref = gameCollectionPrefs[columnWithPrefs.id];
      if (userPref !== undefined) {
        columnWithPrefs = { ...columnWithPrefs, ...userPref };
      }
      return columnWithPrefs;
    });
  } catch (e) {
    // console.log("User Preference 'Column Prefs' Not Found", e);
  }
  newColumns = newColumns.map((col) => {
    let addColumnFilter = { ...col };
    if (
      col.filterable &&
      col.filterEditor === undefined &&
      col.filterEditorProps.stringvalue !== undefined
    ) {
      updateGameFilterValue(col.filterEditorProps.filterValueObj);
      let specifiedEditor = StringFilter;
      if (col.filterEditorProps.stringvalue === "NumberFilter") {
        specifiedEditor = NumberFilter;
      } else if (col.filterEditorProps.stringvalue === "SelectFilter") {
        specifiedEditor = SelectFilter;
      }
      addColumnFilter.filterEditor = specifiedEditor;
    }
    return addColumnFilter;
  });
  setGameDataState(collectionName, {
    headerData: fourhundos,
    selectedCells: selectedCells,
    columns: newColumns,
    rows: newRows,
    loading: false,
  });
};

const productStatusList = {
  filterValue: filterValue,
  updateFilterValue: updateFilterValue,
  columnsTemplate: productColumnsTemplate,
  setInitialData: setInitialProductStatusCells,
  addEditButton: addEditButton,
  // addLinkButton: addLinkButton,
};
const gameDataList = {
  filterValue: gameDataFilterValue,
  updateFilterValue: updateGameFilterValue,
  columnsTemplate: gameDataColumnsTemplate,
  setInitialData: setInitialGameDataCells,
  addEditButton: addEditButton,
  // addLinkButton: addLinkButton,
};

// TODO: Seperate out what is specifically for ProductList, GameData, and can be used for both.
export {
  gameDataList,
  defaultColumnProps,
  defaultGameDataProps,
  // createOptionsArrayFromStrings,
  borderWidthOptions,
  fontSizeOptions,
  productStatusList,
  fixColor,
  getHeader,
  findCellStyle,
  boolSelectValues,
};
