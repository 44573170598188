import React, { useState, useEffect, useRef } from "react";

import config from "../../configData";
import {
  Form,
  Button,
  Input,
  Dropdown,
  Checkbox,
  Icon,
  Image,
  Popup,
} from "semantic-ui-react";
import InputControlled from "../InputControlled";
import { useSelector, useDispatch } from "react-redux";
import { selectFirestore, getOptions, setTags } from "../../Store/firestore";
import SimpleMediaPreview from "../Admin/SimpleMediaPreview";
import { formatOptionsForDropdown } from "../nonUIFuncs";
// import TextEditor from "../TextEditor";
import useOnScreen from "../../Hooks/useOnScreen";
import SalishWordForm from "./Salish/WordForm";

const { NativeLanguage: nativeLanguage } = config();

const WordCollection = ({ data }) => {
  // set record to active data from database will override making this a default
  const [formData, setformData] = useState({
    recordActive: true,
    ...data.data,
  });
  const [dataSaved, setdataSaved] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [rootDD, setrootDD] = useState(
    formatOptionsForDropdown([data.data.Root])
  );
  const alwaysOnScreenHeader = useRef(null);
  const isVisible = useOnScreen(alwaysOnScreenHeader);
  const fireStoreData = useSelector(selectFirestore);
  const Dispatch = useDispatch();


  // let speaker; 
  useEffect(() => {
    let fieldsToPopulate = ["Category", "SUB.Plurality", "SUB.Person", "Grammatical_Category", "Subcategory", "Grammatical_Subclass", "Root.Type"];
    fieldsToPopulate.forEach((field) => {
      Dispatch(getOptions({ myCollection: "Words", field: field }));
    });

    if (typeof formData.speakers !== "object") {
      // ==> if object doesn't exist then use old data from spreadsheet entry and create objecct <== //
      let speakerObj = [
        { gender: "male", level: 1, audio: formData["ML1 Speaker Audio_File"], person: formData["ML1 Speaker"] },
        { gender: "male", level: 2, audio: formData["ML2 Speaker Audio_File"], person: formData["ML2 Speaker"] },
        { gender: "female", level: 1, audio: formData["FL1 Speaker Audio_File"], person: formData["FL1 Speaker"] },
        { gender: "female", level: 2, audio: formData["FL2 Speaker Audio_File"], person: formData["FL2 Speaker"] },
      ];
      setformData({
        ...formData,
        speakers: speakerObj
      });
    }


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let genderOptions = formatOptionsForDropdown(["Male", "Female"]);

  let WordsSubcategory = [];
  if (fireStoreData?.options?.WordsSubcategory) {
    WordsSubcategory = formatOptionsForDropdown(
      fireStoreData.options.WordsSubcategory.options
    );
  }

  let WordsGrammaticalCategory = [];
  if (fireStoreData?.options?.WordsGrammaticalCategory) {
    WordsGrammaticalCategory = formatOptionsForDropdown(
      fireStoreData.options.WordsGrammaticalCategory.options
    );
  }

  let WordsGrammaticalSubclass = [];
  if (fireStoreData?.options?.WordsGrammaticalSubclass) {
    WordsGrammaticalSubclass = formatOptionsForDropdown(
      fireStoreData.options.WordsGrammaticalSubclass.options
    );
  }

  let WordsCategory = [];
  if (fireStoreData?.options?.WordsCategory) {
    WordsCategory = formatOptionsForDropdown(
      fireStoreData.options.WordsCategory.options
    );
  }

  let WordsSUBPlurality = [];
  if (fireStoreData?.options?.WordsSUBPlurality) {
    WordsSUBPlurality = formatOptionsForDropdown(
      fireStoreData.options.WordsSUBPlurality.options
    );
  }

  let WordsSUBPerson = [];
  if (fireStoreData?.options?.WordsSUBPerson) {
    WordsSUBPerson = formatOptionsForDropdown(
      fireStoreData.options.WordsSUBPerson.options
    );
  }

  let tagOptionsDD = [];
  if (fireStoreData?.options?.tags) {
    tagOptionsDD = formatOptionsForDropdown(fireStoreData.options.tags);
  }

  let WordsRootTypeOptionsDD = [];
  if (fireStoreData?.options?.WordsRootType) {
    WordsRootTypeOptionsDD = formatOptionsForDropdown(fireStoreData.options.WordsRootType.options);
  }

  const buttonSaveBarDOM = () => {
    if (dataSaved === null) {
      return (
        <Button
          style={{ marginRight: 0 }}
          color="green"
          // HACK
          onClick={() => setdataSaved(false)}>
          Unchanged
        </Button>
      );
    } else if (dataSaved) {
      return (
        <Button
          style={{ marginRight: 0 }}
          color="green"
          onClick={() => setdataSaved(false)}>
          <Icon name="checkmark" />
          Saved
        </Button>
      );
    } else {
      return (
        <Popup //HACK: only for demo
          content='in DEMO MODE, no data is saved'
          trigger={
            <Button
              style={{ marginRight: 0 }}
              color="blue"
              onClick={() => {
                //TODO: save the data for real
                console.log("Data to be saved: ", formData);
                setdataSaved(true)
              }}>
              <Icon name="save" />
              Save
            </Button>
          }
        />
      );
    }
  };

  const handleAddItemTagOption = (_e, { value }) => {
    console.log("===> inside handleAddItemOption ", value);
    Dispatch(setTags({ tag: value }));
  };

  const handleAddItemRootTypeOption = (_e, { value }) => {
    console.log("===> inside handleAddItemRootTypeOption ", value);
    //TODO: add root type to the database options
    // Dispatch(setTags({ tag: value }));
  };

  const speakerFormDOM = ({ speakersObj }) => {
    // this outputs DOM of all spearker objects 
    const sDOMA = speakersObj.map((spkObj, idx) => {
      return (
        <Form.Group key={idx}>
          <Form.Field>
            <label>Speaker {spkObj.level}</label>
            <InputControlled
              placeholder={spkObj.person || "unknown"}
              showSpecialCharsEntry
            />
          </Form.Field>
          <Form.Field>
            <label>Gender</label>
            <Dropdown placeholder={spkObj.gender} minCharacters={4} selection options={genderOptions}></Dropdown>
          </Form.Field>
          <Form.Field>
            <label>Audio File</label>
            <SimpleMediaPreview mediaObj={spkObj.audio} mediaType="audio" callbackFunc={fileuploadCB} idx={idx} />
          </Form.Field>
        </Form.Group>
      )
    });
    return (sDOMA);
  }

  const fileuploadCB = ({ filestatus, msg, done }) => {
    if (done) {
      console.log("file upload compleate");
    }
    console.log("i got this from the file upload: ", msg);
  }

  return (

    <div className="dictionaryCollection-main">

      {/* //TODO: this button should not scroll off screen */}
      <div ref={alwaysOnScreenHeader} className="dictionaryCollection-Header">
        {isVisible && buttonSaveBarDOM()}
      </div>
      {!isVisible && (
        <div className="dictionaryCollection-Header-keepOnScreen">
          {buttonSaveBarDOM()}
        </div>
      )}
      <h1>New Record</h1>
      <SalishWordForm
        formData={formData}
        setdataSaved={setdataSaved}
        setformData={setformData}
        nativeLanguage={nativeLanguage}
        SimpleMediaPreview={SimpleMediaPreview}
        WordsGrammaticalCategory={WordsGrammaticalCategory}
        WordsGrammaticalSubclass={WordsGrammaticalSubclass}
        tagOptionsDD={tagOptionsDD}
        handleAddItemTagOption={handleAddItemTagOption}
        rootDD={rootDD}
        WordsRootTypeOptionsDD={WordsRootTypeOptionsDD}
        handleAddItemRootTypeOption={handleAddItemRootTypeOption}
        WordsCategory={WordsCategory}
        WordsSUBPlurality={WordsSUBPlurality}
        WordsSUBPerson={WordsSUBPerson}
        WordsSubcategory={WordsSubcategory}
        genderOptions={genderOptions}
        speakerFormDOM={speakerFormDOM}
      />
    </div>
  );
};

export default WordCollection;
