import React, { useEffect, useState } from "react";
import config from "../../configData";
import { Button, Icon } from "semantic-ui-react";

const baseDir = config().baseDir;

const AudioDisplay = (props) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(null);
  const [audio, setAudio] = useState(null);

  const onplayFunc = () => {
    console.log("playing");
    setIsPlaying(true);
  };
  const onCanPlay = (e) => {
    setDuration(e.currentTarget.duration);
  };
  const onEnded = () => {
    setIsPlaying(false);
  };

  useEffect(() => {
    const audioEl = new Audio(`${baseDir}/Audio/${props.obj.data.audio}`);
    audioEl.addEventListener("ended", onEnded);
    audioEl.addEventListener("canplay", onCanPlay);
    setAudio(audioEl);

    return () => {
      audioEl.removeEventListener("ended", onplayFunc);
      audioEl.removeEventListener("canplay", onCanPlay);
    };
  }, [props.obj.data.audio]);

  return (
    <div className={`centerme`}>
      <Button
        className="icon"
        circular
        onClick={(e) => {
          audio.play();
          setIsPlaying(true);
        }}
      >
        {isPlaying ? (
          <Icon.Group size="big">
            <Icon fitted name="circle notched" loading size="big" />
            <Icon fitted name={(duration <= 0) ? "exclamation" : "play"} size="small" />
          </Icon.Group>
        ) : (
          <Icon.Group size="huge">
            <Icon fitted name={(duration <= 0) ? "exclamation circle" : "play circle outline"}/>
          </Icon.Group>
        )}
      </Button>
      <div className="audio-text">Length: {Math.ceil(duration)} Seconds</div>
    </div>
  );
};

export default AudioDisplay;
