import { createSlice } from "@reduxjs/toolkit";
import { getAuth, signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { getFirestore, doc, getDoc } from "@firebase/firestore";

const db = getFirestore(window.app);

export const isLoggedIn = createSlice({
  name: "isLoggedIn",
  initialState: {
    value: { isLoggedIn: false },
  },
  reducers: {
    logIn: (state) => {
      state.value = { ...state.value, isLoggedIn: true };
    },
    logOut: (state) => {
      state.value = { ...state.value, isLoggedIn: false };
    },
    userLoginR: (state, action) => {
      state.value = { ...state.value, currentUserData: action.payload };
    },
    userLoginErrorR: (state, action) => {
      state.value = { ...state.value, userLoginError: action.payload };
    },
  },
});

//TODO: search the database for more user info

export const userLoginGoogle = () => 
  (dispatch) => {
    console.log("userLoginGoogle function from store")
    const providerGoogle = new GoogleAuthProvider();
    providerGoogle.addScope('https://www.googleapis.com/auth/userinfo.email');
    const auth = getAuth();
    auth.useDeviceLanguage(); // optional to not expressly setting laguage.
    providerGoogle.setCustomParameters({
      'login_hint': 'for NTA',
      'hd' : 'nativeteachingaids.com',
    });

    // recomended sign in for web/non-mobile.
    signInWithPopup(auth, providerGoogle)
      .then( async result => {
        console.log("result: ", result);
        const token = GoogleAuthProvider.credentialFromResult(result);
        const user = result.user;
        const userPrefDocRef = doc(db, "adminUsers", user.uid);
        const userPrefDocSnap = await getDoc(userPrefDocRef);
        const metadata = {
          createdAt:  user.metadata.createdAt,
          creationTime: user.metadata.creationTime,
          lastLoginAt: user.metadata.lastLoginAt,
          lastSignInTime:  user.metadata.lastSignInTime,
        };
        dispatch(userLoginErrorR());
        dispatch(logIn());
        dispatch(
          userLoginR({
            user: user.email,
            displayName: user.displayName,
            photoURL: user.photoURL,
            canAdminUsers: userPrefDocSnap.data().isSuperAdmin || false,
            uid: user.uid,
            metadata: metadata,
            idTokenResult: token,
            fullResults: result || {},
            claims: {},
            usrPrefs: userPrefDocSnap.data(),
          })
        );
    }).catch( err => {
      const cred = GoogleAuthProvider.credentialFromError(err);
      console.log("error: ", err);
      console.log("cred from error: ", cred);
    });

  };

export const userLogin =
  ({ email, password }) =>
  (dispatch) => {
    const auth = getAuth();
    console.log(`user: ${email} pass: ${password}`);
    signInWithEmailAndPassword(auth, email, password)
      .then((userCred) => {
        // console.log("userCred: ", userCred);
        return userCred.user.getIdTokenResult().then((idTokenResult) => {
          // console.log("token Results: ", idTokenResult.claims);
          return { userCred, idTokenResult, claims: idTokenResult.claims };
        });
      })
      .then(async ({ userCred, idTokenResult, claims }) => {
        // get user prefs from database
        const userPrefDocRef = doc(db, "adminUsers", userCred.user.uid);
        const userPrefDocSnap = await getDoc(userPrefDocRef);
        // ========================= //
        const user = userCred.user.email;
        const metadata = {
          createdAt:  userCred.user.metadata.createdAt,
          creationTime: userCred.user.metadata.creationTime,
          lastLoginAt: userCred.user.metadata.lastLoginAt,
          lastSignInTime:  userCred.user.metadata.lastSignInTime,
        };
        dispatch(userLoginErrorR());
        dispatch(logIn());
        let canAdminUsers = claims.isSuperAdmin || false; //sets to false if it doesn't exist
        // if (user === "admin@user.com") {
        //   canAdminUsers = true;
        // }
        dispatch(
          userLoginR({
            user,
            canAdminUsers,
            uid: userCred.user.uid,
            metadata: metadata,
            idTokenResult,
            claims,
            usrPrefs: userPrefDocSnap.data(),
          })
        );
      })
      .catch((err) => {
        console.log("User login Error", err);
        dispatch(userLoginErrorR("user error"));
      });
  };

export const { logIn, logOut, userLoginR, userLoginErrorR } =
  isLoggedIn.actions;
export const selectIsLoggedIn = (state) => state.isLoggedIn.value;

export default isLoggedIn.reducer;
