import React from "react";
import { Icon } from "semantic-ui-react";
//import { collectionsToDisplay } from "../../configData";

const DisplayCollectionAlt = ({ collectionName, grammaticalCategory }) => {
  const collectionO = {
    I_Nouns: () => <Icon name="game" />,
    Words: () => <Icon name="book" />,
    Noun: () => <Icon name="book" />,
    Verb: () => "(verb)",
  };
  return (
    <span className="collectionNameAltDisplay">
      &nbsp;{" "}
      {typeof collectionO[collectionName] === "function"
        ? collectionO[grammaticalCategory]()
        : collectionName}
    </span>
  );
};

export default DisplayCollectionAlt;
