
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import {
  Button,
  Input,
  Dropdown,
  Popup,
  Checkbox,
  TextArea,
  Loader,
  Dimmer,
} from "semantic-ui-react";
// import Swal from 'sweetalert2/dist/sweetalert2';
import "@inovua/reactdatagrid-community/index.css";
import { useSelector, useDispatch } from "react-redux";
import { selectAdmin, getAllUsers } from "../../../Store/admin";
import { selectFirestore, changeUserData } from "../../../Store/firestore";
import {
  dateToTimeElapsed,
  dateToFriendly,
} from "../../../Components/nonUIFuncs";


const UserDetailDisplay = ({ userData, closeModalFunc, newUser = false  }) => {
  const [userDataThis, setuserDataThis] = useState({
    displayName: userData.displayName || "",
    email: userData.email || "",
    phoneNumber: userData.phoneNumber || "",
    userAccessLevel: "user",
    disabled: userData.disabled || false,
  });
  const [savedState, setsavedState] = useState("notSaved");

  const fireStoreData = useSelector(selectFirestore);
  useEffect(() => {
   // TODO: stuff 
    if (Object.keys(fireStoreData).length > 0 && fireStoreData?.updateUserDataResponse !== null){
      if (fireStoreData.updateUserDataResponse.sucess) {
        setsavedState("saved")
      } else if(fireStoreData.updateUserDataResponse.sucess === false) {
        setsavedState("errorSave");
      }
    } 
  }, [fireStoreData]);
  const dispatch = useDispatch();

  useEffect(() => {
    let userAccessLevel = "user";
    if (userData?.customClaims?.isAdmin) userAccessLevel = "admin";
    if (userData?.customClaims?.isSuperAdmin) userAccessLevel = "SuperAdmin";

    setuserDataThis({ ...userDataThis, userAccessLevel });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const accessOptions = [
    {
      key: "admin",
      text: "admin",
      value: "admin",
    },
    {
      key: "user",
      text: "User",
      value: "user",
    },
    {
      key: "SuperAdmin",
      text: "SuperAdmin",
      value: "SuperAdmin",
    },
  ];

  const userDataChangehandler = (e,d) => {
    const dataToChange = d.data;
    let newUserData = {...userDataThis};
    newUserData[dataToChange] = d.value;
    setuserDataThis(newUserData);
  }

  const saveBtnHandler = () => {
    console.log("save btn ", userDataThis);
    setsavedState("progress");
    if (newUser){
      dispatch(changeUserData({userData: userDataThis, newUser}));
    } else {
      dispatch(changeUserData({userData: userDataThis, newUser, userID: userData.uid}));
    }
  }

  if (savedState === "progress") {
    return(
    <div className="admin-userDetailDisplay">
      <div>
      Saving Data...
      </div>
    </div>
    );
  }

  if (savedState === "errorSave") {
    return(
    <div className="admin-userDetailDisplay">
      <div>
        Error saving data
      </div>
    </div>
    );
  }

  if (savedState === "saved") {
    return (
      <div className="admin-userDetailDisplay">
        <div>data has been saved and updated</div> 
      </div>
    )
  }

  if (savedState === "notSaved") {  // display when data is not saved yet
    return (
      <div className="admin-userDetailDisplay">
      <h3 className="admin-user-detail-secondary-text">{userData.uid}</h3>
      <div>
      {/* TODO: tie into data */}
      <Popup
      trigger={<Checkbox label="account active  " toggle defaultChecked />}
      content="this will deactivate this account and the user will not be able to login"
      />
      {newUser ||
        <Button
        color="red"
        floated="right"
        onClick={() => {
          delUser(userData);
        }}
        >
        Delete Account
        </Button>
      }
      </div>
      <Input placeholder={userDataThis.displayName} label="Name" data="displayName" onChange={userDataChangehandler} />
      <Input placeholder={userDataThis.email} label="Email" data="email" onChange={userDataChangehandler} />
      <Input placeholder={userDataThis.phoneNumber} label="Phone Number" data="phoneNumber" onChange={userDataChangehandler} />
      <Input placeholder={userDataThis.password || ""} label="Password" data="password" onChange={userDataChangehandler} />
      <div className="ui labeled input ">
      <div className="ui label label">Access Level</div>
      <Dropdown
      selection
      labeled
      fluid
      data="userAccessLevel"
      onChange={userDataChangehandler}
      placeholder={userDataThis.userAccessLevel}
      options={accessOptions}
      />
      </div>
      <div className="ui labled input">
      <div className="ui label labelFix" style={{ width: "5em" }}>
      Notes
      </div>
      <TextArea
      data="notes"
      disabled
      onChange={userDataChangehandler}
      style={{ width: "100%" }}
      type="textarea"
      rows="3"
      placeholder={userDataThis.notes}
      />
      </div>
      {!newUser && (
        <>
        <div
        style={{
          minHeight: "3em",
            display: "flex",
            flexDirection: "column-reverse",
        }}
        >
        Account Creation Date:
        {dateToTimeElapsed(userData.metadata.creationTime)}(
          {dateToFriendly(userData.metadata.creationTime)})
        </div>
        <div>
        Account Last Logged In:
        {dateToTimeElapsed(userData.metadata.lastSignInTime)}(
          {dateToFriendly(userData.metadata.lastSignInTime)})
        </div>
        </>
      )}
      <div style={{marginTop: "10px"}}>
      <div style={{ float: "right" }}>
      <Button onClick={saveBtnHandler} >save</Button>
      <Button color="red" onClick={() => {
        closeModalFunc();
      }}>Cancel</Button>
      </div>
      </div>
      </div>
    );
  }

};

const delUser = (userData) => {

};

export default UserDetailDisplay;
