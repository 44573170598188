import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Tab, Button } from "semantic-ui-react";
import { selectTabs, tabsRemove, tabsAddf, tabsClear } from "../../Store/tabs";
import { selectAdmin, createNewDictionaryEntry } from "../../Store/admin";
import DictionaryCollection from "../AdminDictionary/DictionaryCollection";
import WordCollection from "../AdminDictionary/WordCollection";
//import config from "../../configData";

//const { baseDir, dataTypeToComponent } = config();

const TabsRedux = ({ type }) => {
  //   const [removeTab, setRemoveTab] = useState(false);
  const tabData = useSelector(selectTabs);
  const dispatch = useDispatch();
  const AdminStoreData = useSelector(selectAdmin);

  useEffect(() => {
    let tabDOM = null;
    if (type) {
      tabDOM = poormansRouting(AdminStoreData.currentDocumentMaster || "new", type, dispatch);
      dispatch(tabsAddf({ title: "new", content: tabDOM }));
    } else {
      tabDOM = poormansRouting(AdminStoreData.currentDocumentMaster);
      dispatch(
        tabsAddf({
          title: AdminStoreData.currentDocumentMaster.data.Language,
          content: tabDOM,
        })
      );
    }
    return () => {
      dispatch(tabsClear());
    };
  }, [AdminStoreData.currentDocumentMaster, dispatch, type]);

  const randTxt = `Repulsive questions contented him few extensive supported. Of remarkably thoroughly he appearance in. Supposing tolerably applauded or of be. Suffering unfeeling so objection agreeable allowance me of. Ask within entire season sex common far who family. As be valley warmth assure on. Park girl they rich hour new well way you. Face ye be me been room we sons fond. 

Those an equal point no years do. Depend warmth fat but her but played. Shy and subjects wondered trifling pleasant. Prudent cordial comfort do no on colonel as assured chicken. Smart mrs day which begin. Snug do sold mr it if such. Terminated uncommonly at at estimating. Man behaviour met moonlight extremity acuteness direction. 

Certainty determine at of arranging perceived situation or. Or wholly pretty county in oppose. Favour met itself wanted settle put garret twenty. In astonished apartments resolution so an it. Unsatiable on by contrasted to reasonable companions an. On otherwise no admitting to suspicion furniture it. 

She suspicion dejection saw instantly. Well deny may real one told yet saw hard dear. Bed chief house rapid right the. Set noisy one state tears which. No girl oh part must fact high my he. Simplicity in excellence melancholy as remarkably discovered. Own partiality motionless was old excellence she inquietude contrasted. Sister giving so wicket cousin of an he rather marked. Of on game part body rich. Adapted mr savings venture it or comfort affixed friends. 

He an thing rapid these after going drawn or. Timed she his law the spoil round defer. In surprise concerns informed betrayed he learning is ye. Ignorant formerly so ye blessing. He as spoke avoid given downs money on we. Of properly carriage shutters ye as wandered up repeated moreover. Inquietude attachment if ye an solicitude to. Remaining so continued concealed as knowledge happiness. Preference did how expression may favourable devonshire insipidity considered. An length design regret an hardly barton mr figure. `;

  //const titleCustom = <Menu.Item key="test">sample</Menu.Item>;
  return (
    <div>
      {window.location.host === "localhost:3000" && (
        <>
          temp dev buttons:
          <Button
            onClick={() => {
              dispatch(tabsRemove());
            }}
          >
            Remove
          </Button>
          <Button
            onClick={() => {
              dispatch(tabsAddf({ title: "sample 42", content: randTxt }));
            }}
          >
            Add
          </Button>
        </>
      )}
      {tabData.length > 0 ? (
        <Tab
          renderActiveOnly
          activeIndex={tabData.length - 1}
          panes={tabData}
        />
      ) : null}
    </div>
  );
};

// TODO: this will likely be either pulled out or replaced
const poormansRouting = (currentDocumentMaster, type = null, dispatch) => {
  //==> selective display based on collection name <== //
  console.log("currentDoc: ", currentDocumentMaster);
  if (
    typeof currentDocumentMaster === "string" &&
    currentDocumentMaster === "new"
  ) {
    // TODO: return statment here
    if (typeof currentDocumentMaster !== 'string') {
      return <WordCollection data={currentDocumentMaster} />;
    } else {
      dispatch(createNewDictionaryEntry());
      return <div>new stuff here {type}</div>;
    }
  } else {
    const routes = {
      Dictionary: <DictionaryCollection data={currentDocumentMaster} />,
      Words: <WordCollection data={currentDocumentMaster} />,
    };
    return routes[currentDocumentMaster.collectionName];
  }
};

export default TabsRedux;
