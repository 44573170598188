import React from 'react';
import { Button } from 'semantic-ui-react';
import "./index.scss";

const ButtonGroup = (props) => {
  return (
    <div className="button-group">
      <Button.Group color={props.groupColor || 'blue'}>
        {props.array.map(datum => {
          return (
            <Button
              key={datum}
              onClick={
                () => props.setStateFunc(props.state === datum ? null : datum)
              }
              basic={props.state === datum ? true : false}
              color={props.buttonColor || 'blue'}>
              {datum}
            </Button>
          );
        })}
      </Button.Group>
    </div>
  );
};

export default ButtonGroup;