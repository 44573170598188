import React, { useState, useEffect, useRef } from "react";
import { Button, Modal, Form } from "semantic-ui-react";
import { selectIsLoggedIn } from "../../../Store/loggedIn";
// import { simplifyString } from "../../nonUIFuncs";
import { fileUpload, selectFirestore } from "../../../Store/firestore";
import MediaLibrary from "../../MediaLibrary";
import { DateTime } from "luxon";
import { useSelector, useDispatch } from "react-redux";
import config from "../../../configData";
import "./simpleMediaPreview.scss";
import Dropzone from "../../Dropzone/index";

const baseDir = config().baseDir;

// ==> TODO: This should be moved to Redux <== //
//
// import {getStorage, ref, uploadBytes} from "firebase/storage";
//
// const storage = getStorage();

// =============== //


const SimpleMediaPreview = (
  { mediaObj, mediaType = "any", callbackFunc, idx, mainMedia = false }
) => {
  // mediaType = "audio" || "video" || "image" || "any"
  const fileRef = useRef();
  const [isUpload, setisUpload] = useState(false);
  const [file, setfile] = useState({ file: '' });
  const [showMediaLibrary, setshowMediaLibrary] = useState(false);
  fileRef.current = file;
  const loggedIn = useSelector(selectIsLoggedIn);
  const dispatch = useDispatch();
  const fireStoreData = useSelector(selectFirestore);

  // TODO: check if mediaObj is a string or a complex obj

  useEffect(() => {
    if (fireStoreData?.fileUploadStatusResponse?.name) {
      // callbackFunc({ msg: "here i am", done: true });
      setisUpload(false);
    }
  }, [fireStoreData, callbackFunc]);

  const createNew = (evt) => {
    let currentUserInfo = loggedIn?.currentUserData;
    let storageFilename = "uploads/tmp123.jpg";
    // eslint-disable-next-line no-unused-vars
    let uidTimeFileString =
      currentUserInfo.uid + "_" + DateTime.now().toUnixInteger();

    // dispatch(fileUpload(fileRef.current.file, storageFilename));
  }

  const mediaPlayerDOM = ({ mediaObj }) => {
    const audioPath = `${baseDir}/Audio/${mediaObj}`;
    const mediaPlayerDOMO = {
      audio:
        <div>
          <audio controls src={audioPath}></audio>
        </div>,
      image:
        <div className="simpleMediaPreview-imagemainContainer">
          <img
            className={
              mainMedia
                ? "simpleMediaPreview-mainMedia-img"
                : "simpleMediaPreview-img"
            }
            alt=""
            src={`${baseDir}/Image/${mediaObj}`} />
          <div className="simpleMediaPreview-img-btn">
            <Button
              onClick={() => { changeMediaHdr({ mediaType }) }}
              size="small">
              change Image
            </Button>
          </div>
        </div>,
    }
    return mediaPlayerDOMO[mediaType];
  }

  const changeMediaHdr = ({ mediaType }) => {
    setshowMediaLibrary(true);
    //open mediaLibrary img chooser
    //accept choseen img
    //replace img for this 
  }

  const onNewSelection = ({ mediaIDs }) => {
    console.log("onNewSelectionw:", mediaIDs);
  }

  const mediaTypeDisplayDOM = () => {
    // console.log(typeof mediaObj);
    let dom = [];
    if (mediaObj) {
      dom.push(
        <div>
          {mediaPlayerDOM({ mediaObj })}
        </div>
      );
    } else {
      dom.push(
        <div>
          <Button onClick={() => { setisUpload(true) }} size="small">
            New Upload
          </Button>
          <Button onClick={() => { setshowMediaLibrary(true) }} size="small">
            Choose Existing
          </Button>
        </div>
      );
    }
    return dom;
  }

  let typesToAccept = mediaType;

  //=====> set defaults <====//
  let dropzoneStyle = { width: 400, height: 300 };
  let dropzoneHeaderTxt = "";
  //=====> changes for diffrent media types <====//
  if (mediaType === "audio") {
    dropzoneStyle.height = 150;
    dropzoneHeaderTxt = "Media File Upload (audio — mp3 files)"
  } else {
    dropzoneHeaderTxt = `Media File Upload ( ${mediaType} )`
  }

  // testing
  // console.log("render: ", fireStoreData);
  return (
    <>
      <div
        className={
          mainMedia
            ? "simpleMediaPreview-mainMedia"
            : "simpleMediaPreview-notMainMedia"
        }
      >
        {mediaTypeDisplayDOM()}
      </div>


      {/* mediaLibrary modal */}
      <Modal
        open={showMediaLibrary}
        onClose={() => setshowMediaLibrary(false)}
        onOpen={() => setshowMediaLibrary(true)}
      >
        <Modal.Content>
          <MediaLibrary
            display={mediaType}
            selection
            cbfunc={onNewSelection}
            closeModalFunc={() => {
              setshowMediaLibrary(false);
            }}
          />
        </Modal.Content>
      </Modal>


      {/* fileupload modal */}
      <Modal
        open={isUpload}
        onClose={() => setisUpload(false)}
        onOpen={() => setisUpload(true)}
      >
        <Modal.Header>{dropzoneHeaderTxt}</Modal.Header>
        <Modal.Content>
          <Form
            onSubmit={createNew}
            style={{
              display: "flex",
              flexDirection: "column",
              // alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            {/* <Form.Field>
              <Input
                type="file"
                onChange={(evt) => {
                  // console.log('files: ', evt.target.files);
                  setfile(evt.target.files[0]);
                }}
              />
            </Form.Field> */}
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Dropzone
                style={dropzoneStyle}
                setUploadedImage={setfile}
                selctiveTypes={mediaType !== "all"}
                typesToAccept={typesToAccept}
              />
              {fileRef.current.file.length > 0 ? (
                <div
                  className="mediauploadmodal-content-rightcontent-imagecontainer"
                  style={{ margin: 0, padding: 0 }}
                >
                  <img
                    className="mediauploadmodal-content-rightcontent-image"
                    alt=""
                    src={fileRef.current.file}
                    style={{ width: 400, height: 300, resizeMode: "contain" }}
                  />
                </div>
              ) : null}
            </div>
            <div style={{ width: "100%" }}>
              <div style={{ padding: "10px 0" }}>
                Name: {fileRef.current.fileName || "Unknown"}
              </div>
              <Button type="submit">Submit</Button>
            </div>
          </Form>
        </Modal.Content>
      </Modal>
    </>
  );
}

export default SimpleMediaPreview;
