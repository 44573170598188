import './index.scss';
import React from "react";

const size = 25;

const BoldIcon = (props) => {
  return (
    <svg
      style={{ height:props.size || size, width: props.size || size }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path
        className="custom-icon"
        d="M7.278,5.1c0-0.055,0.045-0.1,0.1-0.1h5.182c1.637,0,2.573,0.175,3.411,0.663c0.916,0.546,1.482,1.559,1.482,2.651c0,1.189-0.532,2.042-1.662,2.643c-0.07,0.037-0.07,0.134,0.001,0.171c1.379,0.711,1.973,1.649,1.973,3.092c0,1.228-0.487,2.281-1.345,2.924C15.581,17.785,14.665,18,12.814,18H7.378c-0.055,0-0.1-0.045-0.1-0.1V5.1z M12.931,10.048c1.052,0,1.598-0.429,1.598-1.286c0-0.838-0.546-1.267-1.598-1.267h-2.687c-0.055,0-0.1,0.045-0.1,0.1v2.353c0,0.055,0.045,0.1,0.1,0.1H12.931z M12.891,15.505c1.228,0,1.891-0.545,1.891-1.579c0-1.033-0.663-1.579-1.891-1.579h-2.648c-0.055,0-0.1,0.045-0.1,0.1v2.958c0,0.055,0.045,0.1,0.1,0.1H12.891z"
      />
    </svg>
  );
};
const ItalicIcon = (props) => {
  return (
    <svg
      style={{ height: props.size || size, width: props.size || size }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path
        className="custom-icon"
        d="M19,6.937V5.063C19,5.028,18.972,5,18.937,5H9.063C9.028,5,9,5.028,9,5.063v1.874C9,6.972,9.028,7,9.063,7H12.3c0.042,0,0.073,0.041,0.06,0.081l-2.731,8.874C9.621,15.982,9.596,16,9.569,16H6.063C6.028,16,6,16.028,6,16.063v1.874C6,17.972,6.028,18,6.063,18h9.874C15.972,18,16,17.972,16,17.937v-1.874C16,16.028,15.972,16,15.937,16H12.7c-0.042,0-0.073-0.041-0.06-0.081l2.731-8.874C15.379,7.018,15.404,7,15.431,7h3.506C18.972,7,19,6.972,19,6.937z"
      />
    </svg>
  );
};
const StrikeThroughIcon = (props) => {
  return (
    <svg
      style={{ height: props.size || size, width: props.size || size }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path
        className="custom-icon"
        d="M20.89,11.4,3.1,11.57a.1.1,0,0,0-.1.1v1.8a.1.1,0,0,0,.1.1l17.8-.17a.1.1,0,0,0,.1-.1V11.5A.11.11,0,0,0,20.89,11.4Zm-6.14-.94c-.34-.12-.72-.22-1.17-.33l-2.72-.59C10,9.34,9.64,9,9.63,8.41c0-.84.85-1.37,2.27-1.39s2.41.62,2.42,1.74V9l2.89,0a.1.1,0,0,0,.1-.11,5.2,5.2,0,0,0-.79-2.46C15.65,5.12,14.12,4.5,12,4.52,8.57,4.55,6.6,6.11,6.63,8.7a3.81,3.81,0,0,0,.44,1.78.11.11,0,0,0,.09,0Zm-.82,4a1.17,1.17,0,0,1,.6,1c0,.94-.93,1.51-2.47,1.53-1.79,0-2.66-.63-2.76-2.08a.11.11,0,0,0-.1-.1l-2.81,0a.11.11,0,0,0-.1.11c.1,2.92,2.19,4.58,5.75,4.54s5.58-1.75,5.55-4.52a4.34,4.34,0,0,0-.05-.53Z"
      />
    </svg>
  );
};
const UnderlineIcon = (props) => {
  return (
    <svg
      style={{ height: props.size || size, width: props.size || size }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path
        className="custom-icon"
        d="M16.9,4C16.955,4,17,4.045,17,4.1V13c0,1.57-1.05,2.73-1.984,3.422c-0.953,0.692-2.312,0.947-3.733,0.947c-1.44,0-2.71-0.374-3.664-1.065C6.684,15.612,6,14.57,6,13V4.1C6,4.045,6.045,4,6.1,4h2.801c0.055,0,0.1,0.044,0.1,0.099l0.076,8.765c0,0.841,0.828,1.746,2.407,1.761C13.188,14.641,14,13.841,14,13V4.1C14,4.045,14.045,4,14.1,4H16.9z"
      />
      <path
        className="custom-icon"
        d="M16.9,21H6.1C6.045,21,6,20.955,6,20.9v-1.8C6,19.045,6.045,19,6.1,19h10.8c0.055,0,0.1,0.045,0.1,0.1v1.8C17,20.955,16.955,21,16.9,21z"
      />
    </svg>
  );
};
const TextClipIcon = (props) => {
  return (
    <svg
      style={{ height: props.size || size, width: props.size || size }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path
        className="custom-icon"
        d="M5.1 19h1.8a.1.1 0 0 0 .1-.1V5.1a.1.1 0 0 0-.1-.1H5.1a.1.1 0 0 0-.1.1v13.8a.1.1 0 0 0 .1.1zM17 5.1v5.8a.1.1 0 0 1-.1.1H9.1a.1.1 0 0 0-.1.1v1.8a.1.1 0 0 0 .1.1h7.8a.1.1 0 0 1 .1.1v5.8a.1.1 0 0 0 .1.1h1.8a.1.1 0 0 0 .1-.1V5.1a.1.1 0 0 0-.1-.1h-1.8a.1.1 0 0 0-.1.1z"
      />
    </svg>
  );
};
const TextWrapIcon = (props) => {
  return (
    <svg
      style={{ height: props.size || size, width: props.size || size }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path
        className="custom-icon"
        d="M6 18.9V5.1a.1.1 0 0 0-.1-.1H4.1a.1.1 0 0 0-.1.1v13.8a.1.1 0 0 0 .1.1h1.8a.1.1 0 0 0 .1-.1zm14 0V5.1a.1.1 0 0 0-.1-.1h-1.8a.1.1 0 0 0-.1.1v13.8a.1.1 0 0 0 .1.1h1.8a.1.1 0 0 0 .1-.1zM17 11c0-4-3.02-4.98-4-5-.36-.008-5.158-.001-5.903 0C7.042 6 7 6.045 7 6.1v1.8a.1.1 0 0 0 .1.1H13c.917 0 2 1 2 3 0 3-2.003 3-2.003 3H11.1a.1.1 0 0 1-.1-.1v-2.66c0-.09-.108-.134-.17-.07l-3.76 3.76a.1.1 0 0 0 0 .141l3.76 3.76a.1.1 0 0 0 .171-.07V16.1a.1.1 0 0 1 .1-.1h1.886S17 16 17 11z"
      />
    </svg>
  );
};
const TextOverflowIcon = (props) => {
  return (
    <svg
      style={{ height: props.size || size, width: props.size || size }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path
        className="custom-icon"
        d="M19.93 11.93l-3.76-3.76a.1.1 0 0 0-.17.071v2.66a.1.1 0 0 1-.1.1H8.1a.1.1 0 0 0-.1.1v1.8a.1.1 0 0 0 .1.1h7.8a.1.1 0 0 1 .1.1v2.66c0 .09.108.134.17.07l3.76-3.76c.038-.04.038-.103-.001-.142zM5.9 5H4.1a.1.1 0 0 0-.1.1v13.8a.1.1 0 0 0 .1.1h1.8a.1.1 0 0 0 .1-.1V5.1a.1.1 0 0 0-.1-.1zm6.2 4h1.8a.1.1 0 0 0 .1-.1V5.1a.1.1 0 0 0-.1-.1h-1.8a.1.1 0 0 0-.1.1v3.8a.1.1 0 0 0 .1.1zm1.8 6h-1.8a.1.1 0 0 0-.1.1v3.8a.1.1 0 0 0 .1.1h1.8a.1.1 0 0 0 .1-.1v-3.8a.1.1 0 0 0-.1-.1z"
      />
    </svg>
  );
};
const VerticalTopIcon = (props) => {
  return (
    <svg
      style={{ height: props.size || size, width: props.size || size }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path
        className="custom-icon"
        d="M12.07 7.07a.1.1 0 0 0-.141 0l-3.76 3.76a.1.1 0 0 0 .07.17h2.66a.1.1 0 0 1 .1.1v7.8a.1.1 0 0 0 .1.1h1.8a.1.1 0 0 0 .1-.1v-7.8a.1.1 0 0 1 .1-.1h2.66c.09 0 .134-.108.07-.17L12.07 7.07zM18.9 3H5.1a.1.1 0 0 0-.1.1v1.8a.1.1 0 0 0 .1.1h13.8a.1.1 0 0 0 .1-.1V3.1a.1.1 0 0 0-.1-.1z"
      />
    </svg>
  );
};
const VerticalCenterIcon = (props) => {
  return (
    <svg
      style={{ height: props.size || size, width: props.size || size }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path
        className="custom-icon"
        d="M19 12.9v-1.8a.1.1 0 0 0-.1-.1H5.1a.1.1 0 0 0-.1.1v1.8a.1.1 0 0 0 .1.1h13.8a.1.1 0 0 0 .1-.1zm-7.07 2.17l-3.76 3.76a.1.1 0 0 0 .071.17h2.66a.1.1 0 0 1 .1.1v2.8a.1.1 0 0 0 .1.1h1.8a.1.1 0 0 0 .1-.1v-2.8a.1.1 0 0 1 .1-.1h2.66c.1 0 .134-.108.07-.17l-3.76-3.76c-.04-.038-.103-.038-.142.001zm.14-6.14l3.76-3.76a.1.1 0 0 0-.071-.17H13.1a.1.1 0 0 1-.1-.1V2.1a.1.1 0 0 0-.1-.1h-1.8a.1.1 0 0 0-.1.1v2.8a.1.1 0 0 1-.1.1H8.24a.1.1 0 0 0-.07.171l3.76 3.76c.038.038.102.038.14-.001z"
      />
    </svg>
  );
};
const VerticalBottomIcon = (props) => {
  return (
    <svg
      style={{ height: props.size || size, width: props.size || size }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path
        className="custom-icon"
        d="M11.93 15.93a.1.1 0 0 0 .14 0l3.76-3.76a.1.1 0 0 0-.07-.17H13.1a.09.09 0 0 1-.1-.1V4.1a.09.09 0 0 0-.1-.1h-1.8a.09.09 0 0 0-.1.1v7.8a.09.09 0 0 1-.1.1H8.24a.1.1 0 0 0-.07.17zm7 2.07H5.1a.09.09 0 0 0-.1.1v1.8a.09.09 0 0 0 .1.1h13.8a.09.09 0 0 0 .1-.1v-1.8a.09.09 0 0 0-.1-.1z"
      />
    </svg>
  );
};
const TextColorIcon = (props) => {
  return (
    <svg
      style={{ height: props.size || size, width: props.size || size }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path
        className="custom-icon"
        d="M9.763,13.407L9.05,15.68c-0.013,0.042-0.052,0.07-0.095,0.07h-2.72c-0.069,0-0.117-0.068-0.094-0.133l4.125-11.81c0.014-0.04,0.052-0.067,0.094-0.067h3.223c0.044,0,0.082,0.028,0.095,0.07l3.753,11.81c0.02,0.064-0.028,0.13-0.095,0.13h-2.847c-0.045,0-0.085-0.03-0.097-0.074l-0.609-2.265c-0.012-0.044-0.051-0.074-0.097-0.074H9.859C9.815,13.337,9.776,13.366,9.763,13.407z M11.807,6.754l-1.315,4.239c-0.02,0.064,0.028,0.13,0.096,0.13h2.453c0.066,0,0.114-0.062,0.097-0.126l-1.137-4.239C11.973,6.661,11.836,6.658,11.807,6.754z"
      />
      <path
        className="custom-icon"
        d="M20.9,21H3.1C3.045,21,3,20.955,3,20.9v-2.8C3,18.045,3.045,18,3.1,18h17.8c0.055,0,0.1,0.045,0.1,0.1v2.8C21,20.955,20.955,21,20.9,21z"
      />
    </svg>
  );
};
const CellColorIcon = (props) => {
  return (
    <svg
      style={{ height: props.size || size, width: props.size || size }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path
        className="custom-icon"
        d="M17.54,14.032c-0.019-0.023-0.047-0.036-0.077-0.036L6.582,14l-0.457-0.457l7.462-6.396c0.042-0.036,0.047-0.099,0.011-0.141l-2.953-3.429c-0.036-0.042-0.099-0.047-0.141-0.011L9.496,4.434C9.454,4.47,9.45,4.533,9.486,4.575l1.953,2.267c0.036,0.042,0.031,0.105-0.011,0.141l-7.47,6.404c-0.044,0.038-0.047,0.105-0.006,0.147l7.437,7.437c0.037,0.037,0.095,0.039,0.135,0.006l6.89-5.741c0.042-0.035,0.048-0.098,0.013-0.141L17.54,14.032z M19.5,17.309c-0.206-0.412-0.793-0.412-0.999,0c0,0-1.506,3.186,0.5,3.186S19.5,17.309,19.5,17.309z"
      />
    </svg>
  );
};
const BorderAllIcon = (props) => {
    return (
      <svg
        style={{ height: props.size || size, width: props.size || size }}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path
          className="custom-icon"
          d="M3 3.1v17.8a.1.1 0 0 0 .1.1h17.8a.1.1 0 0 0 .1-.1V3.1a.1.1 0 0 0-.1-.1H3.1a.1.1 0 0 0-.1.1zM5.1 5h5.8a.1.1 0 0 1 .1.1v5.8a.1.1 0 0 1-.1.1H5.1a.1.1 0 0 1-.1-.1V5.1a.1.1 0 0 1 .1-.1zM5 18.9v-5.8a.1.1 0 0 1 .1-.1h5.8a.1.1 0 0 1 .1.1v5.8a.1.1 0 0 1-.1.1H5.1a.1.1 0 0 1-.1-.1zm13.9.1h-5.8a.1.1 0 0 1-.1-.1v-5.8a.1.1 0 0 1 .1-.1h5.8a.1.1 0 0 1 .1.1v5.8a.1.1 0 0 1-.1.1zM13 10.9V5.1a.1.1 0 0 1 .1-.1h5.8a.1.1 0 0 1 .1.1v5.8a.1.1 0 0 1-.1.1h-5.8a.1.1 0 0 1-.1-.1z"
        />
      </svg>
    );
};
const FilterIcon = (props) => {
    return (
      <svg
        style={{ height: props.size || size, width: props.size || size }}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path
          className="custom-icon"
          d="M10 11.987v6.904c0 .038.02.073.055.09l3.8 1.9a.1.1 0 0 0 .145-.089v-8.804c0-.022.007-.044.02-.06l6.853-8.812a.1.1 0 0 0-.079-.161H3.204a.1.1 0 0 0-.079.161l6.853 8.812c.015.017.022.04.022.06zm1.922-1.085L8.13 6.162A.1.1 0 0 1 8.208 6h7.584a.1.1 0 0 1 .078.162l-3.792 4.74a.1.1 0 0 1-.156 0z"
        />
      </svg>
    );
};

export {
  BoldIcon,
  ItalicIcon,
  StrikeThroughIcon,
  UnderlineIcon,
  TextClipIcon,
  TextWrapIcon,
  TextOverflowIcon,
  VerticalTopIcon,
  VerticalCenterIcon,
  VerticalBottomIcon,
  TextColorIcon,
  CellColorIcon,
  BorderAllIcon,
  FilterIcon,
};
