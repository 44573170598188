import React, { useCallback } from "react";
import "./index.scss";
import { useDropzone } from "react-dropzone";
import { uploadFromSting } from "../../Store/firestore";
import { fetchImage } from "../nonUIFuncs";
import { useDispatch } from "react-redux";

const Dropzone = ({ style, setUploadedImage, dir = "Image", selctiveTypes = false, typesToAccept = "images" }) => {
  //TODO: expand props to be more self documenting
  const Dispatch = useDispatch();
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.warn("file reading has failed");
      reader.onload = () => {
        const binaryStr = reader.result;
        Dispatch(uploadFromSting({ string: binaryStr, filename: file.name, dir }));
        console.log('file', file);
        setUploadedImage({ file: fetchImage(file.name), type: file.type, fileName: file.name });
      };
      reader.readAsDataURL(file);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let acceptTypesObj = {};
  if (selctiveTypes) {
    switch (typesToAccept) {
      case 'image':
      case 'images':
        acceptTypesObj = {
          'image/png': ['.png'],
          'image/jpeg': ['.jpg', '.jpeg'],
        };
        break;
      case 'audio':
        acceptTypesObj = {
          'audio/mp3': ['.mp3'],
        };
        break;
      default:
        console.warn("no accept type of known type: ", typesToAccept);
        break;
    }
  }
  const { getRootProps, getInputProps, isDragActive, ...other } = useDropzone({
    onDrop,
    accept: acceptTypesObj,
  });

  console.log("→ other stuff ", other);
  return (
    <div className="gameData-dropzone-container" style={style} {...getRootProps()}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <p>Drop the files ...</p>
      ) : (
        <p>Drag 'n' drop a file here, or click to select files</p>
      )}
    </div>
  );
};

export default Dropzone;
