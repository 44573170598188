import { createSlice } from "@reduxjs/toolkit";

export const testSlice = createSlice({
  name: "test",
  initialState: {
    value: false,
  },
  reducers: {
    flip: (state) => {
      state.value = state.value ? false : true;
    },
  },
});

export const { flip } = testSlice.actions;

export const selectTest = (state) => state.test.value;

export default testSlice.reducer;
