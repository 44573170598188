import React, { useState, useEffect } from "react";
import { Editor } from "react-draft-wysiwyg";
import { Button } from "semantic-ui-react";
import {
  EditorState,
  convertToRaw,
  Modifier,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
//import htmlToDraft from "html-to-draftjs";
import config from "../../configData";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./index.scss";

const { lookupTable } = config();

let specialCharsA = [];
for (const char in lookupTable) {
  if (lookupTable[char].length > 1) {
    // add all but the first element of the char array
    let charsA = lookupTable[char].slice(1, lookupTable[char].length);
    specialCharsA.push(...charsA);
  }
}
Object.freeze(specialCharsA);


const toolbarOptions = {
  className: "toolBarBtn",
  options: [
    "inline",
    "blockType",
    "fontSize",
    "fontFamily",
    "list",
    "textAlign",
    "colorPicker",
    "link",
    "embedded",
    "emoji",
    "image",
    "remove",
    "history",
  ],
  blockType: {
    inDropdown: true,
    options: [
      "Normal",
      "H1",
      "H2",
      "H3",
      "H4",
      "H5",
      "H6",
      "Blockquote",
      "Code",
    ],
  },
  fontFamily: {
    options: [
      "Arial",
      "Georgia",
      "Impact",
      "Tahoma",
      "Times New Roman",
      "Verdana",
    ],
  },
  colorPicker: {
    colors: [
      "rgb(97,189,109)",
      "rgb(26,188,156)",
      "rgb(84,172,210)",
      "rgb(44,130,201)",
      "rgb(147,101,184)",
      "rgb(71,85,119)",
      "rgb(204,204,204)",
      "rgb(65,168,95)",
      "rgb(0,168,133)",
      "rgb(61,142,185)",
      "rgb(41,105,176)",
      "rgb(85,57,130)",
      "rgb(40,50,78)",
      "rgb(0,0,0)",
      "rgb(247,218,100)",
      "rgb(251,160,38)",
      "rgb(235,107,86)",
      "rgb(226,80,65)",
      "rgb(163,143,132)",
      "rgb(239,239,239)",
      "rgb(255,255,255)",
      "rgb(250,197,28)",
      "rgb(243,121,52)",
      "rgb(209,72,65)",
      "rgb(184,49,47)",
      "rgb(124,112,107)",
      "rgb(209,213,216)",
    ],
  },
  image: {
    urlEnabled: true,
    uploadEnabled: true,
    alignmentEnabled: true,
    previewImage: false,
    inputAccept: "image/gif,image/jpeg,image/jpg,image/png,image/svg",
    alt: { present: false, mandatory: false },
    defaultSize: {
      height: "auto",
      width: "auto",
    },
  },
  history: {
    inDropdown: false,
    options: ["undo", "redo"],
  },
};

const TextEditor = ({ children, saveFunc, label = "", data }) => {
  const [editorState, seteditorState] = useState(EditorState.createEmpty());
  const [isInFocus, setisInFocus] = useState(false);

  useEffect(() => {
    console.log(" ==> Inside useEffect (TextEditor)<== ");
    // ==> check if data is in spreadsheet format <== //
    if (typeof data === "string" && data.length < 1) {
      // do nothing
    } else if (typeof data === "string") {
      // convert to object
      //const html = `<p>${data}</p>`;
      //const contentBlock = htmlToDraft(html);
      //const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      let newEditorState = EditorState.createWithText(data)
      seteditorState(newEditorState);
    } else {
      // already an object
      let newEditorState = EditorState.createWithContent(data.raw);
      seteditorState(newEditorState);
    }
    //TODO: write object to editorState
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onEditorStateChange = (es) => {
    seteditorState(es);
  };

  const addChar = ({ onChange, char }) => {
    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      `${char}`,
      editorState.getCurrentInlineStyle()
    );
    onChange(EditorState.push(editorState, contentState, "insert-characters"));
  };

  // sets up some variables for styles
  let toolbarStyle = {};
  let textEditorWrapperStyle = {};

  // sets some conditional styling on if component is in focus or not
  if (!isInFocus) {
    toolbarStyle = {
      display: "none",
    };
  } else {
    textEditorWrapperStyle = {
      border: "3px solid rgba(255,255,255,0.3)",
    };
  }

  return (
    <div
      className="TextEditor-Wrapper"
      style={textEditorWrapperStyle}
      onBlur={() => {
        setTimeout(() => {
          // HACK: allows the onclick event of the button (save) to exec
          setisInFocus(false)
        }, 300);
      }}
      onFocus={() => {
        setisInFocus(true);
      }}
    >
      <div className="textEditor_label">{label}</div>
      <Editor
        toolbarOnFocus={true}
        editorClassName="textEditor_Editor"
        toolbarClassName="textEditor_Toolbar"
        toolbar={toolbarOptions}
        toolbarStyle={toolbarStyle}
        editorState={editorState}
        toolbarCustomButtons={[<CusToolButton addChar={addChar} />]}
        //onContentStateChange={(cs) => {
        //// let jsonRaw = convertFromRaw(cs);
        //// console.log(JSON.stringify(jsonRaw));
        //}}
        onEditorStateChange={(editorState) => {
          onEditorStateChange(editorState);
        }}
      />
      {children}
      {isInFocus && (
        <Button
          onClick={() => {
            let raw = convertToRaw(editorState.getCurrentContent());
            const markupHTML = draftToHtml(raw);
            if (saveFunc) {
              saveFunc({ html: markupHTML, raw: raw });
            }
          }}
        >
          Save
        </Button>
      )}
    </div>
  );
};

export default TextEditor;

const CusToolButton = ({ addChar, onChange }) => {
  const buttonA = specialCharsA.map((char) => {
    return (
      <Button
        key={char}
        onClick={() => {
          addChar({ onChange, char });
        }}
        size="mini"
        style={{ padding: ".0em .5em", height: "1.6em" }}
        className="textEditor_toolbar_button"
      >
        {char}
      </Button>
    );
  });
  return (
    <>
      <div style={{ width: "2em" }}></div>
      {buttonA}
      <div style={{ width: "2em" }}></div>
    </>
  );
};
