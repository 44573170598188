import React from "react";
import config from "../../configData";
import { Form, Input } from "semantic-ui-react";

const { NativeLanguage: nativeLanguage } = config();

const DictionaryCollection = (props) => {
  const data = props.data.data;
  console.log("This is the data i have: ", props.data);
  return (
    <div className="dictionaryCollection-main">
      <h1>Dictionary Data</h1>
      <div className="dictionaryCollection-Form">
        <Form.Group>
          <Form.Field>
            <label>{nativeLanguage}</label>
            <Input placeholder="test" />
          </Form.Field>
        </Form.Group>
        English: {data.English}
        Collection Name: {props.data.collectionName} Category:
        {data.Dictionary_Category} Part_Of_Speach: {data.Part_Of_Speach}
      </div>
    </div>
  );
};

export default DictionaryCollection;
