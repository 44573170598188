/* eslint-disable array-callback-return */
import React from "react";
import ButtonGroup from "../ButtonGroup";
import "./index.scss";

const AdvancedSearchBar = (props) => {
  return (
    <div className={props.className}>
      {props.buttonGroupsArray.map(datum => {
        if (datum !== undefined) {
          return <ButtonGroup {...datum} />;
        }
        //TODO: this should return if not
      })}
    </div>
  );
};

export default AdvancedSearchBar;
