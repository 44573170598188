import "./index.scss";
// import "../../../App.scss";
import React from "react";
import { Form } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";


const FormInput = (props) => {
  return (
    <Form.Input
      required={props.required || false}
      disabled={props.disabled || false}
      className="formComponents-input"
      onChange={(e, data) => props.updateRow(data.placeholder, data.value)}
      label={props.label}
      placeholder={props.placeholder}
    />
  );
};

const FormCheckbox = (props) => {
  return (
    <Form.Checkbox
      className="formComponents-checkbox"
      onClick={(e, data) => props.updateRow(data.label, data.checked)}
      label={props.label}
      style={props.style}
    />
  );
};

export { FormInput, FormCheckbox };
