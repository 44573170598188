import { createSlice } from "@reduxjs/toolkit";
import { getFunctions, httpsCallable } from "firebase/functions";
import {
  getFirestore,
  doc,
  updateDoc,
  addDoc,
  collection,
  query,
  where,
  limit,
  getDocs,
} from "@firebase/firestore";
import { deleteUser } from "firebase/auth";

const db = getFirestore(window.app);
const functions = getFunctions(window.app);

export const adminSlice = createSlice({
  name: "admin",
  initialState: {
    value: {},
  },
  reducers: {
    user: (state, action) => {
      state.value = { ...state.value, user: action.payload };
    },
    currentScreen: (state, action) => {
      state.value = { ...state.value, currentScreen: action.payload };
    },
    currentDocumentMaster: (state, action) => {
      state.value = { ...state.value, currentDocumentMaster: action.payload };
    },
    allUsers: (state, action) => {
      state.value = { ...state.value, allUsers: action.payload };
    },
  },
});

export const getAllUsers = () => async (dispatch) => {
  const getUserList = httpsCallable(functions, "listAllUsersCall");
  let userList = await getUserList();
  dispatch(allUsers(userList));
};

//TODO: looks like to manage other users it must be done with the admin sdk so we will do that in a cloud function

export const delUser = (user) => async (dispatch) => {
  await deleteUser(user.uid);
  console.log("user deleted");
};

export const updateUser =
  ({ userInfo, newUser = false }) =>
    async (dispatch) => {
      //todo: tokenChanges and tokenClaimChanges will be done in cloud functions
      //const tokenChanges = {
      //name: userInfo.name,
      //email: userInfo.name,
      //phoneNumber: userInfo.phoneNumber,
      //};
      //const tokenClaimChanges = {
      //isSuperAdmin: userInfo.accessLevel === "superAdmin",
      //isAdmin:
      //userInfo.accessLevel === "admin" ||
      //userInfo.accessLevel === "superAdmin",
      //};
      const databaseUserChanges = {
        notes: userInfo.notes,
        fullName: userInfo.name,
        email: userInfo.email,
      };

      let userAdminDocref = doc(db, "adminUsers", userInfo.uid);

      if (!newUser) {
        await updateDoc(userAdminDocref, databaseUserChanges);
      }
    };

export const createNewDictionaryEntry = () => async (dispatch) => {
  let collectionName = 'Words';
  let anExistingDocument = {};
  let emptyDocumentToPush = {};
  const q = query(
    collection(db, "Words"),
    where("English", ">", ''),
    limit(1),
  );
  const querySnap = await getDocs(q);
  querySnap.forEach((doc) => {
    anExistingDocument = doc.data();
    let keys = Object.keys(anExistingDocument);
    keys.forEach((k) => {
      if (typeof anExistingDocument[k] === 'string') {
        emptyDocumentToPush[k] = '';
      } else if (typeof anExistingDocument[k] === 'boolean') {
        emptyDocumentToPush[k] = false;
      } else if (typeof anExistingDocument[k] === 'number') {
        emptyDocumentToPush[k] = 0;
      } else {
        emptyDocumentToPush[k] = anExistingDocument[k];
      }
    });
  });
  const newDocRef = await addDoc(collection(db, collectionName), {
    ...emptyDocumentToPush,
    collectionName: collectionName,
  });

  dispatch(
    currentDocumentMaster({
      collectionName: collectionName,
      data: emptyDocumentToPush,
      id: newDocRef.id,
    })
  );
};

export const getUserAdminPrefs = () => async (dispatch) => { };

export const { user, currentScreen, currentDocumentMaster, allUsers } =
  adminSlice.actions;
export const selectAdmin = (state) => state.admin.value;

export default adminSlice.reducer;
