/* eslint-disable array-callback-return */
import React, { useState, useLayoutEffect } from "react";
import { Icon, Loader, Button, Modal, Checkbox } from "semantic-ui-react";
import MediaDisplay from "../MediaDisplay";
//import { getLanguageArray } from "../nonUIFuncs";
//import config from "../../configData";
import imagesLoaded from "imagesloaded";

//const CONFIG = config();

const GridLayout = ({ data, selection = false, multiple = false, cbfunc }) => {
  useLayoutEffect(() => {
    resizeAllGridItems();
    return window.removeEventListener("resize", resizeAllGridItems);
  });
  // useEffect(() => {
  //   resizeAllGridItems();
  // });
  const resizeGridItem = (item) => {
    let grid = document.getElementsByClassName("mediaLibrary-grid")[0];
    if (grid) {
      let rowHeight = parseInt(
        window.getComputedStyle(grid).getPropertyValue("grid-auto-rows")
      );
      let rowGap = parseInt(
        window.getComputedStyle(grid).getPropertyValue("grid-row-gap")
      );
      let rowSpan = Math.ceil(
        (item.querySelector(".grid-item-content").getBoundingClientRect()
          .height +
          rowGap) /
        (rowHeight + rowGap)
      );
      item.style.gridRowEnd = "span " + rowSpan;
    }
  };

  const resizeInstance = (inst) => {
    let item = inst.elements[0];
    resizeGridItem(item);
  };

  const resizeAllGridItems = () => {
    let allItems = document.getElementsByClassName("grid-item");
    for (let j = 0; j < allItems.length; j++) {
      imagesLoaded(allItems[j], resizeInstance); // after all images are loaded do this
    }
    let i = 0;
    for (i = 0; i < allItems.length; i++) {
      resizeGridItem(allItems[i]);
    }
  };

  const selectionHdlr = (e, v, id, data) => {
    if (v.checked) console.log("i am checked");
    else console.log("i am unchecked");
    console.log("ID: ", id);
    if (data.isImage) console.log("filename: ", data.image);
    if (data.isAudio) console.log("filename: ", data.audio);
    console.log("state of check: ", v);
    cbfunc({ mediaID: id, checked: v.checked });
  }

  window.onload = resizeAllGridItems();
  window.addEventListener("resize", resizeAllGridItems);
  if (!data) {
    return (
      <div>
        <Loader active size="big" />
        loading...
      </div>
    );
  }
  if (data?.length === 0) {
    return <div>no data found, please broaden your search parameters </div>;
  }
  let ids = [];
  return (
    <div>
      <div className="mediaLibrary-grid">
        {data.map((e) => {
          if (!ids.includes(e.id)) {
            ids.push(e.id);
            return (
              <div className="grid-item" key={e.id}>
                <div className="grid-item-content">
                  <MediaLayoutHeader data={e.data}>
                    {selection ? <div>
                      <Checkbox onChange={(event, v) => selectionHdlr(event, v, e.id, e.data)} fitted />
                    </div> : null}
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "center",
                      }}
                    >
                      <div style={{ marginRight: 5 }}>{e.data.Language.BF}</div>
                      <div>({e.data.English})</div>
                    </div>
                  </MediaLayoutHeader>
                  <div className="MediaLibrary-GridItems-content">
                    <MediaDisplay obj={e} />
                  </div>
                </div>
              </div>
            );
          }
        })}
      </div>

      <div style={{ height: 40, width: "100%" }} />
    </div>
  );
};

const MediaLayoutHeader = (props) => {
  const [showModal, setShowModal] = useState(false);
  //TODO: set on enter depress that it should close the modal when open
  return (
    <div className="MediaLibrary-GridItems-Header">
      <Modal open={showModal} className="modalKat">
        <Modal.Header>
          {props.data.Languages.map((d) => (
            <div key={d}>{d}</div>
          ))}
          <div className="englishTxtHeader">({props.data.English})</div>
        </Modal.Header>
        <Modal.Content>
          <div className="GridLayout-modal-grid">
            <div className="ML-expandedImageView">{showData(props.data)}</div>
            {/* <div>Tags:</div> */}
            <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <MediaDisplay obj={props} large />
            </div>
          </div>
        </Modal.Content>
        <Modal.Actions className="footer">
          <Button
            content="Close"
            icon="close"
            onClick={() => {
              setShowModal(false);
            }}
          />
        </Modal.Actions>
      </Modal>
      <div className="MediaLibrary-GridItems-Header-Content">
        {props.children}
      </div>
      <div className="MediaLibrary-Header-box">
        <Icon
          fitted
          name="expand arrows alternate"
          // size="small"
          onClick={(e) => {
            setShowModal(true);
            // console.log(" I was clicked", props.data);
          }}
        />
      </div>
    </div>
  );
};

const showData = (data) => {
  let domA = [];
  for (const key in data) {
    if (typeof data[key] !== "object") {
      // skips displaying objects
      domA.push(
        <div key={key}>
          {key} : {data[key]}
        </div>
      );
    }
  }
  return domA;
};

export default GridLayout;

