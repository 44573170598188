import React from "react";
import { createSlice } from "@reduxjs/toolkit";
import { Tab, Menu, Popup } from "semantic-ui-react";

export const tabSlice = createSlice({
  name: "tabs",
  initialState: {
    value: [],
  },
  reducers: {
    tabsAdd: (state, action) => {
      state.value = [...state.value, action.payload];
    },
    tabsRemove: (state) => {
      let newTabs = state.value.slice(0, -1);
      state.value = newTabs;
    },
    tabsChange: (state, action) => {
      state.value = { ...state.value, tabs: action.payload };
    },
    tabsClear: (state, action) => {
      state.value = [];
    },
  },
});

export const { tabsAdd, tabsRemove, tabsChange, tabsClear } = tabSlice.actions;

export const tabsAddf = ({ title, content }) => (dispatch) => {
  let domTest = {
    menuItem: (
      <Menu.Item key={title}>
        <Popup
          content="fill out and close current tab first"
          trigger={<div>{title}</div>}
        />
      </Menu.Item>
    ),
    render: () => <Tab.Pane>{content}</Tab.Pane>,
  };
  dispatch(tabsAdd(domTest));
};

// export const tabRemovef = () => (dispatch) => {

// }

export const selectTabs = (state) => state.tabs.value;

export default tabSlice.reducer;
