import "./index.scss";
import React from "react";
// import PropTypes from 'prop-types';
import { playAudio } from "../nonUIFuncs/index";

const Welcome = ({ clientConfig, dims }) => {
  let onlyOne = false;
  if (clientConfig.langArray.length <= 0 || clientConfig.engArray <= 0) {
    onlyOne = true;
  }
  return (
    <div className="current-screen-style">
      <div className="welcome-container">
        <div
          className={
            dims.width < 800
              ? "welcome-topContainerVertical"
              : "welcome-topContainer"
          }
        >
          {onlyOne ? null :
            <div className="welcome-containerContentsLang">
              <div className="welcome-top-message-container">
                {clientConfig.langArray.map((datum) => {
                  return (
                    <div
                      key={datum.text}
                      className="welcome-message-lang nativeLangFont"
                      onClick={() => playAudio(datum.audio)}
                    >
                      {datum.text}
                    </div>
                  );
                })}
              </div>
            </div>

          }
          <div
            className={
              dims.width < 800
                ? "welcome-containerContentsEngVertical"
                : "welcome-containerContentsEng"
            }
          >
            <div className="welcome-top-message-container">
              {clientConfig.engArray.map((datum) => {
                return (
                  <div key={Math.random()} className="welcome-message nativeLangFont">
                    {datum}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="welcome-logo-container">
          {clientConfig.logoArray.map((sty) => {
            return <div key={sty} className={sty} />;
          })}
        </div>
        {clientConfig.messageArray.map((msg, idx) => {
          return <div key={idx} className="welcome-messageLower">{msg}</div>;
        })}
      </div>
    </div>
  );
};

export default Welcome;

Welcome.propTypes = {};
