import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  Button,
  Input,
  Dropdown,
  Checkbox,
  Icon,
  Image,
} from "semantic-ui-react";
import SimpleMediaPreview from "../../../Admin/SimpleMediaPreview";
import TextEditor from "../../../TextEditor";
import InputControlled from "../../../InputControlled";

const SalishWordForm = ({
  formData,
  setdataSaved,
  setformData,
  nativeLanguage,
  SimpleMediaPreview,
  WordsGrammaticalCategory,
  WordsGrammaticalSubclass,
  tagOptionsDD,
  handleAddItemTagOption,
  rootDD,
  handleAddItemRootTypeOption,
  WordsCategory,
  WordsSUBPlurality,
  WordsSUBPerson,
  WordsRootTypeOptionsDD,
  WordsSubcategory,
  genderOptions,
  speakerFormDOM,
}) => {

  return (
    <div className="dictionaryCollection-Form">
      <Form>
        <Form.Group inline>
          <Form.Field>
            <Checkbox
              label="Record is active"
              toggle
              checked={formData.recordActive}
              onChange={() => {
                setdataSaved(false);
                setformData({
                  ...formData,
                  recordActive: !formData.recordActive,
                });
              }}
            />
          </Form.Field>
          <Form.Field>
            <Checkbox
              label="Verified with Native fluent speaker"
              toggle
              onChange={() => {
                setdataSaved(false);
                setformData({
                  ...formData,
                  'Verified w/L1?': !formData['Verified w/L1?'],
                });
              }}
              checked={formData['Verified w/L1?']}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group style={{ position: 'relative' }}>
          <Form.Field>
            <label>{nativeLanguage}</label>
            <InputControlled
              showSpecialCharsEntry
              placeholder="test"
              size="small"
              value={formData.Language}
              onChange={(_e, d) => {
                setdataSaved(false);
                setformData({
                  ...formData,
                  Language: d.value,
                });
              }}
            />
          </Form.Field>
          <Form.Field>
            <label>English</label>
            <Input
              size="small"
              value={formData.English}
              onChange={(_e, d) => {
                setdataSaved(false);
                setformData({
                  ...formData,
                  English: d.value,
                });
              }}
            />
          </Form.Field>
          <Form.Field style={{ flexGrow: 1, position: 'absolute', right: 0, top: 0 }}>
            <SimpleMediaPreview
              mediaObj={formData.App_Art}
              mediaType="image"
              mainMedia
            />
            <Image src="" size="small" />
          </Form.Field>
        </Form.Group>
        <Form.Group>
          <Form.Field>
            <label>Grammatical Category</label>
            <Dropdown
              value={formData["Grammatical_Category"]}
              minCharacters={6}
              selection
              options={WordsGrammaticalCategory}
              onChange={(_e, d) => {
                setdataSaved(false);
                setformData({
                  ...formData,
                  Grammatical_Category: d.value,
                });
              }}
            ></Dropdown>
          </Form.Field>
          <Form.Field>
            <label>Grammatical Subclass</label>
            <Dropdown
              value={formData["Grammatical.Subclass"]}
              minCharacters={1}
              search
              selection
              options={WordsGrammaticalSubclass}
              onChange={(_e, d) => {
                setdataSaved(false);
                setformData({
                  ...formData,
                  "Grammatical.Subclass": d.value,
                });
              }}
            ></Dropdown>
          </Form.Field>
        </Form.Group>
        <Form.Group>
          <Form.Field>
            <label>Tags</label>
            <Dropdown
              search
              minCharacters={2}
              allowAdditions
              selection
              options={tagOptionsDD}
              onAddItem={handleAddItemTagOption}
              multiple
            />
          </Form.Field>
        </Form.Group>
        <Form.Group>
          <Form.Field>
            <label>Root</label>
            <Dropdown
              value={formData.Root}
              search
              minCharacters={3}
              allowAdditions
              selection
              options={rootDD}
            />
          </Form.Field>
          <Form.Field>
            <label>Root Type</label>
            <Dropdown
              search
              minCharacters={3}
              allowAdditions
              selection
              value={formData["Root.Type"]}
              options={WordsRootTypeOptionsDD}
              onAddItem={handleAddItemRootTypeOption}
              onChange={(_e, d) => {
                setdataSaved(false);
                setformData({
                  ...formData,
                  "Root.Type": d.value,
                });
              }}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group>
          <TextEditor
            label="Notes (Never Displayed to non admin users)"
            data={formData.Notes}
            saveFunc={({ html, raw }) => {
              console.log("===> saved data raw ", raw);
              console.log("===> saved data html ", html);
              setformData({
                ...formData,
                Notes: { raw: raw, html: html },
              });
            }}
          ></TextEditor>
        </Form.Group>
        <Form.Group>
          <TextEditor label="Language Notes" data=""></TextEditor>
        </Form.Group>
        <Form.Group>
          <Form.Field>
            <label>Category</label>
            <Dropdown
              value={formData.Category}
              search
              minCharacters={3}
              allowAdditions
              selection
              onChange={(_e, d) => {
                setdataSaved(false);
                setformData({
                  ...formData,
                  Category: d.value,
                });
              }}
              options={WordsCategory}
            />
          </Form.Field>
          <Form.Field>
            <label>SubCategory</label>
            <Dropdown
              value={formData.Subcategory}
              search
              minCharacters={3}
              allowAdditions
              selection
              onChange={(_e, d) => {
                setdataSaved(false);
                setformData({
                  ...formData,
                  Subcategory: d.value,
                });
              }}
              options={WordsSubcategory}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group>
          <Form.Field>
            <label>Subject Plurality</label>
            <Dropdown
              value={formData["SUB.Plurality"]}
              minCharacters={6}
              selection
              options={WordsSUBPlurality}
              onChange={(_e, d) => {
                setdataSaved(false);
                setformData({
                  ...formData,
                  "SUB.Plurality": d.value,
                });
              }}
            ></Dropdown>
          </Form.Field>
          <Form.Field>
            <label>Subject Person</label>
            <Dropdown
              value={formData["SUB.Person"]}
              minCharacters={3}
              selection
              // allowAdditions
              options={WordsSUBPerson}
              onChange={(_e, d) => {
                setdataSaved(false);
                setformData({
                  ...formData,
                  "SUB.Person": d.value,
                });
              }}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group>
          <Form.Field>
            <label>Morphological Breakdown</label>
            <InputControlled
              value={formData["Morphological Breakdown"]}
              showSpecialCharsEntry
            />
          </Form.Field>
          <Form.Field>
            <label>Verified</label>
            <Checkbox toggle checked={true} />
          </Form.Field>
        </Form.Group>
        <Form.Group>
          <Form.Field>
            <label>Interlinear Gloss</label>
            <InputControlled
              value={formData["Interlinear Gloss"]}
              showSpecialCharsEntry
            />
          </Form.Field>
          <Form.Field>
            <Checkbox label="Verified with L1" toggle checked={true} />
          </Form.Field>
        </Form.Group>
        <Form.Group>
          <Form.Field>
            <label>Speaker</label>
            <InputControlled
              value={formData["Interlinear Gloss"]}
              showSpecialCharsEntry
            />
          </Form.Field>
          <Form.Field>
            <label>Gender</label>
            <Dropdown
              value=""
              minCharacters={4}
              selection
              options={genderOptions}
            ></Dropdown>
          </Form.Field>
        </Form.Group>
        {/* Speakers */}
        {formData.speakers &&
          speakerFormDOM({ speakersObj: formData.speakers })}
        {/* END Spearkers */}
        {/* <Form.Group inline>
            <Form.Field>
              <label>Interlinear Gloss</label>
              <InputControlled
                value={formData["Interlinear Gloss"]}
                showSpecialCharsEntry
              />
            </Form.Field>
            <Form.Field>
              <Checkbox label="Verified with L1" toggle checked={true} />
            </Form.Field>
          </Form.Group> */}
        <Form.Group inline>
          <Form.Field>
            <label>Interlinear Gloss</label>
            <InputControlled
              value={formData["Interlinear Gloss"]}
              showSpecialCharsEntry
            />
          </Form.Field>
          <Form.Field>
            <Checkbox label="Verified with L1" toggle checked={true} />
          </Form.Field>
        </Form.Group>
      </Form>
    </div>);
}


export default SalishWordForm
