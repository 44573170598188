import React from "react";
import config from "../../configData";

const baseDir = config().baseDir;

const AudioDisplayLg = (props) => {
  return (
    <div>
      <audio controls src={`${baseDir}/Audio/${props.obj.data.audio}`}>
        audio not being displayed
      </audio>
    </div>
  );
};

export default AudioDisplayLg;
