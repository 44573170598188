import React, { useState, useEffect, useRef } from "react";
import { Button, Input, Popup } from "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { selectIsLoggedIn, logOut, userLogin, userLoginGoogle } from "../../Store/loggedIn";
import { selectAdmin } from "../../Store/admin";
import { motion, AnimatePresence } from "framer-motion";
import AdminNav from "../Navigation-admin";
import "./admin.scss";
import AdminDictionary from "../AdminDictionary";
import ManageUsers from "./ManageUsers";
import AdminTags from "../AdminTags";
import GameData from "../GameData";
// import useOnScreen from '../../Hooks/useOnScreen';

const MySwal = withReactContent(Swal);

const LoginScreen = () => {
  const userNameRef = useRef();
  const passwordRef = useRef();
  const [pass, setPass] = useState(null);
  const [name, setName] = useState(null);
  const loggedIn = useSelector(selectIsLoggedIn);
  const adminData = useSelector(selectAdmin);
  const dispatch = useDispatch();

  useEffect(() => {
    if (loggedIn.userLoginError) {
      MySwal.fire("Bad Login");
    }
  }, [loggedIn]);

  const handleKeyPress = (e) => {
    if (e?.key === "Enter") {
      handleLoginButton();
    }
  };

  const handleLoginButton = (e, d) => {
    //TODO: remove for production
    dispatch(userLogin({ email: name, password: pass }));
  };

  const handleQuickLogin = () => {
    dispatch(userLogin({ email: "test@user.com", password: "123456" }));
  };

  const handleGoogleLogin = () => {
    console.log("handleGoogleLogin from login page")
    dispatch(userLoginGoogle());
  }

  const handleQuickLogin2 = () => {
    dispatch(userLogin({ email: "admin@user.com", password: "aaa111" }));
  };

  const handleLogOutButton = (e, d) => {
    dispatch(logOut());
  };

  // dispatch(logIn()); //TODO: This is just here for testing
  const focusInput = ref => {
    ref.focus();
  };
  if (!loggedIn.isLoggedIn) {
    return (
      <AnimatePresence exitBeforeEnter>
        <motion.div
          className="admin-login-fullScreen"
          key="loginScreen"
          initial="hidden"
          animate="visible"
          exit="hidden"
          variants={{
            hidden: {
              scale: 1,
              opacity: 0,
              y: -500,
            },
            visible: {
              scale: 1,
              opacity: 1,
              y: 0,
              transition: {
                delay: 0.2,
                duration: 0.5,
                type: "spring",
              },
            },
          }}
        >
          <div className="admin-login-container">
            <h1>Sign In</h1>
            {window.location.host === "localhost:3000" && (
              <Button.Group
                size="tiny"
                className="admin-login-buttons-container"
              >
                <Button style={{ marginRight: 2 }} onClick={handleQuickLogin}>
                  Dev Quick Login
                </Button>
                <Button.Or />
                <Button onClick={handleQuickLogin2}>
                  Dev Quick Super Admin Login
                </Button>
              </Button.Group>
            )}
            {!loggedIn.isLoggedIn ? (
              <div className="admin-login-form">
                <Button
                  className="admin-login-buttons"
                  onClick={handleGoogleLogin}
                  icon="google"
                  labelPosition="left"
                  content="Login with Google"
                  color="google plus"
                />
                <div className="admin-login-or-bar">
                  <hr />
                  OR
                  <hr />
                </div>
                <Input
                  className="admin-login-buttons"
                  onKeyPress={handleKeyPress}
                  placeholder="Name"
                  onChange={(e, d) => {
                    setName(d.value);
                  }}
                  actionPosition="left"
                  ref={(r) => (userNameRef.current = r)}
                  action={{
                    // color: "blue",
                    content: "Username",
                    onClick: () => focusInput(userNameRef.current),
                  }}
                />
                <Input
                  className="admin-login-buttons"
                  type="password"
                  onChange={(e, d) => {
                    setPass(d.value);
                  }}
                  onKeyPress={handleKeyPress}
                  actionPosition="left"
                  ref={(r) => (passwordRef.current = r)}
                  action={{
                    // color: "blue",
                    content: "Password",
                    onClick: () => focusInput(passwordRef.current),
                  }}
                />
                <div className="admin-login-bottom-buttons-container">
                  <Popup
                    content='only avilable at this time for NTA'
                    trigger={
                      <Button
                        // color="blue"
                        className="admin-login-button-alternate"
                        onClick={handleLoginButton}
                      >
                        Log In
                      </Button>
                    }
                  />
                  <Popup
                    content='only avilable at this time for NTA'
                    trigger={<Button
                      // color="blue"
                      disabled
                      className="admin-login-button-alternate">
                      Request Account
                    </Button>}
                  />
                </div>
              </div>
            ) : (
              <div>
                <Button onClick={handleLogOutButton}>Log Out</Button>
              </div>
            )}
          </div>
        </motion.div>
      </AnimatePresence>
    );
  } else {
    // user is logged in
    return (
      <div className="admin-container">
        <div className="admin-menu-container">
          <AdminNav>
            <Button onClick={handleLogOutButton}>Log Out</Button>
          </AdminNav>
        </div>
        <AnimatePresence>{poormansRouting(adminData)}</AnimatePresence>
      </div>
    );
  }
};

const poormansRouting = (adminData) => {
  let routes = {
    Dictionary: <AdminDictionary />,
    Tags: <AdminTags />,
    Lessons: <AdminDictionary />,
    Users: <ManageUsers />,
    Games: <GameData />,
  };
  return routes[adminData.currentScreen || "Dictionary"];
};

export default LoginScreen;
