import React from "react";
import { Button } from "semantic-ui-react";
import config from "../../configData";

const { lookupTable } = config();

let specialCharsA = [];
for (const char in lookupTable) {
  if (lookupTable[char].length > 1) {
    // add all but the first element of the char array
    let charsA = lookupTable[char].slice(1, lookupTable[char].length);
    specialCharsA.push(...charsA);
  }
}
Object.freeze(specialCharsA);

const SpecialCharsEntry = ({ onClick, className }) => {
  return (
    <div className={className}>
      {specialCharsA.map((char) => (
        <Button
          key={char}
          style={{ padding: ".3em .7em .3em .7em" }}
          size="small"
          onClick={() => {
            onClick(char);
          }}
        >
          {char}
        </Button>
      ))}
    </div>
  );
};

export default SpecialCharsEntry;
export {specialCharsA};
