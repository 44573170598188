import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { Input } from "semantic-ui-react";
import SpecialCharsEntry from "../SpecialCharsEntry";
import "./inputControlled.scss";

export default function InputControlled({
  showSpecialCharsEntry = false,
  placeholder = null,
  size = null,
  fluid = null,
  value = undefined,
  onChange = null,
}) {
  const [formValue, setformValue] = useState("");
  const [showChars, setshowChars] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    setformValue(value);
  }, [value]);

  const handleInputChange = (e, d) => {
    // e: native event
    // d: data from semantic Input
    // TODO: search
    if (onChange && typeof onChange === 'function'){
      onChange(e, d);
    }
    setformValue(d.value);
  };

  return (
    <div
      className="inputControlled-container ui input"
      onFocus={() => {
        //TODO: would love to take out these setTimeouts
        setTimeout(() => {
          setshowChars(true);
        }, 50);
      }}
      onBlur={() => {
        setTimeout(() => {
          setshowChars(false);
        }, 50);
      }}
    >
      {showChars && showSpecialCharsEntry && (
        <SpecialCharsEntry
          className="inputControlled-specialChars"
          onClick={(char) => {
            setformValue(formValue + char);
            inputRef.current.focus();
            // handleInputChange(null, formValue);
            onChange(null, { value: formValue + char });
          }}
        />
      )}
      <Input
        placeholder={placeholder}
        size={typeof size === 'string' ? size : "small" }
        fluid={fluid}
        ref={inputRef}
        value={formValue}
        onChange={handleInputChange}
      />
    </div>
  );
}
