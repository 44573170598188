import React, { useRef, useState, useEffect } from "react";
import "./mediaLibrary.scss";
import GridLayout from "./GridLayout";
import { Input, Checkbox, Button } from "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";
import useLoadMoreData from "../../Hooks/useLoadMoreData";
import { getPagedDataCollection, selectFirestore } from "../../Store/firestore";
import config from "../../configData";

// TODO: auto getting of more data won't trigger when scroll isn't possible.

const CONFIG = config();
const MediaLibrary = ({display = "all", selection = false, multiple = false, cbfunc, closeModalFunc}) => {
  const dispatch = useDispatch();
  const pageRef = useRef();
  const fireStoreData = useSelector(selectFirestore);
  useLoadMoreData({
    effect: ({ currPos }) => {
      // console.log("currentPOs: ", currPos);
      if (currPos.getMoreData) {
        console.log("Getting more data...");
        dispatch(
          getPagedDataCollection({
            collectionName: "MediaLibrary",
            showAudio,
            showImages,
          })
        );
      }
    },
    deps: [],
    element: pageRef,
    offset: 500,
    wait: 100,
  });
  const [filterTxt, setFilterTxt] = useState("");
  const [showImages, setShowImages] = useState(true);
  const [showAudio, setShowAudio] = useState(true);
  const [selectedMedia, setSelectedMedia] = useState([]);
  // bool used to see if search should be English or Native
  const [searchNative, setSearchNative] = useState(false);

  useEffect(()=> {
    if (display === "image") setShowAudio(false);
    if (display === "audio") setShowImages(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    dispatch(
      getPagedDataCollection({
        collectionName: "MediaLibrary",
        showAudio,
        showImages,
        newSearch: true,
        searchNative,
        filterTxt,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterTxt, showImages, showAudio, searchNative]);

  const onSelectedMedia = ({ mediaID, checked }) => {
    console.log(" this is the selected media func : ", mediaID);
    if (checked){
      setSelectedMedia([ ...selectedMedia, mediaID ]);
    } else {
      let newSelectedMediaA = selectedMedia.filter( v => v !== mediaID);
      setSelectedMedia(newSelectedMediaA);
    }
    if (multiple === false) {
      closeModalFunc();
    }
  }
  return (
    <div
      style={
        display === "all"
          ? { paddingTop: 15 }
          : { paddingTop: 0, marginTop: -30 }
      }
      ref={pageRef}
    >
      {!window.location.pathname.includes('ML') && (
      <div className="ML-upperRight">
        <Button
          color="red"
          icon="close"
          onClick={() => {
            cbfunc({ mediaIDs: selectedMedia });
            closeModalFunc();
          }}
        />
      </div>
      )}
      <h1>
        Media Library{" "}
        {display !== "all" ? <span>({display} selection) </span> : ""}{" "}
      </h1>
      <div className="ML-filterBox">
        <div className="ML-filterBox-item">
          <h3>Search</h3>
          <Input
            onChange={(e, data) => {
              setFilterTxt(data.value);
            }}
          ></Input>
        </div>
        <div className="ML-filterBox-item">
          <h3>Search language</h3>
          <Checkbox
            checked={!searchNative}
            onClick={() => {
              setSearchNative(false);
            }}
            radio
            label="English"
          />
          <Checkbox
            checked={searchNative}
            onClick={() => {
              setSearchNative(true);
            }}
            radio
            label={CONFIG.NativeLanguage}
          />
        </div>
        {display === "all" ? (
          <div className="ML-filterBox-item">
            <h3>Display</h3>
            <Checkbox
              checked={showImages}
              onClick={() => {
                setShowImages(showImages ? false : true);
              }}
              label="Images"
            />
            <Checkbox
              checked={showAudio}
              onClick={() => {
                setShowAudio(showAudio ? false : true);
              }}
              label="Audio"
            />
          </div>
        ) : (
          <div></div>
        )}
      </div>
      <GridLayout
        data={fireStoreData?.firebaseCollectionData?.MediaLibrary}
        selection={selection}
        multiple={multiple}
        cbfunc={onSelectedMedia}
      />
    </div>
  );
};

export default MediaLibrary;
