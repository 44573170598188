/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useReducer } from "react";
import {
  Button,
  Input,
  Icon,
  Loader,
  Modal,
} from "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";
// import Swal from 'sweetalert2/dist/sweetalert2';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";
import { motion, AnimatePresence } from "framer-motion";
import { selectAdmin, getAllUsers } from "../../../Store/admin";
import {
  selectFirestore,
  getFullDataFirebaseCollection,
  getSearchStartsWithFilter,
  getSearchStartsWithMultiCollection,
  changeUserData,
} from "../../../Store/firestore";
import {
  dateToFriendly,
} from "../../../Components/nonUIFuncs";
import UserDetailDisplay from './UserDetailDisplay.js';

const SwalReact = withReactContent(Swal);

const ManageUsers = () => {
  const [rows, setRows] = useState([]);
  const [userData, setuserData] = useState({});
  const [userDataFilled, setuserDataFilled] = useState({});
  const [showModal, setshowModal] = useState(false);
  const [selectedUsers, setselectedUsers] = useState([]);

  // ==> Redux store setup <== //
  const adminData = useSelector(selectAdmin);
  const fireStoreData = useSelector(selectFirestore);
  const dispatch = useDispatch();
  // =============== //

  const getAllUsersList = () => {
    dispatch(getAllUsers());
  };

  // ==> do this once on component load <== //
  useEffect(() => {
    dispatch(getAllUsers());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const thenet = () => {
    console.log("you found me");
    alert("The Net");
  }

  const ManageUsersToolbar = () => {
    return (
      <div className="manageUsers-toolbar">
        <Button color="green" onClick={() => {
          setshowModal(true);
          setuserData({});
        }}>
          <Icon name="add user" />
          Add users
        </Button>
        <Button color="red" onClick={deleteBtnHdlr}>
          <Icon name="user delete" />
          Remove users
        </Button>
      </div>
    );
  };

  const deleteBtnHdlr = () => {
    for (let userID in selectedUsers) {
      dispatch(changeUserData({ delUser: true, userID }));
    }
  }

  const editUser = (userDataEdit) => {
    //BUG: this can be old data from last click.
    setuserData(userDataEdit);
    setshowModal(true);
  }

  const columns = [
    { name: "displayName", header: "Name", maxWidth: 1000, defaultFlex: 1 },
    { name: "email", header: "Email", maxWidth: 1000, defaultFlex: 1 },
    {
      name: "lastSignInTime",
      header: "Last SignIn Time",
      sortable: false,
      minWidth: 100,
      defaultFlex: 1,
      render: (value) => {
        //TODO: friendlier time display
        let formatedDate = dateToFriendly(value.data.metadata.lastSignInTime);
        return <div>{formatedDate}</div>;
      },
    },
    {
      name: "Access Level",
      header: "Access Level",
      sortable: false,
      maxWidth: 120,
      defaultFlex: 1,
      render: (value) => {
        const userDataColumn = value.data;
        let styleAL = {
          backgroundColor: "rgba(60,255,60,0.9)",
          color: "white",
        };
        let userAccessLevel = "user";
        if (userDataColumn?.customClaims?.isAdmin) {
          userAccessLevel = "admin";
          styleAL.backgroundColor = "rgba(50,50,255,0.4)";
        }
        if (userDataColumn?.customClaims?.isSuperAdmin) {
          styleAL.backgroundColor = "rgba(50,50,255,0.9)";
          userAccessLevel = "super admin";
        }

        return (
          <div style={styleAL} className="admin-access-lvl-cell">
            {userAccessLevel}
          </div>
        );
      },
    },
    {
      name: "Edit",
      header: "Edit User",
      sortable: false,
      maxWidth: 90,
      render: (value) => {
        return (
          <div className="datagrid-center">
            <Button
              data={JSON.stringify(value.data)}
              onClick={(e, v) => {
                const ndata = JSON.parse(v.data);
                editUser(ndata);
              }}
            >
              Edit
            </Button>
          </div>
        );
      },
    },
  ];
  const gridStyle = { minHeight: 550 };

  // ==> display loading screen while waiting for data <== //
  if (!adminData?.allUsers?.data) {
    return (
      <div>
        <ManageUsersToolbar />
        <div style={{ minHeight: "5em" }}>&nbsp;</div>
        <h3>Loading...</h3>
        <Loader active size="huge">
          Retriving Data
        </Loader>
      </div>
    );
  }
  let isNewUser = userData.length === 0;

  return (
    <div>
      <ManageUsersToolbar />
      <ReactDataGrid
        dataSource={adminData.allUsers.data || null}
        sortable
        defaultSortInfo={{ name: "email", dir: 1 }}
        checkboxColumn
        idProperty="uid"
        columns={columns}
        style={gridStyle}
        onSelectionChange={d => { setselectedUsers(d.selected) }}
      />
      <Modal
        onClose={() => setshowModal(false)}
        onOpen={() => setshowModal(true)}
        open={showModal}
      >
        <Modal.Header>
          {(isNewUser) ? "Add New User" : "Edit User"}
        </Modal.Header>
        <Modal.Content>
          <UserDetailDisplay newUser={isNewUser} userData={userData} closeModalFunc={() => { setshowModal(false) }} />
        </Modal.Content>
      </Modal>
      <div style={{ color: "rgba(222,222,222,0.9)", position: "fixed", right: 5, bottom: 5, zIndex: 99999999 }} onClick={thenet}> Π</div>
    </div>
  );
};



export default ManageUsers;
