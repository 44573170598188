import "./index.scss";
import React, {useState, useEffect} from "react";
import { Button } from "semantic-ui-react";
import config from "../../../../configData";
import { setQuizData } from "../../../nonUIFuncs";

const baseDir = config().baseDir;

const Tile = (props) => {
  // console.log(props);
  const [audio, setAudio] = useState(null);
  let quizData = setQuizData(props.data);
  useEffect(() => {
    if (quizData.Audio_Array[0] !== undefined) {
      const audioEl = new Audio(`${baseDir}/Audio/${quizData.Audio_Array[0]}`);
      setAudio(audioEl);
    }
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="tile-container">
      {props.data.App_Art.length > 0 ? (
        <img
          className="tile-left"
          src={`${baseDir}/Image/${props.data.App_Art}`}
          alt=""
        />
      ) : null}
      <div className="tile-right">
        <div>
          <div className="tile-lang">{props.data.Language}</div>
          <div className="tile-eng">{props.data.English}</div>
        </div>
        <div style={{ display: "flex" }}>
          {audio !== null ? (
            <Button
              circular
              color="blue"
              icon="play"
              onClick={() => audio.play()}
            />
          ) : null}
          <Button circular color="blue" onClick={() => props.pressFunc({collectionName: props.collectionName, data: props.data, id: props.id})}>
            Open Tab
          </Button>
        </div>
      </div>
      {/* <div style={{padding: 15, color: 'white', fontWeight: 'bold', backgroundColor: 'red', fontSize: 18, alignSelf: 'flex-end', justifySelf: 'flex-end'}}>
        {props.data.collectionName}
      </div> */}
    </div>
  );
};

export default Tile;
