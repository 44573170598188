import React from "react";
import { Button, Popup } from "semantic-ui-react";
// import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { selectAdmin, currentScreen } from "../../Store/admin";
import { selectIsLoggedIn } from "../../Store/loggedIn";
import "./nav-admin.scss";

const AdminNav = (props) => {
  const adminData = useSelector(selectAdmin);
  const userData = useSelector(selectIsLoggedIn);
  const dispatch = useDispatch();
  // const history = useHistory();
  // const [userIsSuper, setuserIsSuper] = useState(false);

  let superUser = false;

  if (userData?.currentUserData?.canAdminUsers) {
    superUser = true;
  }

  return (
    <div className="nav-admin-semantic-menu">
      <Button
        active={adminData.currentScreen === "Dictionary" ? true : false}
        onClick={() => {
          dispatch(currentScreen("Dictionary"));
        }}
      >
        Dictionary
      </Button>
      <Popup content='This package is not avilable for your community' trigger={
        <div style={{display: 'flex'}}>
          <Button
            style={{alignSelf: 'stretch'}}
            disabled
            onClick={() => {
              dispatch(currentScreen("Lessons"));
            }}>
            Lessons
          </Button>
        </div>
      } />
      <Button
        active={adminData.currentScreen === "Games" ? true : false}
        onClick={() => {
          dispatch(currentScreen("Games"));
          // history.push("/edit/gameData")
        }}>
          Games
        </Button>
      <Button disabled>Exercises</Button>
      <Button
        active={adminData.currentScreen === "Tags" ? true : false}
        onClick={() => {
          dispatch(currentScreen("Tags"));
        }}
      >
        Tags
      </Button>
      <Button
        active={adminData.currentScreen === "Users" ? true : false}
        onClick={() => {
          dispatch(currentScreen("Users"));
        }}
        disabled={!superUser}
      >
        Users
      </Button>
      <div className="nav-admin-menu-last">{props.children}</div>
    </div>
  );
};

export default AdminNav;

// AdminNav.PropTypes = {};
