import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "../features/counter/counterSlice";
import testReducer from "../Store/test";
import firestoreReducer from "../Store/firestore";
import isLoggedInReducer from "../Store/loggedIn";
import adminReducer from "../Store/admin";
import tabReducer from "../Store/tabs";

export default configureStore({
  reducer: {
    counter: counterReducer,
    test: testReducer,
    admin: adminReducer,
    firestore: firestoreReducer,
    isLoggedIn: isLoggedInReducer,
    tabs: tabReducer,
  },
});
