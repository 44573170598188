/* eslint-disable react-hooks/exhaustive-deps */
import "./index.scss";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectFirestore, findBrowseResults } from "../../../Store/firestore";
import config from "../../../configData";
import Tabs from "../../Tabs";
import { useHistory, useLocation } from "react-router-dom";

import Tile from "./Tile";

const CONFIG = config();

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const AlphaBar = (props) => {
  const alpha = Array.from(Array(26)).map((e, i) => i + 65);
  const alphabet = alpha.map((x) => String.fromCharCode(x));
  const params = new URLSearchParams();
  const query = useQuery();
  let collection = query.get('collection');
  let id = query.get('id');
  const history = useHistory();

  const _chooseLetter = (char) => {
    params.append('screen', 'browse');
    if (collection !== null) {
      params.append("collection", collection);
    }
    if (id !== null) {
      params.append("id", id);
    }
    params.append('letter', char);
    history.push({ search: params.toString() });
    props._chooseLetter(char);
  }

  return (
    <div className="browse-alphabar-container">
      <div className="browse-alphabar-inner-container">
        <div className="browse-lang-container">
          <div
            className={
              props.lang === "Language"
                ? "browse-lang-button-selected"
                : "browse-lang-button"
            }
            onClick={() => props.setLang("Language")}
          >
            {CONFIG.NativeLanguage}
          </div>
          <div
            className={
              props.lang === "English"
                ? "browse-lang-button-selected"
                : "browse-lang-button"
            }
            onClick={() => props.setLang("English")}
          >
            English
          </div>
        </div>
        {alphabet.map((char) => {
          let letter = char.toLowerCase();
          if (props.lang === "English") {
            return (
              <div
                key={char}
                onClick={() => _chooseLetter(char)}
                className={
                  props.selected === char
                    ? "browse-alphabar-char-selected"
                    : "browse-alphabar-char"
                }
              >
                {char}
              </div>
            );
          } else {
            let letterArr = CONFIG.lookupTable[letter];
            let langDOM = letterArr.map((langChar) => {
              let langCharUpper = langChar.toUpperCase();
              return (
                <div
                  key={langChar}
                  onClick={() => _chooseLetter(langChar)}
                  className={
                    props.selected === langChar
                      ? "browse-alphabar-char-selected"
                      : "browse-alphabar-char"
                  }
                >
                  {langCharUpper}
                </div>
              );
            });
            return langDOM;
          }
        })}
      </div>
    </div>
  );
};

const Browse = (props) => {
  const [lang, setLang] = useState("Language");
  const fireStoreData = useSelector(selectFirestore);
  const dispatch = useDispatch();

  useEffect(() => {
    let langArr = [];
    //TODO: looks like key should be used
    // eslint-disable-next-line no-unused-vars
    for (const [key, value] of Object.entries(CONFIG.lookupTable)) {
      value.map((l) => {
        return langArr.push(l);
      })
    }
    props.setSelected(props.letter);
    let language = "Language";
    if (!langArr.includes(props.letter)) {
      language = 'English';
      setLang('English');
    }
    dispatch(
      findBrowseResults({
        search: props.letter || 'a',
        lang: language,
      })
    );
  }, []);

  const _chooseLetter = (char) => {
    props.setSelected(char);
    dispatch(
      findBrowseResults({
        search: char,
        lang: lang,
        animacy: props.animacy,
        partOfSpeach: props.partOfSpeach,
        personView: props.personView,
        pluralization: props.pluralization,
        progressiveness: props.progressiveness,
        tense: props.tense,
        transitivity: props.transitivity,
      })
    );
  };

  const openTab = (d) => {
    let topOfPageElem = document.getElementsByClassName("main-content")[0];
    topOfPageElem.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    let dataToPass = {
      data: d,
      key: d.id,
      value: d.id,
      text: d.English,
    };
    props.dispatch({
      type: "add",
      browse: true, // This is important to keep so the tab gets placed in the right spot
      tab: dataToPass,
    });
  }
  return (
    <div className="browse-main-grid">
      <AlphaBar
        lang={lang}
        setLang={setLang}
        selected={props.selected}
        setSelected={props.setSelected}
        _chooseLetter={_chooseLetter}
      />
      <div className="browse-mainContent-container">
        {props.panes.length > 0 ? (
          <Tabs
            panes={props.panes}
            activeTab={props.activeTab}
            setActiveTab={props.setActiveTab}
            showLeftBar={false}
          />
        ) : null}
        {fireStoreData.browseResults !== undefined &&
          fireStoreData.browseResults.length > 0 ? (
          <div className="browse-tile-container">
            {fireStoreData.browseResults.map((datum) => {
              return <Tile pressFunc={openTab} key={datum.id} {...datum} />;
            })}
          </div>
        ) : null}
      </div>
      <div style={{ height: 45 }} />
    </div>
  );
};

export default Browse;
